import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getMaterialNamesByTrade, getMaterialsByNameAndGrade } from '../../services/materialService';
import AddMaterialForm from '../materialManagement/AddMaterialForm';
import VendorRegister from '../vendor/VendorRegister';

const gradeList = [
    'a', 'b', 'c'
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const AddMaterial: React.FC<any> = (props) => {
    const [materialData, setMaterialData] = React.useState<any>();
    const filter = createFilterOptions<any>();
    const [vendorList, setVendorList] = React.useState<any>([]);
    const { accessToken } = useOidcAccessToken();
    const [count, setCount] = React.useState(Number);
    const [material, setMaterial] = React.useState<any>(
        {
            'companyName': '',
            'name': '',
            'quantity': '',
            'unit': '',
            'totalCost': '',
            'grade': '',
            'unitCost': '',
            'vendorMaterialMapId': ''

        }
    );
    const [openAddMaterialForm, setOpenAddMaterialForm] = React.useState<boolean>(false);
    const [openVendorForm, setOpenVendorForm] = React.useState(false);

    const handleClose = () => {
        props.onClose();
        setMaterial({
            'companyName': '',
            'name': '',
            'quantity': '',
            'unit': '',
            'totalCost': '',
            'grade': '',
            'unitCost': '',
            'vendorMaterialMapId': ''
        });
        setCount(0);
        props.changeEditMaterial();
    };

    const handleChangeMaterialName = (e: any, value: any) => {
        setMaterial({ ...material, "name": value?.name });
        if (material?.grade !== '') {
            getMaterials(value?.name, material?.grade);
        }
    }

    const handleChangeGrade = (e: any) => {
        setMaterial({ ...material, [e.target.name]: e.target.value })
        if (material?.name !== '') {
            getMaterials(material?.name, e.target.value);
        }
    }

    const handleChangeVendor = (e: any, value: any) => {
        setMaterial({
            ...material, "companyName": value?.companyName, "unit": value?.unit, "unitCost": value?.cost,
            "vendorMaterialMapId": value?.vendorMaterialMapId
        });
        if (material.quantity !== '') {
            setMaterial({ ...material, "totalCost": value?.cost * material?.quantity })
        }
    }

    const handleChangeQuantity = (e: any) => {
        setMaterial({ ...material, [e.target.name]: e.target.value, totalCost: (material?.unitCost * e.target.value) })
    }

    const getMaterialNames = async () => {
        // get material Names
        let result = await getMaterialNamesByTrade(props?.trade, accessToken);
        setMaterialData(result?.data);
    }
    const closeForm = () => {
        setOpenAddMaterialForm(false);
    }

    // get vendors based on material name and grade
    const getMaterials = async (materialName: any, grade: any) => {
        let materials = {};
        materials["name"] = materialName;
        materials["grade"] = grade;
        console.log(materials);
        let result = await getMaterialsByNameAndGrade(materials, accessToken);
        console.log(result.data);
        if (result?.data !== undefined) {
            setVendorList(result?.data);
        }
        else
        {
            setVendorList([]);
        }
    }

    const addMaterial = () => {
        let validated = validation();
        if (validated === true) {
            props.changeMaterial(material);
            handleClose();
        }
        else {
            setCount(count + 1);
        }
    }

    const editMaterial = () => {
        let validated = validation();
        if (validated === true) {
            props.editAddedMaterial(material);
            handleClose();
        }
        else {
            setCount(count + 1);
        }
    }

    const openVendorRegForm = () => {
        setOpenVendorForm(true);
    };
    const openForm = () => {
        setOpenAddMaterialForm(true);
    }

    const validation = () => {
        let validated = true;
        if (material.name === '' || material.grade === '' || material.companyName === '' || material.quantity === '') {
            validated = false;
        }
        return validated;
    }
    React.useEffect(() => {
        if (props?.editMaterialData !== undefined) {
            setMaterial(props?.editMaterialData);
        }
        getMaterialNames();
    }, [props])

    return (
        <React.Fragment>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={props.open}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add Material
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth size="small">
                                <Autocomplete
                                    value={material?.name}
                                    onChange={(e, newValue) => {
                                        if (typeof newValue === 'string') {
                                            handleChangeMaterialName(e, newValue);
                                        } else if (newValue && newValue.inputValue) {
                                            openForm();
                                        } else {
                                            handleChangeMaterialName(e, newValue);
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option?.name);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                name: `Add Material`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={materialData}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.name;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option?.name}
                                    </li>}
                                    // disabled={disabledMaterial}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Material Name" size='small'
                                            error={(material?.name === '' && count !== 0)}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label" error={(material?.grade === '' && count !== 0)} >Grade</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={material?.grade}
                                    label="Grade"
                                    name="grade"
                                    onChange={(e) => handleChangeGrade(e)}
                                    required
                                    error={(material?.grade === '' && count !== 0)}
                                >
                                    {gradeList.map((grade) => (
                                        <MenuItem value={grade}>{grade}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size="small">
                                <Autocomplete
                                    value={material?.companyName !== undefined ? material?.companyName : ''}
                                    onChange={(e, newValue) => {
                                        if (typeof newValue === 'string') {
                                            handleChangeVendor(e, newValue);
                                        } else if (newValue && newValue.inputValue) {
                                            openVendorRegForm();
                                        } else {
                                            handleChangeVendor(e, newValue);
                                        }
                                    }}
                                    // onChange={(e, newValue) => setVendorNameAndId(e, newValue,index)}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.companyName);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                companyName: `Add Vendor`,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={vendorList}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.companyName;
                                    }}

                                    renderOption={(props, option) => <li {...props}>{option?.companyName}</li>}
                                    disabled={material?.name === '' || material?.grade === '' ? true : false}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} label="Vendor Name" size='small'
                                            error={(material?.companyName === '' && count !== 0)}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="demo-helper-text-aligned"
                                    label="Quanitity"
                                    name="quantity"
                                    size="small"
                                    fullWidth
                                    value={material?.quantity}
                                    error={(material?.quantity === '' && count !== 0)}
                                    onChange={(e) => handleChangeQuantity(e)}
                                    type="number" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="demo-helper-text-aligned"
                                    label="Unit"
                                    name="unit"
                                    size="small"
                                    fullWidth
                                    disabled
                                    value={material?.unit}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="demo-helper-text-aligned"
                                    label="Sub Total"
                                    name="subTotal"
                                    size="small"
                                    fullWidth
                                    disabled
                                    value={material?.totalCost}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="cost"
                                    id="outlined-size-small"
                                    size="small"
                                    label="Unit Cost"
                                    type="number"
                                    disabled
                                    value={material?.unitCost}
                                // onChange={(e) => materialInputChange(e)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {props?.editMaterialData !== undefined ?
                        <Button variant='contained' className="successButton" onClick={editMaterial}>Update</Button>
                        :
                        <Button variant='contained' className="successButton" onClick={addMaterial}>save</Button>
                    }

                    <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleClose}>Close</Button>

                </DialogActions>
            </Dialog>
            <AddMaterialForm open={openAddMaterialForm}
                onClose={closeForm}></AddMaterialForm>
            <VendorRegister
                open={openVendorForm}
                onClose={() => setOpenVendorForm(false)}
            />
        </React.Fragment>

    );
}
export default AddMaterial;