import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { AppBar, Tab, Tabs } from '@mui/material';
import TaskForm from './TaskForm';
import MaterialForm from './MaterialForm';
import ResourceForm from './ResourceForm';
import ToolForm from './ToolForm';
import ActionForm from './ActionForm';
import { getTaskById, saveTask, updateTaskById } from '../../services/TaskService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CustomDialog from '../../pages/Dialog';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}


const AddTaskDialog: React.FC<any> = ({ open, onClose, projectData, taskId, phase, wbs, func, tasks, editView }) => {
    const [value, setValue] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [taskData, setTaskData] = React.useState<any>({
        taskName: '',
        wbs: wbs,
        taskId: taskId,
        estimatedHours: '',
        taskType: '',
        dependencies: [],
        otherDependencies: [],
        successorTask: '',
        phase: phase,
        resources: [
            {
                'type': '',
                'group': '',
                'count': ''
            }
        ],
        boms: [
            {
                'companyName': '',
                'name': '',
                'quantity': '',
                'unit': '',
                'totalCost': '',
                'unitCost': '',
                'vendorMaterialMapId': ''
            }
        ],
        projectTools: [
            {
                'companyName': '',
                'name': '',
                'quantity': '',
                'unit': '',
                'owned': true,
                'totalCost': '',
                'unitCost': '',
                'vendorToolMapId': ''
            }
        ],
        actions: [
            {
                'state': '',
                'action': '',
                'item': ''
            }
        ],
    });
    const { accessToken } = useOidcAccessToken();
    const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [saveError, setSaveError] = React.useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [totalMaterialCost, setTotalMaterialCost] = React.useState<any>();
    const [totalToolCost, setTotalToolCost] = React.useState<any>();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        let validated = validation();
        if (validated === true) {
            setValue(newValue);
        }
        else {
            setCount(count + 1);
        }

    };

    const handleClose = () => {
        onClose();
        Object.assign(taskData, {
            taskName: '',
            wbs: '',
            taskId: '',
            estimatedHours: '',
            phase: '',
            taskType: '',
            dependencies: [],
            otherDependencies: [],
            successorTask: '',
            resources: [
                {
                    'type': '',
                    'group': '',
                    'count': ''
                }
            ],
            boms: [
                {
                    'companyName': '',
                    'name': '',
                    'quantity': '',
                    'unit': '',
                    'totalCost': '',
                    'unitCost': '',
                    'vendorMaterialMapId': ''
                }
            ],
            projectTools: [
                {
                    'companyName': '',
                    'name': '',
                    'quantity': '',
                    'unit': '',
                    'owned': true,
                    'totalCost': '',
                    'unitCost': '',
                    'vendorToolMapId': ''
                }
            ],
            actions: [
                {
                    'state': '',
                    'action': '',
                    'item': ''
                }
            ],
        })
        setCount(0);
        setValue(0);
        setTotalMaterialCost(0);
        setTotalToolCost(0);
    };

    const handleChangeTaskData = (e: any, index: any) => {
        if (e.target.name === 'type') {
            let newArray = taskData?.resources;
            newArray[index]["type"] = e.target.value;
            setTaskData({ ...taskData, 'resources': newArray });
        }
        else
            if (e.target.name === 'group') {
                let newArray = taskData?.resources;
                newArray[index]["group"] = e.target.value;
                setTaskData({ ...taskData, 'resources': newArray });
            }
            else if (e.target.name === 'count') {
                if (e.target.value >= 0) {
                    let newArray = taskData.resources;
                    newArray[index]["count"] = e.target.value;
                    setTaskData({ ...taskData, 'resources': newArray });
                }
            }
            else if (e.target.name === 'estimatedHours') {
                if (e.target.value >= 0) {
                    setTaskData({ ...taskData, [e.target.name]: e.target.value });
                }
            }
            else if (e.target.name === "otherDependencies") {
                setTaskData({ ...taskData, [e.target.name]: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value, });
            }
            else if (e.target.name === "dependencies") {
                setTaskData({ ...taskData, [e.target.name]: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value, });
            }
            else {
                setTaskData({ ...taskData, [e.target.name]: e.target.value });
            }
    }


    // change materials in task data 
    const handleChangeMaterial = (data) => {
        if (taskData.boms[0].companyName === '' && taskData.boms[0].name === '' &&
            taskData.boms[0].quantity === '' && taskData.boms[0].unit === '' &&
            taskData.boms[0].totalCost === '' && taskData.boms[0].unitCost === '' &&
            taskData.boms[0].vendorMaterialMapId === ''
        ) {
            taskData.boms[0] = data;
            calculateTotalMaterialCost(taskData?.boms);
        }
        else {
            const updatedMaterials = [
                ...taskData.boms,
                data,
            ];
            calculateTotalMaterialCost(updatedMaterials);
            setTaskData({ ...taskData, 'boms': updatedMaterials });
        }
    }

    const editMaterial = (index: any, data: any) => {
        let newArray = [...taskData?.boms];
        newArray[index] = data;
        calculateTotalMaterialCost(newArray);
        setTaskData({ ...taskData, 'boms': newArray });
    }

    const editTool = (index: any, data: any) => {
        let newArray = [...taskData?.projectTools];
        newArray[index] = data;
        calculateTotalToolCost(newArray);
        setTaskData({ ...taskData, 'projectTools': newArray });
    }

    // change vendor name in boms

    const handleChangeVendor = (e: any, value: any, index: any) => {
        let newArray = taskData.boms;
        newArray[index]["companyName"] = value?.companyName;
        setTaskData({ ...taskData, 'boms': newArray });
    }

    // change vendor name in project Tool

    const handleChangeToolVendor = (e: any, value: any, index: any) => {
        let newArray = taskData.projectTools;
        newArray[index]["companyName"] = value?.companyName;
        setTaskData({ ...taskData, 'projectTools': newArray });
    }

    const addResources = () => {
        const resource = {
            type: '',
            group: '',
            count: ''
        };
        const updatedResources = [
            ...taskData.resources,
            resource,
        ];
        setTaskData({ ...taskData, 'resources': updatedResources });
    }

    const removeResources = (index: any) => {
        let newArray = taskData.resources;
        if (newArray.length > 1) {
            newArray.splice(index, 1);
            setTaskData({ ...taskData, 'resources': newArray });
        }
        else {
            let resource = {
                'type': '',
                'group': '',
                'count': ''
            }
            newArray[index] = resource
            setTaskData({ ...taskData, 'resources': newArray });
        }
    }

    const addMaterial = () => {
        const material = {
            name: '',
            quantity: '',
            unit: ''
        };
        const updatedMaterials = [
            ...taskData.boms,
            material,
        ];
        setTaskData({ ...taskData, 'boms': updatedMaterials });
    }

    const removeMaterial = (index: any) => {
        let newArray = taskData.boms;
        if (newArray.length > 1) {
            newArray.splice(index, 1);
            setTaskData({ ...taskData, 'boms': newArray });
        }
        else {
            let material = {
                'companyName': '',
                'name': '',
                'quantity': '',
                'unit': '',
                'totalCost': '',
                'unitCost': '',
                'vendorMaterialMapId': ''
            }
            newArray[index] = material
            setTaskData({ ...taskData, 'boms': newArray });
        }
        calculateTotalMaterialCost(newArray);
    }

    const handleChangeTool = (data: any) => {
        if (taskData.projectTools[0].companyName === '' && taskData.projectTools[0].name === '' &&
            taskData.projectTools[0].quantity === '' && taskData.projectTools[0].unit === '' &&
            taskData.projectTools[0].totalCost === '' && taskData.projectTools[0].unitCost === '' &&
            taskData.projectTools[0].vendorToolMapId === ''
        ) {
            taskData.projectTools[0] = data;
            calculateTotalToolCost(taskData?.projectTools);
        }
        else {
            const updateTools = [
                ...taskData.projectTools,
                data,
            ];
            calculateTotalToolCost(updateTools);
            setTaskData({ ...taskData, 'projectTools': updateTools });
        }
    }
    const addTool = () => {
        const tool = {
            name: '',
            quantity: '',
            unit: ''
        };
        const updatedTools = [
            ...taskData.projectTools,
            tool,
        ];
        setTaskData({ ...taskData, 'projectTools': updatedTools });
    }

    const removeTool = (index: any) => {
        let newArray = taskData.projectTools;
        if (newArray.length > 1) {
            newArray.splice(index, 1);
            setTaskData({ ...taskData, 'projectTools': newArray });
        }
        else {
            let tool =
            {
                'companyName': '',
                'name': '',
                'quantity': '',
                'unit': '',
                'owned': true,
                'totalCost': '',
                'unitCost': '',
                'vendorToolMapId': ''
            }

            newArray[index] = tool
            setTaskData({ ...taskData, 'projectTools': newArray });
        }
        calculateTotalToolCost(newArray);
    }

    const handleChangeAction = (e: any, index: any) => {
        let newArray = taskData?.actions;
        newArray[index][e.target.name] = e.target.value;
        setTaskData({ ...taskData, 'actions': newArray });
    }

    const calculateTotalMaterialCost = (tasksData: any) => {
        let total = 0;
        for (let i = 0; i < tasksData?.length; i++) {
            total = tasksData[i]?.totalCost + total;
        }
        setTotalMaterialCost(total);
    }

    const calculateTotalToolCost = (tasksData: any) => {
        let total = 0;
        for (let i = 0; i < tasksData?.length; i++) {
            total = tasksData[i]?.totalCost + total;
        }
        setTotalToolCost(total);
    }

    const addAction = () => {
        const action = {
            state: '',
            action: '',
            item: ''
        };
        const updatedAction = [
            ...taskData?.actions,
            action,
        ];
        setTaskData({ ...taskData, 'actions': updatedAction });
    }

    const removeAction = (index: any) => {
        let newArray = taskData?.actions;
        if (newArray.length > 1) {
            newArray.splice(index, 1);
            setTaskData({ ...taskData, 'actions': newArray });
        }
        else {
            let action =
            {
                'state': '',
                'action': '',
                'item': ''
            }

            newArray[index] = action
            setTaskData({ ...taskData, 'actions': newArray });
        }
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const validation = () => {
        let validated = true;
        if (taskData.taskName === '' || taskData.estimatedHours === '' || taskData.taskType === '') {
            validated = false;
        }
        return validated;
    }

    // remove empty bom in taskData 
    const removeEmptyBom = (bom: any) => {
        for (let i = 0; i < bom.length; i++) {
            if (bom[i].companyName === '' && bom[i].name === '' && bom[i].quantity === '' &&
                bom[i].unit === '' && bom[i].totalCost === '' && bom[i].unitCost === '' &&
                bom[i].vendorMaterialMapId === ''
            ) {
                bom.splice(bom[i], 1);
            }
        }
        return bom;
    }

    // remove empty resources in taskData 
    const removeEmptyResources = (resources: any) => {
        for (let i = 0; i < resources.length; i++) {
            if (resources[i].type === '' && resources[i].group === '' && resources[i].count === ''
            ) {
                resources.splice(resources[i], 1);
            }
        }
        if (resources.length === 0) {
            resources = null;
        }
        return resources;
    }
    // remove empty actions in taskData 
    const removeEmptyActions = (actions: any) => {
        for (let i = 0; i < actions.length; i++) {
            if (actions[i].state === '' && actions[i].action === '' && actions[i].item === ''
            ) {
                actions.splice(actions[i], 1);
            }
        }
        if (actions.length === 0) {
            actions = null;
        }
        return actions;
    }

    const removeEmptyProjectTools = (projectTools: any) => {
        for (let i = 0; i < projectTools.length; i++) {
            if (projectTools[i].companyName === '' && projectTools[i].name === '' && projectTools[i].quantity === '' &&
                projectTools[i].unit === '' && projectTools[i].totalCost === '' && projectTools[i].unitCost === '' &&
                projectTools[i].vendorToolMapId === ''
            ) {
                projectTools.splice(projectTools[i], 1);
            }
        }
        return projectTools;
    }

    const addTask = async () => {
        setCount(count + 1);
        let validated = validation();
        if (validated === true) {
            var finalData = {};
            finalData['boms'] = removeEmptyBom(taskData.boms);
            finalData['resources'] = JSON.stringify(removeEmptyResources(taskData.resources));
            finalData['actions'] = JSON.stringify(removeEmptyActions(taskData.actions));
            finalData['projectTools'] = removeEmptyProjectTools(taskData.projectTools);
            finalData['wbs'] = wbs;
            finalData['phase'] = phase;
            finalData['taskId'] = taskId;
            finalData['project'] = projectData?.props?.id;
            finalData['taskName'] = taskData.taskName;
            finalData['estimatedHours'] = taskData.estimatedHours;
            finalData['taskName'] = taskData.taskName;
            finalData['dependencies'] = (taskData.dependencies).join(";");
            finalData['otherDependencies'] = (taskData.otherDependencies).join(";");
            finalData['taskType'] = taskData.taskType;
            let result = await saveTask(accessToken, finalData);
            if (result.status === 201) {
                setSaveSuccess(true);
                setOpenSaveDialog(true);
                handleClose();
                func();
            }
            else {
                setOpenSaveDialog(true);
                setSaveError(true);
                handleClose();
            }
        }
    }

    const updateTask = async () => {
        setCount(count + 1);
        let validated = validation();
        if (validated === true) {
            var finalData = {};
            finalData['boms'] = removeEmptyBom(taskData.boms);
            finalData['resources'] = JSON.stringify(removeEmptyResources(taskData.resources));
            finalData['actions'] = JSON.stringify(removeEmptyActions(taskData.actions));
            finalData['projectTools'] = removeEmptyProjectTools(taskData.projectTools);
            finalData['wbs'] = wbs;
            finalData['phase'] = phase;
            finalData['taskId'] = taskId;
            finalData['id'] = tasks?.id;
            finalData['project'] = projectData?.props?.id;
            finalData['taskName'] = taskData.taskName;
            finalData['estimatedHours'] = taskData.estimatedHours;
            finalData['taskName'] = taskData.taskName;
            finalData['dependencies'] = (taskData.dependencies).join(";");
            finalData['otherDependencies'] = (taskData.otherDependencies).join(";");
            finalData['taskType'] = taskData.taskType;
            let result = await updateTaskById(finalData, accessToken);
            if (result.status === 201 || result.status === 200) {
                setUpdateSuccess(true);
                setOpenUpdateDialog(true);
                handleClose();
                func();
            }
            else {
                setOpenUpdateDialog(true);
                setUpdateError(true);
                handleClose();
            }
        }
    }

    // get task, boms and project tools based on task id
    const getTasksByTaskId = async (id: any) => {
        let result = await getTaskById(id, accessToken);
        let data = result.data;
        let editData = {};
        if (data['boms'] === null || data['boms'] === "null" || data['boms'] === "" || data['boms']?.length === 0) {
            editData['boms'] = [
                {
                    'companyName': '',
                    'name': '',
                    'quantity': '',
                    'unit': '',
                    'totalCost': '',
                    'unitCost': '',
                    'vendorMaterialMapId': ''
                }
            ]
        }
        else {
            editData['boms'] = data['boms'];
            calculateTotalMaterialCost(data["boms"])
        }
        if (data['projectTools'] === null || data['projectTools'] === "null" || data['projectTools'] === "" ||
            data['projectTools'] === undefined || data['projectTools'].length === 0) {
            editData['projectTools'] = [
                {
                    'companyName': '',
                    'name': '',
                    'quantity': '',
                    'unit': '',
                    'owned': true,
                    'totalCost': '',
                    'unitCost': '',
                    'vendorToolMapId': ''
                }
            ]
        }
        else {
            editData['projectTools'] = data['projectTools'];
            calculateTotalToolCost(data["projectTools"])
        }
        if (data['actions'] === null || data['actions'] === "null" || data['actions'] === "" || data['actions'] === undefined) {
            editData['actions'] = [
                {
                    'state': '',
                    'action': '',
                    'item': ''
                }
            ]
        }
        else {

            editData['actions'] = JSON.parse(data['actions']);
        }
        if (data['resources'] === null || data['resources'] === "null" || data['resources'] === "" || data['resources'] === undefined) {
            editData['resources'] = [
                {
                    'type': '',
                    'group': '',
                    'count': ''
                }
            ]
        }
        else {

            editData['resources'] = JSON.parse(data['resources']);
        }
        editData['taskName'] = data['taskName'];
        editData['wbs'] = data['wbs'];
        editData['taskId'] = data['taskId'];
        editData['estimatedHours'] = data['estimatedHours'];
        editData['phase'] = data['phase'];
        editData['taskType'] = data['taskType'];
        if (data['dependencies'] === "") {
            editData['dependencies'] = [];
        }
        else {
            editData['dependencies'] = data['dependencies']?.split(",");
        }
        if (data['otherDependencies'] === "" || data['otherDependencies'] === null || data['otherDependencies'] === undefined) {
            editData['otherDependencies'] = [];
        }
        else {
            editData['otherDependencies'] = data['otherDependencies']?.split(",");
        }

        setTaskData(editData)
    }


    React.useEffect(() => {
        if (editView === true) {
            getTasksByTaskId(tasks?.id)
            // setTaskData(editData);
        }
    }, [editView,  tasks, open]);
    return (
        <React.Fragment>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"lg"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add Task
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {/* <Divider /> */}
                {/* <AppBar position="static" color="default"> */}
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                    >
                        <Tab label="Task" {...a11yProps(0)} />
                        <Tab label="Resource" {...a11yProps(1)} />
                        <Tab label="Bom" {...a11yProps(2)} />
                        <Tab label="Tools" {...a11yProps(3)} />
                        {/* <Tab label="Question" {...a11yProps(4)} /> */}
                        <Tab label="Action" {...a11yProps(4)} />
                    </Tabs>
                {/* </AppBar> */}
                <DialogContent dividers sx={{ padding: 0, height:'40vh'}} >
                    <CustomTabPanel value={value} index={0}>
                        <TaskForm changeTaskData={handleChangeTaskData} task={taskData} taskId={taskId} projectId={projectData?.props?.projectId}
                            count={count} editView={editView} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <ResourceForm changeTaskData={handleChangeTaskData} task={taskData} add={addResources} remove={removeResources} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <MaterialForm projectData={projectData?.props} task={taskData}
                            changeTaskData={handleChangeMaterial} add={addMaterial}
                            remove={removeMaterial} ChangeVendorData={handleChangeVendor}
                            total={totalMaterialCost} edit={editMaterial} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <ToolForm projectData={projectData?.props} task={taskData}
                            changeTaskData={handleChangeTool} add={addTool}
                            remove={removeTool} changeVendorToolData={handleChangeToolVendor}
                            total={totalToolCost} edit={editTool} />
                    </CustomTabPanel>
                    {/* <CustomTabPanel value={value} index={4}>
                        Item One
                    </CustomTabPanel> */}
                    <CustomTabPanel value={value} index={4}>
                        <ActionForm task={taskData}
                            changeTaskData={handleChangeAction} add={addAction}
                            remove={removeAction} />
                    </CustomTabPanel>
                </DialogContent>
                <DialogActions>
                    {editView === true ?
                        <Button variant='contained' className="successButton" onClick={updateTask}>update</Button>
                        :
                        <Button variant='contained' className="successButton" onClick={addTask}>save</Button>
                    }
                    <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleClose}>Close</Button>

                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openSaveDialog}
                onClose={() => setOpenSaveDialog(false)}
                success={saveSuccess}
                error={saveError}
                Content={saveSuccess ? "Saved" : "Not Saved"}
            />
            <CustomDialog
                open={openUpdateDialog}
                onClose={() => setOpenUpdateDialog(false)}
                success={updateSuccess}
                error={updateError}
                Content={updateSuccess ? "Updated" : "Not Updated"}
            />
        </React.Fragment>
    );
}

export default AddTaskDialog;