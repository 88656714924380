import { Box, Button, Card, FormControl, Grid, IconButton, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getAllVendors } from '../../services/VendorService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddTool from './AddTool';


const ToolForm: React.FC<any> = (props) => {
    const { accessToken } = useOidcAccessToken();
    const [vendorList, setVendorList] = React.useState<any>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuIndex, setMenuIndex] = React.useState<any>();
    const openMenu = Boolean(anchorEl);
    const [openTool, setOpenTool] = React.useState<boolean>(false);
    const [editData, setEditData] = React.useState<any>();
    const [editIndexvalue, setEditIndexValue] = React.useState<any>();

    const handleChange = (event: any, value: any, index: any) => {
        props.changeTaskData(event, value, index)
    };

    const handleCloseTool = () => {
        setOpenTool(false);
    }

    const removeRow = (index: any) => {
        props.remove(index);
    }


    const getVendors = async () => {
        let result = await getAllVendors(accessToken);
        setVendorList(result.data);
    }

    const handleClick = (event: any, index: any) => {
        setMenuIndex(index);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeEditToolData = () => {
        setEditData(undefined);
    }

    const editToolData = (data: any) => {
        props.edit(editIndexvalue, data);
    }


    const editRow = (index: any, row: any) => {
        setEditIndexValue(index);
        setEditData(row);
        setAnchorEl(null);
        setOpenTool(true);
    }

    const addNewTool = () => {
        setAnchorEl(null);
        setOpenTool(true);
    }

    React.useEffect(() => {
        // getToolNames();
        getVendors();
    }, []);

    return (
        <>
            <Grid container className='alignCenter'>
                <Grid item xs={12}>
                    <Card variant='outlined' className='taskcard' sx={{ padding: 1, marginBottom: 1, height:'31vh', overflow:'auto' }}>
                        {props?.task?.projectTools[0].companyName !== '' && props?.task?.projectTools[0].name !== '' &&
                            props?.task?.projectTools[0].quantity !== '' && props?.task?.projectTools[0].unit !== '' &&
                            props?.task?.projectTools[0].totalCost !== '' && props?.task?.projectTools[0].unitCost !== ''
                            && props?.task?.projectTools[0].vendorToolMapId !== '' ?
                            <>
                                {props?.task?.projectTools?.map((tool: any, index: any) =>
                                    <Card variant='outlined' className='taskcard' sx={{ padding: 1, marginBottom: 1, backgroundColor: '#F1F3FF' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Tool Name"
                                                        name="name"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={tool?.name}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Vendor Name"
                                                        name="companyName"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={tool?.companyName}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Quanitity"
                                                        name="quantity"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={tool?.quantity}
                                                        type="number" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Unit"
                                                        name="unit"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={tool?.unit}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Sub Total"
                                                        name="subTotal"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={tool?.totalCost}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton
                                                    id="demo-positioned-button"
                                                    aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openMenu ? 'true' : undefined}
                                                    onClick={(e) => handleClick(e, index)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="demo-positioned-menu"
                                                    aria-labelledby="demo-positioned-button"
                                                    anchorEl={anchorEl}
                                                    open={openMenu === true && menuIndex === index ? true : false}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={() => editRow(index, tool)}
                                                    >
                                                        <Stack direction={'row'} spacing={1}>
                                                            <EditIcon fontSize='small'
                                                            />
                                                            <Typography>Edit</Typography>
                                                        </Stack>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => removeRow(index)}>
                                                        <Stack direction={'row'} spacing={1}>
                                                            <DeleteIcon fontSize='small' />
                                                            <Typography>Delete</Typography>
                                                        </Stack>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => addNewTool()}
                                                    >
                                                        <Stack direction={'row'} spacing={1}>
                                                            <AddIcon fontSize='small' />
                                                            <Typography>Add</Typography>
                                                        </Stack>
                                                    </MenuItem>
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                )}
                            </>
                            :
                            <Box className="alignCenter">
                                <Button onClick={() => addNewTool()} size="large" startIcon={<AddIcon fontSize='small' />}>Add Tool</Button>
                            </Box>
                        }
                    </Card>
                </Grid>
                <Grid xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2} className='alignEnd'>
                            <Typography>Total:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{props?.total}</Typography>
                        </Grid>
                        <Grid item xs={1}>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AddTool open={openTool} onClose={handleCloseTool} trade={props?.projectData?.trade}
                changeTool={handleChange} editToolData={editData} changeEditTool={changeEditToolData}
                editAddedTool={editToolData} />
        </>
    );
}

export default ToolForm;