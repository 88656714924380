import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, Stack, Tooltip, Typography } from '@mui/material';
import MultiChoice from './MultiChoice';
import FreeText from './FreeText';
import Combo from './Combo';
import { BasicDefinitions, CodeList, ConditionDef, ItemData, ItemDef, ItemGroupData, ItemGroupDef, Project, ProjectData } from '../../../interface/SdmInterfaces';
import SingleSelect from './SingleSelect';
import RadioType from './RadioType';
import DateSelector from './DateSelector';
import DateTime from './DateTime';
import MultilineText from './MultilineText';
import FloatText from './FloatText';
import IntegerText from './IntegerText';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


interface Props {
    OID: string;
    measureDef: BasicDefinitions;
    itemDefs: ItemDef[];
    itemGroupDefs: ItemGroupDef[];
    codeListDefs: CodeList[];
    conditionDefs: ConditionDef[];
    OnItemGrpDataChange: React.Dispatch<React.SetStateAction<any>>;
    show: React.Dispatch<React.SetStateAction<any>>;
    showRepeat: React.Dispatch<React.SetStateAction<any>>;
    itemGroupDatas: ItemGroupData[];
}


const QuestionRendererCard: React.FC<Props> = ({ OID, itemGroupDefs, itemDefs, codeListDefs, conditionDefs, measureDef, OnItemGrpDataChange, show, showRepeat, itemGroupDatas }) => {

    // console.log(codeListDefs);

    const [itemData, setItemData] = useState<ItemData[]>([]);
    const [itemGroupData, setItemGroupData] = useState<ItemGroupData[]>([]);
    const [itemDef, setItemDef] = useState<ItemDef[]>([]);
    const [itemGroupDatases, setItemGroupDatas] = useState<ItemGroupData[]>([]);

    //To set ItemData 
    function OnItemDataChange(item: any) {
        //console.log(item);
        if (itemData.length !== 0 && item !== undefined && item !== null) {
            const index = itemData.findIndex((obj) => obj.ItemOID === item.ItemOID);
            const updatedTextData = [...itemData];
            if (index !== -1) {
                updatedTextData[index] = item;
            }
            else {
                updatedTextData.push(item)
            }
            setItemData(updatedTextData);
            handleItemGroupData(updatedTextData);
        } else {
            setItemData([...itemData, item]);
            handleItemGroupData([item]);
        }
    }

    let itemGroupDataArray: ItemGroupData[] = [];

    //To set ItemGroupData
    const handleItemGroupData = (itemData: any) => {
        // console.log(itemGroupDatas);
        if (itemGroupDatas.length === 0) {
            if (itemData.length !== 0 && itemData !== undefined && itemData !== null) {
                itemGroupDefs?.map((igd: any, i: any) => {
                    igd?.ItemRef?.map((ir: any) => {
                        itemData?.map((td: any) => {
                            // console.log(itemGroupData[i].ItemGroupRepeatKey === i, itemGroupData);
                            if (ir.ItemOID === td.ItemOID) {
                                if (itemGroupData.length !== 0) {
                                    // if (itemGroupData[i].ItemGroupRepeatKey !== i) {
                                    const index = itemGroupData.findIndex((obj) => obj.ItemGroupOID === igd.OID);
                                    const updatedItemGrpData = [...itemGroupData];
                                    if (index !== -1) {
                                        const itd = [...updatedItemGrpData[index].ItemData];
                                        const ind = itd.findIndex((obj) => obj.ItemOID === td.ItemOID);
                                        if (ind !== -1) {
                                            itd[ind] = td;
                                        } else {
                                            itd.push(td)
                                        }
                                        updatedItemGrpData[index] = { ItemData: itd, ItemGroupOID: updatedItemGrpData[index].ItemGroupOID, ItemGroupRepeatKey: i };
                                    }
                                    else {
                                        updatedItemGrpData.push({ ItemData: [td], ItemGroupOID: igd.OID, ItemGroupRepeatKey: i });
                                    }
                                    setItemGroupData(updatedItemGrpData);
                                    let itGrp = {
                                        "ItemGroup": updatedItemGrpData,
                                        "ProjectOID": OID
                                    }
                                    OnItemGrpDataChange(itGrp);
                                    // } else {

                                    // }
                                } else {
                                    itemGroupDataArray.push({ ItemData: [td], ItemGroupOID: igd.OID, ItemGroupRepeatKey: 0 });
                                    setItemGroupData(itemGroupDataArray);
                                    let itGrp = {
                                        "ItemGroup": itemGroupDataArray,
                                        "ProjectOID": OID
                                    }
                                    OnItemGrpDataChange(itGrp);
                                }
                            }
                        })
                    })
                })
            }
        } else {
            if (itemData.length !== 0 && itemData !== undefined && itemData !== null) {
                setItemGroupDatas(itemGroupDatas);
                itemGroupDefs?.map((igd) => {
                    igd?.ItemRef?.map((ir) => {
                        itemData?.map((td: any) => {
                            if (ir.ItemOID === td.ItemOID) {
                                if (itemGroupDatases.length !== 0) {
                                    const index = itemGroupDatases.findIndex((obj) => obj.ItemGroupOID === igd.OID);
                                    const updatedItemGrpData = [...itemGroupDatases];
                                    if (index !== -1) {
                                        const itd = [...updatedItemGrpData[index].ItemData];
                                        const ind = itd.findIndex((obj) => obj.ItemOID === td.ItemOID);
                                        if (ind !== -1) {
                                            itd[ind] = td;
                                        } else {
                                            itd.push(td)
                                        }
                                        updatedItemGrpData[index] = { ItemData: itd, ItemGroupOID: updatedItemGrpData[index].ItemGroupOID, ItemGroupRepeatKey: 0 };
                                    }
                                    else {
                                        updatedItemGrpData.push({ ItemData: [td], ItemGroupOID: igd.OID, ItemGroupRepeatKey: 0 });
                                    }
                                    console.log(updatedItemGrpData);
                                    setItemGroupDatas(updatedItemGrpData);
                                    let itGrp = {
                                        "ItemGroup": updatedItemGrpData,
                                        "ProjectOID": OID
                                    }
                                    OnItemGrpDataChange(itGrp);
                                }
                                // else {
                                //     itemGroupDataArray.push(...itemGroupDataArray, { ItemGroupOID: igd.OID, ItemData: [td], ItemGroupRepeatKey: 0 });
                                //     setItemGroupData(itemGroupDataArray);
                                //     let itGrp = {
                                //         "ItemGroup": itemGroupDataArray,
                                //         "ProjectOID": OID
                                //     }
                                //     OnItemGrpDataChange(itGrp);
                                // }
                            }
                        })
                    })
                })
            }
        }
    }

    //To show question based on condition
    function showQuestion(status: any, itemOID: any, OID: any) {
        //console.log(status, itemOID, OID);
        if (status) {
            itemGroupDefs?.map((igd) => {
                igd?.ItemRef?.map((ir, index) => {
                    if (ir.CollectionExceptionConditionOID === itemOID) {
                        let item = {
                            "id": ir.ItemOID,
                            "indexes": index,
                            "status": status,
                            "ProjectOID": OID
                        }
                        show(item);
                    }
                })
            })
            // itemGroupData?.map((igd) => {
            //     igd?.ItemData?.map((id) => {
            //         if (id.ItemOID === itemOID) {
            //             let item = {
            //                 "id": id.ItemOID,
            //                 "indexes": igd.ItemGroupRepeatKey,
            //                 "status": status,
            //                 "ProjectOID": OID
            //             }
            //             show(item);
            //         }
            //     })
            // })
        } else {
            itemGroupDefs?.map((igd) => {
                igd?.ItemRef?.map((ir, index) => {
                    if (ir.CollectionExceptionConditionOID === itemOID) {
                        let item = {
                            "id": ir.ItemOID,
                            "indexes": index,
                            "status": status,
                            "ProjectOID": OID
                        }
                        show(item);
                    }
                })
            })
        }
    }

    //For repeat item
    function handleRepeat(event: any, itemGroup: any, indexes: any) {
        //console.log(itemGroup, indexes);
        if (itemGroup !== (null || undefined)) {
            // for (let i = 0; i < itemGroupDefs.length; i++) {
            //     if (itemGroupData[i].ItemGroupOID === itemGroup.OID) {
            //         itemGroupData[i].ItemGroupRepeatKey = itemGroupData[i].ItemGroupRepeatKey + 1;           
            //     }
            // }
            itemGroupDefs.splice(indexes, 0, itemGroup);
            showRepeat(itemGroupDefs);
        }
        // console.log(itemGroupDefs);
        // let itemGroupRepeat = {
        //     "ItemGroup": itemGroup,
        //     "ItemData": [],
        //     "ItemGroupRepeatKey": 0, 
        //     "Index": indexes
        // }
        // showRepeat(itemGroupRepeat);
        //itemGroupDefs.push(itemGroup);
    }

    //For delete item in Repeat
    function handleDelete(event: any, itemGroup: any, indexes: any) {
        if (itemGroup !== (null || undefined)) {
            itemGroupDefs.splice(indexes, 1);
            showRepeat(itemGroupDefs);
        }
    }

    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={10}>
                    <Box>
                        {itemGroupDefs.map((itemGroup, indexes) => {
                            let itgrpDt = itemGroupDatas?.filter((igd) => igd.ItemGroupOID === itemGroup.OID);
                            return (
                                <Card className='POCard' variant="outlined" sx={{ p: 3, mt: 2 }}>
                                    <Stack direction='row'>
                                        <Grid item xs={6}>
                                            <Typography sx={{ fontWeight: "bold" }}>{itemGroup?.Description?.TranslatedText?.text}
                                                &nbsp;{itemGroup?.Repeating === 'Yes' ? indexes + 1 : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {itemGroup?.Repeating === 'Yes' ? <Button style={{ float: 'right' }} variant='outlined' onClick={(e) => handleRepeat(e, itemGroup, indexes)}>Add</Button> : null}
                                            {itemGroup?.Repeating === 'Yes' ? <Button style={{ float: 'right' }} variant='outlined' onClick={(e) => handleDelete(e, itemGroup, indexes)}>Delete</Button> : null}
                                        </Grid>
                                    </Stack>
                                    {itemGroup?.ItemRef?.map((ir, index) => {
                                        let item = itemDefs?.find((id: any) => id?.OID === ir?.ItemOID);
                                        let measureUnit = measureDef?.MeasurementUnit?.find((mu) => mu?.OID === item?.MeasurementOid);
                                        let itemData = itgrpDt?.map((igd) => igd?.ItemData?.find((id) => id?.ItemOID === item?.OID));
                                        let codeList = codeListDefs?.filter((codeList) => codeList?.OID === item?.CodeListRef?.CodeListOID);
                                        let condition = conditionDefs?.filter((cd) => cd?.FormalExpression?.text?.includes(item?.OID as string));
                                        return (
                                            // <Card className='POCard' variant="outlined" sx={{ p: 3, mt: 2 }}>
                                            <>
                                                {/* <Stack direction='row' sx={{ mt: 2 }}> */}
                                                {/* <Grid item xs={6}>  */}
                                                {ir.Mandatory === 'Yes' ? <Typography sx={{ mt: 2 }}>{item?.Question?.TranslatedText?.text} <span style={{ color: 'red' }}>*</span></Typography> : <Typography sx={{ mt: 2 }}>{item?.Question?.TranslatedText?.text}</Typography>}
                                                {/* <Tooltip title="Add" placement="right-start" >
                                                            {/* <Button><InfoOutlinedIcon /></Button> 
                                                            <InfoOutlinedIcon sx={{ color: 'blue', verticalAlign:"middle", mx: 1 }} />
                                                        </Tooltip> */}

                                                {/* </Grid> */}
                                                {/* <Grid item xs={6}>
                                                        {itemGroup?.Repeating === 'Yes' ? <Button style={{ float: 'right' }} variant='outlined' onClick={e => handleRepeat(e, item, index)}>Add</Button> : null}
                                                    </Grid> */}
                                                {/* </Stack> */}
                                                
                                                {item?.DataType === 'text' ? <FreeText itemDef={item} measureDef={measureUnit} onItemchange={OnItemDataChange} itemDatas={itemData} mandatory={ir.Mandatory} /> : null}
                                                {item?.DataType === 'integer' ? <IntegerText itemDef={item} measureDef={measureUnit} onItemchange={OnItemDataChange} itemDatas={itemData} mandatory={ir.Mandatory} /> : null}
                                                {item?.DataType === 'float' ? <FloatText itemDef={item} measureDef={measureUnit} onItemchange={OnItemDataChange} itemDatas={itemData} /> : null}
                                                {item?.DataType === 'multilinetext' ? <MultilineText itemDef={item} onItemchange={OnItemDataChange} /> : null}
                                                {item?.DataType === 'singleselect' ? <SingleSelect OID={OID} itemDef={item} conditionDef={condition} codeList={codeList} onItemchange={OnItemDataChange} showStatus={showQuestion} itemDatas={itemData} /> : null}
                                                {item?.DataType === 'multiselect' ? <MultiChoice itemDef={item} conditionDef={condition} codeList={codeList} onItemchange={OnItemDataChange} showStatus={showQuestion} itemDatas={itemData} /> : null}
                                                {item?.DataType === 'date' ? <DateSelector itemDef={item} conditionDef={condition} codeList={codeList} onItemchange={OnItemDataChange} itemDatas={itemData} /> : null}
                                                {item?.DataType === 'datetime' ? <DateTime itemDef={item} conditionDef={condition} codeList={codeList} onItemchange={OnItemDataChange} itemDatas={itemData} /> : null}
                                                {item?.DataType === 'combobox' ? <Combo itemDef={item} conditionDef={condition} codeList={codeList} onItemchange={OnItemDataChange} showStatus={showQuestion} itemDatas={itemData} /> : null}
                                                {item?.DataType === 'boolean' ? <RadioType itemDef={item} conditionDef={condition} codeList={codeList} onItemchange={OnItemDataChange} showStatus={showQuestion} itemDatas={itemData} /> : null}
                                                {/* </Card> */}
                                            </>
                                        )
                                    }
                                    )}
                                </Card>
                            )
                        })}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}


export default QuestionRendererCard;