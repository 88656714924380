import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { CodeList, ConditionDef, ItemData, ItemDef } from '../../../interface/SdmInterfaces';
import { useState } from 'react';
import dayjs from 'dayjs';

interface DateProps {
    itemDef: ItemDef;
    conditionDef: ConditionDef[];
    codeList: CodeList[];
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
    showStatus: React.Dispatch<React.SetStateAction<any>>;
}

const DateSelector: React.FC<any> = ({ itemDef, conditionDef, codeList, onItemchange, itemDatas }) => {

    // console.log(itemDef);

    const [itemData, setItemData] = useState<ItemData>();

    const handleChange = (event: any) => {
        //console.log(dayjs(event.$d));
        let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: dayjs(event.$d).format('YYYY-MM-DD') + "T00:00:00.000Z", CodeList: [], MetaKey: itemDef.MetaKey };
        setItemData(tempItemData);
        onItemchange(tempItemData);
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']} >
                    <DatePicker label="Date" 
                    slotProps={{ textField: { fullWidth: true, size: 'small' } }} 
                    // value={(itemDef?.OID === itemDatas[0]?.ItemOID) ? itemDatas[0]?.Value : itemData?.Value}
                    onChange={handleChange} />
                </DemoContainer>
            </LocalizationProvider>
        </>
    )
}

export default DateSelector;