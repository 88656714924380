/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 21/06/24 
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for listing    customer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2196           |  24/06/24   | Aish       | created  employee time sheet view based on date filteration
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Menu, MenuItem, Stack } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import AddTaskDialog from './AddTaskDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteTask, getDependentTaskByTaskIdAndProjectId, getTasks } from '../../services/TaskService';
import CustomDialog from '../../pages/Dialog';
import EstimateWarning from './EstimateWarning';


const BidTaskList: React.FC<any> = (props) => {
    // const [page, setPage] = React.useState(0);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    // const [dense, setDense] = React.useState(false);
    const { accessToken } = useOidcAccessToken();
    const [rows, setRows] = React.useState<any>([]);
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openRow, setOpenRow] = React.useState<string | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuIndex, setMenuIndex] = React.useState<any>();
    const [taskId, setTaskId] = React.useState<any>();
    const [phase, setPhase] = React.useState<any>();
    const [tasks, setTasks] = React.useState<any>();
    const [editView, setEditView] = React.useState<any>(false);
    const [wbs, setWbs] = React.useState<any>();
    const openMenu = Boolean(anchorEl);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [deleteSuccess, setDeleteSuccess] = React.useState(false);
    const [deleteError, setDeleteError] = React.useState(false);
    const [openDependenciesView, setOpenDependenciesView] = React.useState(false);
    const [dependentTask, setDependentTask] = React.useState<any>();
    const [deletedTaskData, setDeletedTaskData] = React.useState<any>();
    const [openEstimateWarningDialogs, setOpenEstimateWarningDialogs] = React.useState(false);

    const handleClick = (event: any, index: any) => {
        setMenuIndex(index);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleRowClick = (rowIndex) => {
        setSelectedRow(selectedRow === rowIndex ? null : rowIndex);
        setOpenRow(openRow === rowIndex ? null : rowIndex);
    };

    const addTask = (taskId: any, phase: any, wbs: any) => {
        setTaskId(taskId);
        setPhase(phase);
        setEditView(false);
        setWbs(wbs);
        setOpenDialogs(true);
        handleClose();
    }

    const editTask = (row: any) => {
        setEditView(true);
        setTaskId(row?.taskId);
        setTasks(row);
        setOpenDialogs(true);
        handleClose();
    }
    const getDependentTask = async (row: any) => {
        let result = await getDependentTaskByTaskIdAndProjectId(row?.project, row?.taskId, accessToken);
        console.log(result.data);
        if ((result.data).length !== 0) {
            setAnchorEl(null);
            setDeletedTaskData(row);
            setDependentTask(result?.data);
            setOpenDependenciesView(true);
        }
        else {
            setAnchorEl(null);
            deleteTaskById(row);
        }

    }

    const deleteTaskById = async (row: any) => {
        let result = await deleteTask(row, accessToken);
        if (result.status === 200 || result.status === 201) {
            setDeleteSuccess(true);
            setOpenDeleteDialog(true);
            setOpenDependenciesView(false)
            getTaskData();
        }
        else {
            setOpenDeleteDialog(true);
            setDeleteError(true);
        }
    }

    const getTaskData = async () => {
        let result = await getTasks(props?.props?.id, accessToken);
        setRows(result.data);
    }

    const openEstimateWarningDialog = () =>
    {
       setOpenEstimateWarningDialogs(true);
    }
    React.useEffect(() => {
        getTaskData();
    }, []);
    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={12} sm={12} lg={12} className='alignEnd' marginBottom={1}>
                    <Button variant='contained' onClick={openEstimateWarningDialog}>Create Estimate</Button>
                </Grid>
                <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Box sx={{ width: '100%', }}>
                        <Paper>
                            <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                // size={dense ? 'small' : 'medium'}
                                >
                                    <TableHead className='tableHead'>
                                        <TableRow    >
                                            <TableCell className='tableTimeSheet'>Task ID</TableCell>
                                            <TableCell className='tableTimeSheet'>Task Name</TableCell>
                                            <TableCell className='tableTimeSheet'>Wbs</TableCell>
                                            <TableCell className='tableTimeSheet'>Status</TableCell>
                                            <TableCell className='tableTimeSheet'>Duration </TableCell>
                                            <TableCell  >Action </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(rows).length === 0 ? (<TableRow>
                                            <TableCell colSpan={10}>
                                                <Typography align="center">No Data</Typography>
                                            </TableCell>
                                        </TableRow>)
                                            :
                                            (<>
                                                {rows?.map((items) => (
                                                    <React.Fragment>
                                                        <TableRow onClick={() => handleRowClick(items?.phase)}
                                                            style={{
                                                                backgroundColor: items?.phase === "sales" ? "#AEBBFF" : items?.phase === "activeConstruction" ? "#D9DFFE" :
                                                                    items?.phase === "preConstruction" ? "#C2CCFF" : items?.phase === "postConstruction" ? "#F1F3FF" : "white"
                                                            }}
                                                        >
                                                            <TableCell >
                                                                {(items?.phase).split(/(?=[A-Z])/).join("-").replace(/^./, items?.phase[0].toUpperCase())}
                                                            </TableCell>
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell>
                                                                <IconButton
                                                                    aria-label="expand row"
                                                                    size="small"
                                                                    sx={{ fontWeight: 500 }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setOpen(!open);
                                                                        handleRowClick(items?.phase);
                                                                    }}
                                                                    style={{
                                                                        backgroundColor: items?.phase === "sales" ? "#AEBBFF" : items?.phase === "activeConstruction" ? "#D9DFFE" :
                                                                            items?.phase === "preConstruction" ? "#C2CCFF" : items?.phase === "postConstruction" ? "#F1F3FF" : "white"
                                                                    }}
                                                                >
                                                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                        {openRow === items?.phase && (
                                                            <>
                                                                {(items?.tasks)?.length === 0 ?
                                                                    <TableCell colSpan={10}>
                                                                        <Typography align="center">
                                                                            <Button variant="text" onClick={() => addTask("null", items?.phase, "null")}
                                                                            >
                                                                                Add Task
                                                                            </Button>
                                                                        </Typography>
                                                                    </TableCell>
                                                                    :
                                                                    <>
                                                                        {(items?.tasks)?.map((row, index) => (
                                                                            <>
                                                                                {/* {row?.wbs.split(".").length === 2 ?
                                                                                    null
                                                                                    : */}
                                                                                <TableRow key={index}>
                                                                                    <TableCell size='small'>{row?.taskId}</TableCell>
                                                                                    <TableCell size='small'>{row?.taskName}</TableCell>
                                                                                    <TableCell size='small'>{row?.wbs}</TableCell>
                                                                                    <TableCell size='small'>{row?.status}</TableCell>
                                                                                    <TableCell size='small'>{row?.estimatedHours}</TableCell>
                                                                                    <TableCell size='small'>
                                                                                        <IconButton
                                                                                            id="demo-positioned-button"
                                                                                            aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                                                                                            aria-haspopup="true"
                                                                                            aria-expanded={openMenu ? 'true' : undefined}
                                                                                            onClick={(e) => handleClick(e, index)}
                                                                                        >
                                                                                            <MoreVertIcon />
                                                                                        </IconButton>
                                                                                        <Menu
                                                                                            id="demo-positioned-menu"
                                                                                            aria-labelledby="demo-positioned-button"
                                                                                            anchorEl={anchorEl}
                                                                                            open={openMenu === true && menuIndex === index ? true : false}
                                                                                            onClose={handleClose}
                                                                                            anchorOrigin={{
                                                                                                vertical: 'top',
                                                                                                horizontal: 'left',
                                                                                            }}
                                                                                            transformOrigin={{
                                                                                                vertical: 'top',
                                                                                                horizontal: 'left',
                                                                                            }}
                                                                                        >
                                                                                            <MenuItem onClick={() => editTask(row)}>
                                                                                                <Stack direction={'row'} spacing={1}>
                                                                                                    <VisibilityIcon fontSize='small' />
                                                                                                    <Typography>View</Typography>
                                                                                                </Stack>
                                                                                            </MenuItem>
                                                                                            <MenuItem onClick={() => editTask(row)}
                                                                                                disabled={row?.wbs.split(".").length === 2 ? true : false}
                                                                                            >
                                                                                                <Stack direction={'row'} spacing={1}>
                                                                                                    <EditIcon fontSize='small'
                                                                                                    />
                                                                                                    <Typography>Edit</Typography>
                                                                                                </Stack>
                                                                                            </MenuItem>
                                                                                            <MenuItem onClick={() => getDependentTask(row)}
                                                                                                disabled={items?.phase === "sales" || row?.wbs.split(".").length === 2 ? true : false}
                                                                                            >
                                                                                                <Stack direction={'row'} spacing={1}>
                                                                                                    <DeleteIcon fontSize='small' />
                                                                                                    <Typography>Delete</Typography>
                                                                                                </Stack>
                                                                                            </MenuItem>

                                                                                            <MenuItem onClick={() => addTask(row?.taskId, items?.phase, row?.wbs)}>
                                                                                                <Stack direction={'row'} spacing={1}>
                                                                                                    <AddIcon fontSize='small' />
                                                                                                    <Typography>Add Below</Typography>
                                                                                                </Stack>
                                                                                            </MenuItem>
                                                                                            {/* <MenuItem onClick={addTask}>
                                                                                            <Stack direction={'row'} spacing={1}>
                                                                                                <AddIcon fontSize='small' />
                                                                                                <Typography>Add Above</Typography>
                                                                                            </Stack>
                                                                                        </MenuItem> */}
                                                                                        </Menu>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                {/* } */}
                                                                            </>
                                                                        ))}

                                                                    </>
                                                                }
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10]}
                                component="div"
                                // count={rows.length}
                                count={countRef.current}
                                rowsPerPage={rowsPerPageRef.current}
                                page={pageRef.current}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> */}
                        </Paper>
                    </Box>
                   
                    <AddTaskDialog open={openDialogs} onClose={() => setOpenDialogs(false)}
                        projectData={props} taskId={taskId} phase={phase} wbs={wbs} func={getTaskData}
                        tasks={tasks} editView={editView} />
                    <EstimateWarning open={openEstimateWarningDialogs} onClose={() => setOpenEstimateWarningDialogs(false)}
                    projectId = {props?.props?.id}
                         />
                    <CustomDialog
                        open={openDeleteDialog}
                        onClose={() => setOpenDeleteDialog(false)}
                        success={deleteSuccess}
                        error={deleteError}
                        Content={deleteSuccess ? "Deleted" : "Not Deleted"}
                    />
                </Grid>
                <React.Fragment>
                    <Dialog
                        open={openDependenciesView}
                        fullWidth={true}
                        maxWidth={"sm"}
                        scroll={'paper'}
                    >
                        <DialogTitle >
                            {"Warning! This tasks have the following dependency"}
                        </DialogTitle>
                        <DialogContent dividers>
                            {dependentTask?.map((task: any, index: any) => {
                                return (
                                    <DialogContentText marginBottom={1}>
                                        <Stack direction={'row'} spacing={1}>
                                            <Typography>{index + 1}.</Typography>
                                            <Typography> {task?.taskName}</Typography>
                                        </Stack>
                                    </DialogContentText>
                                )
                            })}
                        </DialogContent>
                        <DialogActions>
                            <Button className='sucessButton' variant="contained" size="small" onClick={() => deleteTaskById(deletedTaskData)}>Proceed</Button>
                            <Button className='dangerButton' variant="contained" size="medium" onClick={() => setOpenDependenciesView(false)} autoFocus>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </Grid>
        </>
    );
}
export default BidTaskList;