/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 06-Sep-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Confirm dialog to show message after reference project selection
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Backdrop, DialogContentText, IconButton, Typography } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CloseIcon from '@mui/icons-material/Close';
import gmslogo from "../../assets/logo.gif";
import CustomDialog from '../../pages/Dialog';
import { createEstimate } from '../../services/BidService';
import { useNavigate } from 'react-router-dom';

const EstimateWarning: React.FC<any> = (props) => {
    const { accessToken } = useOidcAccessToken();
    const [isLoading, setIsLoading] = React.useState(false);
    const [openEstimateDialog, setOpenEstimateDialog] = React.useState(false);
    const [estimateSuccess, setEstimateSuccess] = React.useState(false);
    const [estimateError, setEstimateError] = React.useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        props.onClose();
    };

    const createEstimates = async () => {
        setIsLoading(true);
        let result = await createEstimate(props?.projectId, accessToken);
        if (result.status === 200 && result.data !== null && result.data !== "") {
            setIsLoading(false);
            // handleClose();
            // setOpenEstimateDialog(true);
            // setEstimateSuccess(true);
            onNextPage();
        }
        else {
            setIsLoading(false);
            handleClose();
            setOpenEstimateDialog(false);
            setEstimateError(false);
        }
    }

    const onNextPage = () => {
        navigate("/salesestimate");
    }

    return (
        <React.Fragment>

            <Dialog
                open={props?.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
                </Backdrop>
                <DialogTitle sx={{ m: 0, p: 2, fontSize: '16px' }}>
                    Confirmation
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom marginBottom={1}>
                        Make sure you have added all the tasks, completed the Bom's, Tool's and added all resource required.
                    </Typography>
                    <DialogContentText gutterBottom >
                        Note that once you create an Estimate you cannot come back to this step.
                    </DialogContentText>
                    <DialogContentText gutterBottom >
                        Do you confirm and wish to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Go Back</Button>
                    <Button
                        onClick={createEstimates}
                        autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openEstimateDialog}
                onClose={() => setOpenEstimateDialog(false)}
                success={estimateSuccess}
                error={estimateError}
                Content={estimateSuccess ? "Estimate Created Successfully" : "Error"}
            />
        </React.Fragment>
    );
}

export default EstimateWarning;