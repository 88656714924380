import * as React from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
  Box, Table, TableBody, TableCell, TableContainer,
  TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography,
  Paper, Checkbox, IconButton, Tooltip,
  Grid, InputBase, useMediaQuery, Stack, Button, Dialog, DialogContent, DialogActions, Snackbar, Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import SearchIcon from "@mui/icons-material/Search";
import { deleteVendor, getVendorcount, getVendors } from '../../services/VendorService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import Header from '../Header';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomDialog from '../../pages/Dialog';
import VendorRegister from './VendorRegister';

interface Data {
  id: number;
  companyName: string;
  phoneNumber: string;
  email: string;
  status: string;
  zipCode: string;
}

function createData(
  id: number,
  companyName: string,
  phoneNumber: string,
  email: string,
  status: string,
  zipCode: string,
): Data {
  return {
    id,
    companyName,
    phoneNumber,
    email,
    status,
    zipCode,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'companyName',
    numeric: false,
    disablePadding: true,
    label: 'Company Name',
  },
  {
    id: 'phoneNumber',
    numeric: true,
    disablePadding: false,
    label: 'Phone Number',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'zipCode',
    numeric: true,
    disablePadding: false,
    label: 'Zip Code',
  }
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead >
      <TableRow className='tableHead'>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel

              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          CUSTOMERS
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

const VendorList: React.FC = () => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('companyName');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [rows, setRows] = React.useState<any>([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const navigate = useNavigate();
  const [vendorId, setVendorId] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState('');
  const VENDORACTIVESTATUS = "Active";
  const VENDORINACTIVESTATUS = "Inactive";
  const [searchParams, setSearchParams] = React.useState<any>(null);
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState(false);
  const [count, setCount] = React.useState(Number);
  const [openVendor, setOpenVendor] = React.useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleCloseVendorDialog = () => {
    setOpenVendor(false);
  };


  const handleDialogOpen = (vendorId: any) => {
    setVendorId(vendorId);
    setOpenDialog(true);
  };

  const handleToastClick = (message: any) => {
    setOpen(true);
    setToastMessage(message);
  };

  const onDeleteVendor = async (vendorId: any) => {
    await setOpenDialog(false);
    setOpenDialogs(true);
    let result = await deleteVendor(vendorId, VENDORINACTIVESTATUS, accessToken);
    if (result.status === 204 || result.status === 200) {
      // handleToastClick("Deleted");
      // getVendorList();
      setDeleteSuccess(true);
      getVendorList(searchParams);
    }
    else {
      // handleToastClick("Not Deleted");
      setDeleteError(true);
    }
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const onNextPage = () => {
    setOpenVendor(true)
  }
  const CustomCheckbox = styled(Checkbox)({
    '& .MuiSvgIcon-root': {
      width: '1em', // Customize the width of the checkbox
      height: '1em',
      fontWeight: '300',
      color: '#ccc'// Customize the height of the checkbox
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      fill: '#007bff', // Customize the fill color when checked
    },
  });

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const SearchBar = () => (
    <div className='searchBar'>
      <Grid container rowSpacing={1} mt={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
        <Grid item xs={6} > <Paper
          variant="outlined"
          component="form"
          className='searchField'
          sx={{ p: "2px 4px", display: "flex", alignItems: "center", maxWidth: 300 }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{ "aria-label": "search google maps" }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
        </Grid>
        <Grid item xs={6} className='alignEnd' sx={{ padding: '15px' }}>
          <Button
            variant="contained" size="large"
            onClick={onNextPage}
          >
            <AddOutlinedIcon className='personIcon' />&nbsp; Add New Vendor
          </Button>
        </Grid>
        {/* <Grid item xs={2}><Tooltip title="Print">
          <IconButton>
            <PrintIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
          <Tooltip title="Share">
            <IconButton>
              <ShareIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Settings">
            <IconButton>
              <SettingsIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </Grid> */}
      </Grid>
    </div>
  );
  const getVendorList = async (searchParams:any) => {
    let response = await getVendorcount(VENDORACTIVESTATUS, searchParams, accessToken);
    setCount(response.data);
    let result = await getVendors(VENDORACTIVESTATUS, searchParams, page, rowsPerPage, accessToken);
    setRows(result.data);
  }
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const onEditPage = (vendorId: any) => {
    navigate("/admin/vendorregistration",
      { state: { vendorId } }
    );
  } 
  const inputchange = async (e: any) => {
    var value = e.target.value === "" ? null : e.target.value;
    if(e.target.value.length >= 3){
      setSearchParams(value);
      getVendorList(value);
    }
    if(e.target.value.length === 0){
      setSearchParams(value);
      getVendorList(value);
    }
  };

  React.useEffect(() => {
    getVendorList(null);
  }, [page, rowsPerPage])
  return (
    <>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
        <Alert onClose={handleClose} security='success' sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
      >
        <Header title="Vendors List" subtitle="" />
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={12}>
          <Box sx={{ width: '100%', }}>
            <Paper className='POCard' sx={{ width: '100%', mb: 2 }}>
              <div className='searchBar'>
                <Grid container rowSpacing={1} mt={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                  <Grid item xs={6} > 
                    <Paper
                    variant="outlined"
                    component="form"
                    className='searchField'
                    sx={{ p: "2px 4px", display: "flex", alignItems: "center", maxWidth: 300 }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search"
                      inputProps={{ "aria-label": "search google maps" }}
                      onChange={(e) => inputchange(e)}
                    />
                    <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  </Grid>
                  <Grid item xs={6} className='alignEnd' sx={{ padding: '15px' }}>
                    <Button
                      variant="contained" size="large"
                      onClick={onNextPage}
                    >
                      <AddOutlinedIcon className='personIcon' />&nbsp; Add New Vendor
                    </Button>
                  </Grid>
                  {/* <Grid item xs={2}><Tooltip title="Print">
          <IconButton>
            <PrintIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
          <Tooltip title="Share">
            <IconButton>
              <ShareIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Settings">
            <IconButton>
              <SettingsIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </Grid> */}
                </Grid>
              </div>
              <TableContainer sx={{ marginTop: 3 }}>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                  />
                  {rows?.length === 0 ? <Typography textAlign={'center'}>No Vendors</Typography> :
                    <TableBody>
                      {rows?.map((row, index) => {
                        const isItemSelected = isSelected(Number(row?.id));
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, Number(row?.id))}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            sx={{ cursor: 'pointer' }}
                            
                          >
                            <TableCell padding="checkbox" size='small'>
                              <CustomCheckbox
                                className='check'
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell size='small'
                              // className='tablerows'
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row?.companyName}
                            </TableCell>
                            <TableCell size='small'>{row?.primaryMobileNumber}</TableCell>
                            <TableCell size='small'>{row?.email}</TableCell>
                            <TableCell size='small'>{row?.status}</TableCell>
                            <TableCell size='small'>{row?.zipCode}</TableCell>
                            <TableCell size='small'>
                              <Stack direction={'row'}>
                                <Tooltip title="Edit">
                                  <IconButton onClick={() => onEditPage(row.id)} sx={{
                                    ":hover": {
                                      color: "green"
                                    }
                                  }}>
                                    <EditRoundedIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton onClick={() => handleDialogOpen(row.id)} sx={{
                                    ":hover": {
                                      color: "red"
                                    }
                                  }}>
                                    <DeleteRoundedIcon />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {/* {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
              <TablePagination
                sx={{ border: 'none', }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            {/* <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            /> */}
          </Box>
        </Grid>
      </Grid>
      {openVendor &&(
        <VendorRegister open={openVendor} onClose={handleCloseVendorDialog}/>
      )}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
        <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
          <DialogContent >
            <Typography textAlign={'center'}><ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
              Are you sure?
            </Typography>
            <Typography textAlign={'center'}>
              You won't be able to revert this!
            </Typography>
          </DialogContent>
        </Box>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleDialogClose} className="dangerButton">No, Cancel!</Button>
          <Button
            onClick={() => onDeleteVendor(vendorId)}
            autoFocus className="primaryTonalButton">
            Yes, Delete it!
          </Button>
        </DialogActions>

      </Dialog>
      <CustomDialog
        open={openDialogs}
        onClose={() => setOpenDialogs(false)}
        success={deleteSuccess}
        error={deleteError}
        Content={deleteSuccess ? "Deleted Successfully" : "Not Deleted Successfully"}
      />
    </>

  );
}

export default VendorList;