/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Shiyamkumar.G
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1491           |  22/3/24    |   Nandha      |  After add to cart the cart count will not increase without refresh
* 609,508        | 27-03-2024  |  shiyamkumar  |  Dynamic Price Change Issue Fixed
* 1600           |  10/4/24    |   Nandha      |  edit bid backdrop implements
* 1767           |  02/04/24   | shiyam Kumar  |  Salesperson Create Bid Issue
* 1937           |  15/05/24   | Vanitha       |  Mandatory implementation in Property name
* 2176           | 09-07-2024  | shiyam kumar  |  sales person create bid And assignement issue.
*/

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Check from '@mui/icons-material/Check';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Backdrop, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Link, StepIconProps, StepLabel, useTheme } from '@mui/material';
import { tokens } from "../../hooks/theme";
import './CreateBid.scss';
import TaskSelector from './selectorComponent/TaskSelector';
import Estimate from './estimate/Estimate';
import ShoppingCart from './shoppingCart/ShoppingCart';
import { addToCart, createBid, getAssetsByAssetUid, getCartCount, getCartList, getUsers } from '../../services/BidService';
import CreateBidForm from './fineTuneSelector/CreateBidForm';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import gmslogo from "../../assets/logo.gif";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ConstructionIcon from '@mui/icons-material/Construction';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import RoofingIcon from '@mui/icons-material/Roofing';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import StoreIcon from '@mui/icons-material/Store';
import { useLocation, useNavigate } from 'react-router-dom';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import dayjs from 'dayjs';
import { UserContext, getUserSession } from '../../hooks/UserSession';
import Cart from '../cart/CartDialog';
import { TaskContext } from "../../hooks/UserSession";
import { useContext, useRef } from 'react';
import SalesmanCalendar from '../calendar/SalesmanCalendar';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SalesBookingCheckout from '../salesBookingConfirmation/SalesBookingCheckout';
import QuestionRenderer from '../../pages/QuestionRenderer/QuestionRenderer';
import { Value } from 'sass';


export const dateFormatter = (value: any) => {
  const date = new Date(value);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[date.getMonth()] + " " + date.getDate();
};

const CreateBid: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  let bid = location.state?.data;
  let formState = location.state?.state;
  let salesDate = location.state?.salesDate;
  console.log(formState, bid, salesDate);
  let data: any = {
    createdBy: '',
    updatedBy: '',
    comment: '',
    jobDoneBy: 'local',
    description: '',
    status: "",
    startDate: null,
    selectedDate: null,
    projectTemplateData: '',
    assetTemplateData: '',
    tradeQuestionTemplateVersion: '',
    assetQuestionTemplateVersion: '',
    tradeType: ''
  };

  const steps = [{ title: 'Select Trade' }, { title: 'Fine-tune Selection' }, { title: 'Finalize' }];
  const [activeStep, setActiveStep] = React.useState<any>(bid === undefined ? 0 : 2);
  const [bidData, setBidData] = React.useState<any>(bid === undefined ? data : bid);
  const [bids, setBids] = React.useState<any>([]);
  const [bidList, setBidList] = React.useState<any>(undefined);
  const [breadcrumb, setBreadcrumb] = React.useState<any>([]);
  const [selectedBid, setSelectedBid] = React.useState<any>(bid === undefined ? undefined : bid);
  const [openCart, setOpenCart] = React.useState(false);
  const [startDate, setStartDate] = React.useState<any>(bid === undefined ? undefined : bid.startdate);
  const [stepValidated, setStepValidated] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [customerName, setCustomerName] = React.useState<string>();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [selectedAsset, setSelectedAsset] = React.useState<any>(bid);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>(bid === undefined ? {} : { 0: true, 1: true, 2: true });
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const color = (index: any) => {
    return completed[index] ? activeStep === index ? "white" : colors.primary[500] : activeStep === index ? "" : 'gray'
  };
  const [cartCount, setCartCount] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [estimating, setEstimating] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [role, setRole] = React.useState<any>([] as any[]);
  const [buttonVisible, setButtonVisible] = React.useState<any>(true);
  const [userdata, setUserData] = React.useState<any>([] as any[]);
  const { isTaskCompleted, handleCompleted } = React.useContext(TaskContext);
  const { userSessionDetails, setUserDetails } = useContext(UserContext);
  const [hideButton, setHideButton] = React.useState(false);
  const bidStatus = ["bidPending", "bidReview", "awaitingEstimation", "Contract Approval", "Contract Upload"];
  const [openSignup, setOpenSignup] = React.useState(false);
  const [showAnotherComponent, setShowAnotherComponent] = React.useState(false);
  const odmData = useRef<any>('');
  const questionRendererRef = React.useRef<any>(null);
  const odmProjectData = useRef<any>('');
  const [tradeType, setTradeType] = React.useState<any>();
  const tradeVersion = useRef<any>('');
  const assetVersion = useRef<any>('');

  console.log(bidData);

  const updateDate = (date: any) => {
    setBidData({ ...bidData, selectedDate: dayjs(date.$d).format('YYYY-MM-DD') + "T00:00Z" });
    // Object.assign(componentData.current, { startDate: dayjs(date.$d).format('YYYY-MM-DD') + "T00:00:00.000Z" });
    // validator();
  }

  const closeDateConfirmation = () => {
    // setSelectedDate(startDateValidator(new Date(props?.props?.startDate)));
    setShowAnotherComponent(false);
  }

  React.useEffect(() => {
    setUserData(userSessionDetails);
    //console.log(userSessionDetails);
    setRole(userSessionDetails?.role?.name);
    //console.log(role);
    if (bid !== undefined) {
      // setSelectedBid(bid);
      // setCompleted({ 0: true, 1: true, 2: true });
      setActiveStep(0);
      getUsers(accessToken).then(res => {
        if (bid.customerUid !== undefined) {
          res.data.map((user: any) => {
            if (user.customerUuid === bid.customerUid) {
              setCustomerName(user.displayName || userdata.firstname);
            }
          })
        }
      });
    }
  }, [userSessionDetails]);
  // console.log(userdata);

  function handleClick(e: any) {
    throw new Error('Function not implemented.');
  };
  const openCalender = () => {
    callParentComponent();
    setOpenSignup(true);
  }

  const updateData = (value: any) => {
    if ('selectedBid' in value) {
      setSelectedBid(value.selectedBid);
      Object.assign(bidData, value.selectedBid);
    }
    else if ('breadcrumbData' in value) {
      setBreadcrumb(value.breadcrumbData);
      Object.assign(bidData, value.data);
    }
    else if ('customerName' in value) {
      setCustomerName(value.customerName);
      setSelectedAsset(value.selectedAsset);
      Object.assign(bidData, value.data);
    } else {
      Object.assign(bidData, value.data);
    }

    if ('validated' in value) {
      setStepValidated(value.validated);
    }
    //  setBidData({...bidData, selectedDate: value});


    // if ('selectedDate' in value) {
    //   setHideButton(true)
    // }
  };

  const updatedSelectedDate = (date: any) => {
    callParentComponent();
    //console.log(odmData, odmProjectData);
    bidData['status'] = "bidReview"
    bidData['createdBy'] = userdata.firstname + " " + userdata.lastname;
    setBidData({
      ...bidData,
      selectedDate: date
    });
    //console.log(bidData)
  }
  ////console.log(bidData);

  const totalSteps = () => {
    return steps.length;
  };


  const getBidList = async () => {
    const response = await getCartList(accessToken, bidStatus);
    setBidList(response.data);
  };

  const validation = () => {
    let validated: any = true;
    if (bidData?.trade === '' || bidData?.trade === undefined || bidData?.trade === null) {
      validated = false;
    }
    if (bidData?.startDate === '' || bidData?.startDate === undefined || bidData?.startDate === null) {
      validated = false;
    }
    if (bidData?.customerUid === '' || bidData?.customerUid === undefined || bidData?.customerUid === null) {
      validated = false;
    }
    if (bidData?.assetUid === '' || bidData?.assetUid === undefined || bidData?.assetUid === null) {
      validated = false;
    }
    return validated;

  }

  const addBidToCart = (status: any) => {
    callParentComponent();
    // setBidData({ ...bidData, status: status })
    bidData['status'] = status;
    bidData['createdBy'] = userdata.firstname + " " + userdata.lastname;
    bidData['assetTemplateData'] = odmData.current;
    bidData['projectTemplateData'] = odmProjectData.current;
    bidData['tradeType'] = tradeType;
    // setOpen(true);
    // setLoading(true);
    let validated = validation();
    if (validated === true) {
      console.log(bidData);
      createBid(bidData, accessToken).then(async res => {
        if (res.status === 200 || res.status === 201) {
          handleCompleted();
          setOpen(false);
          setSuccess(true);
          setLoading(false);
          getBidList();
        }
        else {
          setError(true);
        }
      }

      );
    }
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    // console.log(bidData, bid)
    callParentComponent();
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setButtonVisible(true);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
    setButtonVisible(true);
  };

  const fetchRows = async () => {
    const response = await getCartCount(accessToken, bidStatus);
    setCartCount(response.data);
  };

  const handleCartClose = () => {
    setOpenCart(false);
    setSuccess(false);
  };

  const dateTimeFormatter = (value: any) => {
    const date = new Date(value);
    const month = date.getMonth().toString();
    return date.getFullYear() + "-" + (month.length === 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + ((date.getDate()).toString().length === 1 ? "0" + (date.getDate()).toString() : date.getDate()) + "T00:00:00.000Z";
  };

  const cancel = () => {
    setSuccess(false);
    setBidData(data);
    setActiveStep(0);
    setCompleted({});
    bid = undefined;
    setCustomerName(undefined);
    navigate(location.pathname, { replace: true });
    window.sessionStorage.setItem("reload", "true");
  };

  const handleComplete = async (index: any) => {
    console.log(index);
    if (stepValidated || completed[index]) {
      if (role === "customer") {
        const username = userdata.firstname + " " + userdata.lastname;
        setCustomerName(username);
      }
      else {
        bidData['sales'] = userdata.objectId;
      }

      // if (activeStep === 1) {
      //   setSelectedBid(undefined);
      //   await getAssetsByAssetUid(accessToken, bidData.assetUid).then((res: any) => {
      //     var asset = res.data;
      //     setSelectedAsset(asset);
      //   })
      //   setOpen(true);
      //   // setEstimating(true)
      //   if (bidData['jobStartdate'] !== null) {
      //     if (startDate !== undefined) {
      //       if (new Date(startDate).toLocaleDateString() === new Date(bidData['startdate']).toLocaleDateString()) {
      //         bidData['startdate'] = dateTimeFormatter(bidData['jobStartdate']);
      //       }
      //     } else {
      //       bidData['jobStartdate'] = bidData['startdate']
      //     }
      //   }
      //   let inputdata = Object.assign({}, bidData)
      //   delete inputdata['jobStartdate'];
      //   if (formState !== "edit") {
      //     inputdata['createdBy'] = userdata.firstname + " " + userdata.lastname;
      // } 
      // inputdata['updatedBy'] = userdata.firstname + " " + userdata.lastname;     
      //   await createBid(inputdata, accessToken).then(res => {
      //     if (res.status === 201) 
      //       {
      //       setEstimating(false);
      //       setBids(res.data)
      //       setOpen(false);
      //       const newCompleted = completed;
      //       newCompleted[activeStep] = true;
      //       let element = [{ type: 'trade', value: bidData.trade, element: <Link component="button" underline="none" key="1" color="inherit">{bidData.trade}</Link> },
      //       { type: 'materialType', title: bidData.materialType, element: <Link component='button' underline="none" key="1" color="inherit" id={'materialType'} onClick={(e) => handleClick(e)} sx={{ "&:hover": { color: colors.primary[500] + ' !important', background: 'none !important' } }} >{bidData.materialType}</Link> },
      //       { type: 'manufacturer', title: bidData.manufacturer, element: <Link component='button' underline="none" key="1" color="inherit" id={'manufacturer'} onClick={(e) => handleClick(e)} sx={{ "&:hover": { color: colors.primary[500] + ' !important', background: 'none !important' } }} >{bidData.manufacturer}</Link> }
      //       ]
      //       setBreadcrumb(element);
      //       setCompleted(newCompleted);
      //       handleNext();
      //     }
      //     else {
      //       setCount(count + 1);
      //       setOpen(false);
      //       setError(true);
      //     }
      //   });

      // } else {
      //   if(index === 2){
      //     if(selectedBid !== undefined){
      //     if(!(selectedBid === bidData)){
      //         const newCompleted = completed;
      //         newCompleted[activeStep] = true;
      //         setCompleted(newCompleted);
      //         handleNext();
      //         setCount(0);
      //       }
      //     }
      //   }else{
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
      setCount(0);
      //   }
      // }
    } else {
      // setCount(count + 1);
      // handleStep(activeStep);
      // setButtonVisible(false);

    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };


  const [chatopen, setChatOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChat = () => {
    setChatOpen(true);
  };

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setSuccess(false);
    setError(false);
  };

  const openCartDialog = () => {
    fetchRows();
    getBidList();
    setOpenCart(true);
    cancel();
  };

  const openSalesCalendar = (bidData: any) => {

    callParentComponent();
    bidData['status'] = "bidReview"
    bidData['createdBy'] = userdata.firstname + " " + userdata.lastname;
    // bidData['assetTemplateData']=odmData;
    // bidData['projectTemplateData']=odmProjectData
    setBidData({
      ...bidData,
      assetTemplateData: odmData.current,
      projectTemplateData: odmProjectData.current,
      tradeType: tradeType,
      tradeQuestionTemplateVersion: tradeVersion.current,
      assetQuestionTemplateVersion: assetVersion.current
    });

    // setOpenSignup(true);
    setShowAnotherComponent(true);
  };

  const buttonVisibility = (boolean: any) => {
    setButtonVisible(boolean);
  };

  const callParentComponent = () => {
    if (questionRendererRef.current && typeof questionRendererRef.current.handleSaveFunctionality === 'function') {
      questionRendererRef.current.handleSaveFunctionality();
    }
  };

  const handleSaveFunctionality = async (savedAssetData: any, savedProjectData: any, savedTradeType: any, savedTradeVersion: any, savedAssetVersion: any) => {
    console.log(savedAssetData, savedProjectData)
    odmData.current = savedAssetData;
    // console.log('hii')
    odmProjectData.current = savedProjectData;
    setTradeType(savedTradeType);
    tradeVersion.current = savedTradeVersion;
    assetVersion.current = savedAssetVersion;
    // setAssetData({ ...assetData, assetTemplatedata:"sample"});
    setBidData({ ...bidData, assetTemplateData: savedAssetData, projectTemplateData: savedProjectData, tradeType: savedTradeType, tradeQuestionTemplateVersion: savedTradeVersion, assetQuestionTemplateVersion: savedAssetVersion });
    updateData({ data: { ...bidData, assetTemplateData: savedAssetData, projectTemplateData: savedProjectData, tradeType: savedTradeType, tradeQuestionTemplateVersion: savedTradeVersion, assetQuestionTemplateVersion: savedAssetVersion } });
  };
  const forms = [
    <TaskSelector data={bid === undefined ? bidData : bid} completed={stepValidated} count={count} selectedCustomer={customerName} function={updateData} />,
    // <CreateBidForm data={bid === undefined ? bidData : bid} completed={stepValidated} count={count} breadcrumb={breadcrumb} function={updateData} setButton={buttonVisibility} />,
    // <Estimate bids={bids.length === 0 ? [bid] : bids} selectedBid={selectedBid} asset={selectedAsset} customerName={customerName} function={updateData} />,
    // <ShoppingCart bid={selectedBid} asset={selectedAsset} />
    <QuestionRenderer data={bid === undefined ? bidData : bid} ref={questionRendererRef} onSave={handleSaveFunctionality} validate={odmData}  />

,
    <ShoppingCart bid={bid === undefined ? bidData : bid} />
    // <QuestionRenderer onSave={function (data: any): void {
    //   throw new Error('Function not implemented.');
    // } } />
  ];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      active ? (
        <Check className="step-icon" sx={{ color: colors.primary[500] }} />
      ) :
        completed ? (
          <Check className="ative-step-icon" sx={{ border: "2px soild" + colors.primary[500], backgroundColor: colors.primary[500] }} />
        ) : (
          <RadioButtonUncheckedIcon className="unCheckedRadioButton" />
        )
    );
  };

  return (
    <>
      <Cart
        open={openCart}
        onClose={handleCartClose}
        onCount={fetchRows}
      />
      {formState === "edit" ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={formState === "edit"}
          onClick={handleClose}
        >
          <Grid container spacing={1} borderRadius={2} p={2} >
            <Grid xs={12}>
              <Typography variant='h3' sx={{ color: "#3c3c3cde", fontWeight: "500" }}>Create Bid</Typography>
              <Dialog open={success} >
                <DialogTitle className='success'>
                  <Box paddingLeft={8} paddingRight={8}>
                    <Typography textAlign={'center'}>
                      <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                    <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                      Success
                    </Typography>
                  </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                  <DialogContent >
                    <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Bid has been added to your cart Successfully</DialogContentText>
                  </DialogContent>
                  {role === "customer" ? <DialogActions style={{ justifyContent: "center" }}>
                    <Button variant='contained' className="successButton" onClick={openCartDialog}>Go to Cart</Button>
                  </DialogActions> : <DialogActions style={{ justifyContent: "center" }}>
                    <Button variant='contained' className="successButton" onClick={cancel}>Ok</Button>
                  </DialogActions>}
                </Box>
              </Dialog>

              <Dialog open={error} >
                <DialogTitle className='error'>
                  <Box paddingLeft={8} paddingRight={8}>
                    <Typography textAlign={'center'}>
                      <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                    <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                      Error
                    </Typography>
                  </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                  <DialogContent >
                    <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Error Couldn't Create Bid</DialogContentText>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "center" }}>
                    <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
                  </DialogActions>
                </Box>
              </Dialog>

              <Card style={{ marginTop: 20 }} className='POCard' variant="outlined" >
                <Backdrop
                  sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={open}
                  onClick={handleClose}
                >
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item md={12} style={{ textAlign: 'center' }}>
                      <img src={gmslogo} alt="Image" className="topBarLogo" />
                    </Grid>
                    <Grid item md={12} style={{ textAlign: 'center' }}>
                      {estimating && <Typography className="loading" variant='h4' color="blue">Estimating . . .</Typography>}
                      {loading && <Typography className="loading" variant='h4' color="blue">Loading . . .</Typography>}
                    </Grid>
                  </Grid>
                </Backdrop>

                <Grid container spacing={2} borderRadius={2} >
                  <Grid item xs={5} sm={5} md={4} lg={4} xl={3} className='stepper'>
                    <Grid item xs={12} pt={5}>
                      <Stepper nonLinear activeStep={activeStep} orientation="vertical" connector={<></>}>
                        {steps.map((data, index) => (
                          <Step key={data.title} completed={completed[index]} className={`step ${activeStep === index ? 'active-step' : ""}`} sx={{ backgroundColor: activeStep === index ? colors.primary[500] : "", cursor: completed[index] ? activeStep === index ? 'pointer' : "pointer" : activeStep === index ? 'default' : "default" }} onClick={completed[index] ? handleStep(index) : undefined}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <StepLabel StepIconComponent={QontoStepIcon} >
                                <Grid container display={'flex'} alignItems={'center'}>
                                  <Grid item xs={12} sm={2} md={2} lg={2} xl={2} className='stepNumber'>
                                    <Typography variant='h3' className='stepperText' color={completed[index] ? activeStep === index ? "" : colors.primary[500] : activeStep === index ? "" : 'gray'}>{"0" + (index + 1)}</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={10} md={10} lg={10} xl={10} pl={0.5}>
                                    <Typography variant='h4' className='stepperText' color={completed[index] ? activeStep === index ? "" : colors.primary[500] : activeStep === index ? "" : 'gray'}>{data.title}</Typography>
                                  </Grid>
                                </Grid>
                              </StepLabel>
                            </Grid>
                            <Grid container  >
                              {(bidData.customerUid !== undefined && bidData.trade !== undefined && index === 0 && completed[0]) ? (<><Grid item xs={3}></Grid><Grid item xs={1}><AccountCircleIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h6' className='stepperText' sx={{ color: color(index) }}>

                                {`${customerName}`}</Typography></Box></Grid>
                                <Grid item xs={3}></Grid><Grid item xs={1}><ConstructionIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h6' className='stepperText' sx={{ color: color(index) }}>{`${bid === undefined ? bidData.trade : bid.trade}`}</Typography></Box></Grid></>) :
                                (bidData.materialType !== undefined && bidData.manufacturer !== undefined && index === 1 && completed[1]) ? (<><Grid item xs={3}></Grid><Grid item xs={1}>< RoofingIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h5' className='stepperText' sx={{ color: color(index) }}>{`${bid === undefined ? bidData.materialType : bid.materialType}`}</Typography></Box></Grid>
                                  <Grid item xs={3}></Grid><Grid item xs={1}>< StoreIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h6' className='stepperText' sx={{ color: color(index) }}>{`${bid === undefined ? bidData.manufacturer : bid.manufacturer}`}</Typography></Box></Grid></>) :
                                  (selectedBid !== undefined && index === 2 && completed[2]) ? (<><Grid item xs={3}></Grid><Grid item xs={1}>< MonetizationOnIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h6' className='stepperText' sx={{ color: color(index) }}> {bid === undefined ? bidData.estimation : bid.estimation}</Typography></Box></Grid>
                                    <Grid item xs={3}></Grid><Grid item xs={1}><EventAvailableIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h6' className='stepperText' sx={{ color: color(index) }}>{dayjs(bid === undefined ? bidData.jobStartdate : bid.jobStartdate).format("DD MMM YYYY")}</Typography></Box></Grid></>) : (<><Grid pb={5}></Grid></>)}
                            </Grid>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                  <Grid container xs={7} sm={7} md={8} lg={8} xl={9} m={0} p={1}>
                    <React.Fragment>
                      <Grid item xs={12} md={12} pt={3} >

                        {forms.map((component: any, index: any) => {
                          if (activeStep === index) {
                            return component
                          }
                        })}

                      </Grid>
                      <Grid item xs={12} >
                        <Box className='actionButton'>
                          {activeStep !== 0 ? (
                            <Button
                              onClick={handleBack}
                              variant='contained'
                              startIcon={<ArrowBackIcon />}
                              className={`greyTonalButton`}
                              sx={{ marginLeft: "32px" }}
                            >
                              Previous
                            </Button>
                          ) : null}

                          <Box className={'buttonSpace'} />
                          {bid && <Button onClick={openCartDialog} sx={{ marginRight: "32px" }} variant='contained' className={`dangerButton`} endIcon={<HighlightOffIcon />}>
                            Cancel
                          </Button>}
                          {activeStep !== steps.length &&
                            (completed[activeStep] ? (
                              <>
                                <Typography variant='h5' color="rgb(60 60 60 / 87%)" sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Book an Appointment to Sales Person</Typography>
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <FormControl size="small" sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <DatePicker
                                      format='DD MMM YYYY'
                                      label="Choose Date"
                                      disablePast
                                      slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                      disableHighlightToday
                                      shouldDisableDate={date => date.day() === 0 || date.day() === 6}
                                      disabled = {bidData?.startDate === null || bidData?.trade === null || bidData?.customerUid === null|| bidData?.assetUid === null}                                   
                                      // value={selectedData.startDate !== '' ?dayjs(selectedData.startDate):null}
                                      value={(salesDate !== undefined) ? dayjs(salesDate) : null}
                                      minDate={dayjs().add(1, 'day')}
                                      onChange={(e) => updateDate(e)}

                                    />
                                  </FormControl>
                                </LocalizationProvider> */}
                                <Box sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <SalesmanCalendar props={bidData} open={openSignup} handleClose={() => setOpenSignup(false)} function={updatedSelectedDate} />
                                </Box>
                                {bidData?.selectedDate != null ? <Button onClick={() => openSalesCalendar(bidData)} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext' color="success">
                                  Submit
                                </Button>
                                  : <Button onClick={() => handleComplete(activeStep)} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext'>
                                    Save
                                  </Button>
                                }
                                <Button onClick={() => handleComplete(activeStep)} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext' endIcon={<ArrowForwardIcon />}>
                                  Next
                                </Button>
                              </>
                            ) : (
                              (completedSteps() === totalSteps() - 1)
                                ? <Button onClick={addBidToCart} variant='contained' className='SucessSolid' sx={{ marginRight: "32px" }}
                                  endIcon={<ShoppingCartCheckoutIcon />}>
                                  {formState === undefined ? "Add to Cart" : "update"}
                                </Button>
                                :
                                <><Typography sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant='h5' color="rgb(60 60 60 / 87%)">Book an Appointment to Sales Person</Typography>
                                  <Box sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <SalesmanCalendar props={bidData} open={openCalender} handleClose={() => setOpenSignup(false)} function={updatedSelectedDate} />
                                  </Box>
                                  {bidData?.selectedDate != null ? <><Button onClick={() => openSalesCalendar(bidData)} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext' endIcon={<ArrowForwardIcon />}>
                                    Submit
                                  </Button>
                                    <Button onClick={() => handleComplete(activeStep)} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext' endIcon={<ArrowForwardIcon />}>
                                      Next
                                    </Button>
                                  </>
                                    : <><Button onClick={() => handleComplete(activeStep)} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext' >
                                      Save
                                    </Button>
                                      <Button onClick={() => handleComplete(activeStep)} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext' endIcon={<ArrowForwardIcon />}>
                                        Next
                                      </Button></>}
                                </>
                            ))}
                        </Box>
                      </Grid>
                    </React.Fragment>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid xs={1}>
              {/* <FloatChatbot /> */}
            </Grid>
          </Grid>
        </Backdrop>
      ) : (
        <Grid container spacing={1} borderRadius={2} p={2} >
          <Grid xs={12}>
            <Typography variant='h3' sx={{ color: "#3c3c3cde", fontWeight: "500" }}>Create Bid</Typography>
            <Dialog open={success} >
              <DialogTitle className='success'>
                <Box paddingLeft={8} paddingRight={8}>
                  <Typography textAlign={'center'}>
                    <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                  <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                    Success
                  </Typography>
                </Box>
              </DialogTitle>
              <Box paddingLeft={8} paddingRight={8}>
                <DialogContent >
                  <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Bid has been added to your cart Successfully</DialogContentText>
                </DialogContent>
                {role === "customer" ? <DialogActions style={{ justifyContent: "center" }}>
                  <Button variant='contained' className="successButton" onClick={openCartDialog}>Go to Cart</Button>
                </DialogActions> : <DialogActions style={{ justifyContent: "center" }}>
                  <Button variant='contained' className="successButton" onClick={cancel}>Ok</Button>
                </DialogActions>}
              </Box>
            </Dialog>
            <Dialog open={error} >
              <DialogTitle className='error'>
                <Box paddingLeft={8} paddingRight={8}>
                  <Typography textAlign={'center'}>
                    <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                  <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                    Error
                  </Typography>
                </Box>
              </DialogTitle>
              <Box paddingLeft={8} paddingRight={8}>
                <DialogContent >
                  <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Error Couldn't Create Bid</DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                  <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
                </DialogActions>
              </Box>
            </Dialog>
            <Card style={{ marginTop: 20 }} className='POCard' variant="outlined" >
              <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
              >
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item md={12} style={{ textAlign: 'center' }}>
                    <img src={gmslogo} alt="Image" className="topBarLogo" />
                  </Grid>
                  <Grid item md={12} style={{ textAlign: 'center' }}>
                    {estimating && <Typography className="loading" variant='h4' color="blue">Estimating . . .</Typography>}
                    {loading && <Typography className="loading" variant='h4' color="blue">Loading . . .</Typography>}
                  </Grid>
                </Grid>
              </Backdrop>

              <Grid container spacing={2} borderRadius={2} >
                <Grid item xs={5} sm={5} md={4} lg={4} xl={3} className='stepper'>
                  <Grid item xs={12} pt={5}>
                    <Stepper nonLinear activeStep={activeStep} orientation="vertical" connector={<></>}>
                      {steps.map((data, index) => (
                        <Step key={data.title} completed={completed[index]} className={`step ${activeStep === index ? 'active-step' : ""}`} sx={{ backgroundColor: activeStep === index ? colors.primary[500] : "", cursor: completed[index] ? activeStep === index ? 'pointer' : "pointer" : activeStep === index ? 'default' : "default" }} onClick={completed[index] ? handleStep(index) : undefined}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <StepLabel StepIconComponent={QontoStepIcon} >
                              <Grid container display={'flex'} alignItems={'center'}>
                                <Grid item xs={12} sm={2} md={2} lg={2} xl={2} className='stepNumber'>
                                  <Typography variant='h3' className='stepperText' color={completed[index] ? activeStep === index ? "" : colors.primary[500] : activeStep === index ? "" : 'gray'}>{"0" + (index + 1)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} md={10} lg={10} xl={10} pl={0.5}>
                                  <Typography variant='h4' className='stepperText' color={completed[index] ? activeStep === index ? "" : colors.primary[500] : activeStep === index ? "" : 'gray'}>{data.title}</Typography>
                                </Grid>
                              </Grid>
                            </StepLabel>
                          </Grid>
                          <Grid container  >
                            {(bidData.customerUid !== undefined && bidData.trade !== undefined && index === 0 && completed[0]) ? (<><Grid item xs={3}></Grid><Grid item xs={1}><AccountCircleIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h6' className='stepperText' sx={{ color: color(index) }}>
                              {`${customerName}`}</Typography></Box></Grid>
                              <Grid item xs={3}></Grid><Grid item xs={1}><ConstructionIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h6' className='stepperText' sx={{ color: color(index) }}>{`${bid === undefined ? bidData.trade : bid.trade}`}</Typography></Box></Grid></>) :
                              (bidData.materialType !== undefined && bidData.manufacturer !== undefined && index === 1 && completed[1]) ? (<><Grid item xs={3}></Grid><Grid item xs={1}>< RoofingIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h5' className='stepperText' sx={{ color: color(index) }}>{`${bid === undefined ? bidData.materialType : bid.materialType}`}</Typography></Box></Grid>
                                <Grid item xs={3}></Grid><Grid item xs={1}>< StoreIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h6' className='stepperText' sx={{ color: color(index) }}>{`${bid === undefined ? bidData.manufacturer : bid.manufacturer}`}</Typography></Box></Grid></>) :
                                (selectedBid !== undefined && index === 2 && completed[2]) ? (<><Grid item xs={3}></Grid><Grid item xs={1}>< MonetizationOnIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h6' className='stepperText' sx={{ color: color(index) }}> {bid === undefined ? bidData.estimation : bid.estimation}</Typography></Box></Grid>
                                  <Grid item xs={3}></Grid><Grid item xs={1}><EventAvailableIcon sx={{ color: color(index) }} /></Grid><Grid item xs={8}><Box><Typography variant='h6' className='stepperText' sx={{ color: color(index) }}>{dayjs(bid === undefined ? bidData.jobStartdate : bid.jobStartdate).format("DD MMM YYYY")}</Typography></Box></Grid></>) : (<><Grid pb={5}></Grid></>)}
                          </Grid>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                </Grid>

                <Grid container xs={7} sm={7} md={8} lg={8} xl={9} m={0} p={1}>
                  {allStepsCompleted() ? (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <Typography className='stepperText'>
                          All steps completed - you&apos;re finished
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Box />
                          <Button onClick={handleReset}>Reset</Button>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item xs={12} md={12} pt={3} >

                        {forms.map((component: any, index: any) => {
                          if (activeStep === index) {
                            return component
                          }
                        })}

                      </Grid>
                      <Grid item xs={12} >
                        <Box className='actionButton'>

                          {activeStep !== 0 ? (
                            <Button
                              onClick={handleBack}
                              variant='contained'
                              startIcon={<ArrowBackIcon />}
                              className={`greyTonalButton`}
                              sx={{ marginLeft: "32px" }}
                            >
                              Previous
                            </Button>
                          ) : null}

                          <Box className={'buttonSpace'} />

                          {bid && <Button onClick={openCartDialog} sx={{ marginRight: "32px" }} variant='contained' className={`dangerButton`}>
                            Cancel
                          </Button>}
                          {activeStep !== steps.length &&
                            (completed[activeStep] ? (
                              <>
                                <Typography variant='h5' color="rgb(60 60 60 / 87%)" sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Book an Appointment to Sales Person</Typography>
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <FormControl size="small" sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <DatePicker
                                      format='DD MMM YYYY'
                                      label="Choose Date"
                                      disablePast
                                      slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                      disableHighlightToday
                                      shouldDisableDate={date => date.day() === 0 || date.day() === 6}
                                      disabled = {bidData?.startDate === null || bidData?.trade === null || bidData?.customerUid === null|| bidData?.assetUid === null}                                   
                                      // value={selectedData.startDate !== '' ?dayjs(selectedData.startDate):null}
                                      //value={(selectedData.startdate !== undefined) ? data.data.startDate !== undefined ? dayjs(data.data.startDate) : dayjs(data.data.jobStartdate) : null}
                                      minDate={dayjs().add(1, 'day')}
                                      onChange={(e) => updateDate(e)}

                                    />
                                  </FormControl>
                                </LocalizationProvider> */}
                                <Box sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <SalesmanCalendar props={bidData} open={openSignup} handleClose={() => setOpenSignup(false)} function={updatedSelectedDate} />
                                </Box>
                                {bidData?.selectedDate != null ? <Button sx={{ marginRight: "32px" }} onClick={() => openSalesCalendar(bidData)} variant='contained' className='createbidbtnnext' color="success">
                                  Submit
                                </Button>
                                  : <Button onClick={() => addBidToCart("bidPending")} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext'>
                                    Save
                                  </Button>
                                }
                                <Button onClick={() => handleComplete(activeStep)} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext' endIcon={<ArrowForwardIcon />}>
                                  Next
                                </Button>
                              </>
                            ) : (
                              (completedSteps() === totalSteps() - 1)
                                ?
                                <>
                                  <Typography variant='h5' color="rgb(60 60 60 / 87%)" sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Book an Appointment to Sales Person</Typography>
                                  <Box sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <SalesmanCalendar props={bidData} open={openSignup} handleClose={() => setOpenSignup(false)} function={updatedSelectedDate} />
                                  </Box>
                                  {bidData?.selectedDate != null ? <Button sx={{ marginRight: "32px" }} onClick={() => openSalesCalendar(bidData)} variant='contained' className='createbidbtnnext' color="success">
                                    Submit
                                  </Button>
                                    : <Button onClick={() => addBidToCart("bidPending")} variant='contained' className='SucessSolid' sx={{ marginRight: "32px" }}
                                      endIcon={<ShoppingCartCheckoutIcon />}>
                                      {formState === undefined ? "Add to Cart" : "update"}
                                    </Button>
                                  }
                                </>
                                :
                                <>
                                  <Typography sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant='h5' color="rgb(60 60 60 / 87%)">Book an Appointment to Sales Person</Typography>
                                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <FormControl size="small" sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      <DatePicker
                                        format='DD MMM YYYY'
                                        label="Choose Date"
                                        disablePast
                                        slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                        disableHighlightToday
                                        disabled = {bidData?.startDate === null || bidData?.trade === null || bidData?.customerUid === null|| bidData?.assetUid === null}
                                        shouldDisableDate={date => date.day() === 0 || date.day() === 6}
                                       minDate={dayjs().add(1, 'day')}
                                        onChange={(e) => updateDate(e)}
                                      />
                                    </FormControl>
                                  </LocalizationProvider> */}
                                  <Box sx={{ marginRight: "32px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <SalesmanCalendar props={bidData} open={openCalender} handleClose={() => setOpenSignup(false)} function={updatedSelectedDate} />
                                  </Box>
                                  {bidData?.selectedDate != null ? <Button sx={{ marginRight: "32px" }} onClick={() => openSalesCalendar(bidData)} variant='contained' className='createbidbtnnext' color="success">
                                    Submit
                                  </Button>
                                    : <Button onClick={() => addBidToCart("bidPending")} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext'>
                                      Save
                                    </Button>
                                  }
                                  <Button onClick={() => handleComplete(activeStep)} sx={{ marginRight: "32px" }} variant='contained' className='createbidbtnnext' endIcon={<ArrowForwardIcon />}>
                                    Next
                                  </Button>
                                </>
                            ))}
                        </Box>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid xs={1}>
            {showAnotherComponent && (
              <SalesBookingCheckout open={showAnotherComponent} bid={bidData} selectedDate={dayjs(bidData.selectedDate).format("DD MMM YYYY")} onClose={() => closeDateConfirmation()} />
            )}
          </Grid>
        </Grid>
      )
      }
    </>
  );
}

export default CreateBid;
