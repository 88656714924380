import { executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";



export const getTaskList = async (projectId:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/tasks/projectId/${projectId}`;
  return await executeGetData(url,accessToken);
};

export const getTasksByProjectId = async (projectId:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/tasks/getTasksByProjectId/projectId/${projectId}`;
  return await executeGetData(url,accessToken);
};

export const getMyTask = async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/getMyPendingSchedules`;
  return await executeGetData(url,accessToken);
};
export const getCurrentTask = async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/getCurrentTask`;
  return await executeGetData(url,accessToken);
};

export const getMyActiveProjectTasks = async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/getMyActiveProjectPendingSchedules`;
  return await executeGetData(url,accessToken);
};

export const getTasksBycustomerUIdAndStatus = async (resourceUid: string, resourceType: string,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/tasks/resourceType/${resourceType}/userUID/${resourceUid}`;
  return await executeGetData(url,accessToken);
};

export const getVerificationTasks = async (page:any, size:any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/tasks/MyVerificationTasks?page=${page}&size=${size}`;
  return await executeGetData(url,accessToken);
};

export const getVerificationTasksCount = async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/tasks/MyVerificationTasks/count`;
  return await executeGetData(url,accessToken);
};

export const getTaskByStatus = async (status:any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/search/status/${status}`;
  return await executeGetData(url,accessToken);
};


export const getTaskByProjectId = async (id: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/projectId/${id}`;
  return await executeGetData(url,accessToken);
};

export const getTaskByProjectIdAndStatus = async (id: any,status:any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/projectId/${id}/status/${status}`;
  return await executeGetData(url,accessToken);
};

export const getTaskByCustomerId = async (customerUId: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/customer/${customerUId}`;
  return await executeGetData(url,accessToken);
};

export const getTaskByCustomerIdAndStatus = async (customerUId: any,status:any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/customerTask/${customerUId}/status/${status}`;
  return await executeGetData(url,accessToken);
};
export const getCompletedTaskByassignees = async (assignees: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/completedTasks/assignees/${assignees}`;
  return await executeGetData(url,accessToken);
};

export const getPercentage = async (projectId: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/completedPercentage/project/${projectId}`;
  return await executeGetData(url, accessToken);
};

export const reassignTeamMember = async (payload: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/resourceReassignment`;
  return await executePostData(url, payload, accessToken);
};

export const getTasksByProjectIdAndTaskId = async (projectId: any, taskId:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/getTaskByProjectIdAndTaskId/projectId/${projectId}/taskId/${taskId}`;
  return await executeGetData(url, accessToken);
};

export const getDependenciesByTaskIdAndProjectId = async (projectId: any, taskId:any, status:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/getTaskByProjectIdAndTaskId/projectId/${projectId}/taskId/${taskId}/status/${status}`;
  return await executeGetData(url, accessToken);
};

export const getNewDependenciesByTaskIdAndProjectId = async (projectId: any, taskId:any, status:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/getTasks/projectId/${projectId}/taskId/${taskId}/status/${status}`;
  return await executeGetData(url, accessToken);
};

export const addTask = async (task:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/addTask`;
  return await executePostData(url, task, accessToken);
};

export const getTasks = async (projectId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/getBidTask/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
};

export const getPredecessorTasks = async (projectId: any, taskId:any, status:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/getPredecessorTasks/projectId/${projectId}/taskId/${taskId}/status/${status}`;
  return await executeGetData(url, accessToken);
};

export const getSuccessorTasks = async (projectId: any, taskId:any, status:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/getSuccessorTasks/projectId/${projectId}/taskId/${taskId}/status/${status}`;
  return await executeGetData(url, accessToken);
};

export const saveTask = async (accessToken: any, task: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/createNewTask`;
  return await executePostData(url, task, accessToken);
}

export const deleteTask = async (taskData:any ,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/tasks/deleteTask`;
  return await executePutData(url,taskData, accessToken);
};

// get task, boms and project tools
export const getTaskById = async (id:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/getTaskById/id/${id}`;
  return await executeGetData(url, accessToken);
};

export const updateTaskById = async (taskData:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/tasks/updateTask`;
  return await executePutData(url, taskData, accessToken);
};

export const getDependentTaskByTaskIdAndProjectId = async (projectId:any, taskId:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/getDependentTasks/projectId/${projectId}/taskId/${taskId}`;
  return await executeGetData(url, accessToken);
};


export const saveSelectedReferenceProjectTasks = async (projectId:any, referenceProjectId:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/saveSelectedReferenceProjectTasks/projectId/${projectId}/referenceProjectId/${referenceProjectId}`;
  return await executePostData(url, null, accessToken);
};

export const saveTemplateTasks = async (projectId:any, trade:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/storeTemplateTasks/projectId/${projectId}/trade/${trade}`;
  return await executePostData(url, null, accessToken);
};

export const getTaskTypeList = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tasks/getTaskTypes`;
  return await executeGetData(url, accessToken);
};