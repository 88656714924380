import { TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ItemData, ItemDef } from "../../../interface/SdmInterfaces";
import { Stack } from "@mui/system";
import { set } from "date-fns";

interface Props {
    itemDef: ItemDef;
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
}

const IntegerText: React.FC<any> = ({ itemDef, measureDef, onItemchange, itemDatas, mandatory }) => {

    //console.log(mandatory);

    const [itemData, setItemData] = useState<ItemData>();
    const [errors, setErrors] = useState<any>(false);

    const inputchange = (e: any) => {
        let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: e.target.value, CodeList: [], MetaKey: itemDef.MetaKey };
        setItemData(tempItemData);
        onItemchange(tempItemData);
    }

    useEffect(() => {
        if (mandatory === "Yes") {
            if (itemData?.Value === "") {
                setErrors(true);
            } else {
                setErrors(false);
            }
        }
    })

    return (
        <>
            <Stack direction="row" spacing={2}>
                <TextField
                    error={errors}
                    id="demo-helper-text-aligned"
                    label=""
                    name="name"
                    size="small"
                    sx={{ m: 1 }}
                    type="number"
                    fullWidth
                    value={(itemDef?.OID === itemDatas[0]?.ItemOID) ? itemDatas[0]?.Value : itemData?.Value}
                    inputProps={{ step: "1" }}
                    helperText={errors ? "Please fill this field" : ""}
                    onChange={inputchange}
                />
                <Typography sx={{ textAlign: 'center' }}>{measureDef?.Symbol?.TranslatedText?.text}</Typography>
            </Stack>
        </>
    )
}

export default IntegerText;