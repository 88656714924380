/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 1-3-2024
* @author      : Vijaykumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Vendor Register 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1517          |  28/3/24    |   Nandha    |  Vendor dialog implements for add ,edit and update 
* 1755         |  02/5/24     |   Vanitha   |  Responsive the  Vendor list table 
* 1756         |  02/5/24     |   Vanitha   |  Validation for Mobile Number
* 2249         | 07/Jun/2024  | vijaykumar  |  Removed material and tools add feature 
*/

import * as React from 'react';
import {
    Box, Button, TextField,
    Grid, Card, CardHeader,
    CardContent,
    styled, useMediaQuery, SelectChangeEvent, FormControl, InputLabel, Select, MenuItem, IconButton, Stack, Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    ListItemText,
    FormControlLabel,
    FormGroup,
    FormLabel
} from "@mui/material";
import { Theme, useTheme } from '@mui/material/styles';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FeedIcon from '@mui/icons-material/Feed';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import "./VendorRegister.scss"
import { GoogleMap, useJsApiLoader, MarkerF, StandaloneSearchBox } from '@react-google-maps/api';
import { editVendor, getVendorsById, saveVendor } from '../../services/VendorService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useLocation, useNavigate } from 'react-router-dom';
import { getMaterialNames } from '../../services/materialService';
import { getToolsName } from '../../services/ToolService';
import CustomDialog from '../../pages/Dialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getPreviousPageDetails } from '../../hooks/UserSession';
import CloseIcon from '@mui/icons-material/Close';


const FileUploaderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100px',
    border: '1px dashed black',
    borderRadius: '8px',
    padding: '2px'
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Service',
    'Material',
    // 'Resource',
];
const trades = [
    'Roofing',
    'Siding',
    'Gutter',
];

const units = [
    'SQ',
    'Bundle-100 LF',
    'Bundle-33 LF',
    'RL',
    'EA',
    '1.85inches - 10',
    'BD', '5LB', 'QTY', 'BX', 'TB', 'LF', 'Hour'
];

const grades = [
    'a', 'b', 'c'
]

const manufacturers = [
    'Owens Corning', 'GAF', 'Certain Teed', 'Norandex', 'James Hardie', 'Smart Side', 'ACM', 'Berger', ' US Aluminum Inc'
]
const containerStyle = {
    width: '100%',
    height: '300px'
};

const days = ["1 Day", "2 Days", "3 Days", "4 Days", "5 Days", "6 Days", "7 Days"]
const libraries: any = ['places'];

function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

// const FileUploadIcon = styled(CloudUploadIcon)({
//     fontSize: '4em',
// });

const VendorRegister: React.FC<any> = ({ open, onClose }) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
        libraries: libraries
    })
    const [service, setService] = React.useState<string[]>([]);
    const [vendorData, setVendorData] = React.useState<any>({
        companyName: "",
        displayName: "",
        status: "Active",
        primaryMobileNumber: '',
        email: "",
        website: "",
        accountNo: "",
        // services: "Service",
        description: "",
        approvedBy: "",
        createdBy: '',
        checkName: '',
        zipCode: '',
        address: '',
        location: '',
        city: '',
        country: '',
        state: '',
        leadTime: '',
        createdAt: new Date(),
        updatedAt: '',
        routerNo: '',
        payTerms: '',
        vendorTypes: [],
        services: [],
        suppliers: [],
        // tools: [],
        // materials: []
    });
    const [message, setMessage] = React.useState<any>({
        companyName: '',
        primaryMobileNumber: '',
        email: '',
        address: '',
        state: '',
        city: '',
        country: '',
        accountNo: null,
        area: '',
        services: '',
        tool: '',
        material: '',
        leadTime: '',

    });
    const [map, setMap] = React.useState(null);
    const inputRef = React.useRef<any>();
    const componentData = React.useRef<any>()
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [count, setCount] = React.useState(0);
    const navigate = useNavigate();
    let location = useLocation()
    const id = location.state?.vendorId;
    const [openToast, setOpenToast] = React.useState(false);
    const [material, setMaterial] = React.useState('');
    const [tool, setTool] = React.useState('');
    const [materialName, setMaterialName] = React.useState<any>([]);
    const [toolName, setToolName] = React.useState<any>([]);
    const [vendorDataObject, setVendorDataObject] = React.useState<any>();
    const [unit, setUnit] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const [success, setSuccess] = React.useState(false);
    const numberRegex = /^\d{10}$/;
    const routeNoRegex = /^\d{9}$/;
    const accountNoRegex = /^\d{5,17}$/;
    const [selectedVendorTypes, setSelectedVendorTypes] = React.useState<string[]>([]);
    const [subCategory, setSubCategory] = React.useState<string[]>([]);
    const [vendorType, setVendorType] = React.useState<string[]>([]);

    const vendorOptions = [
        { label: 'Suppliers', value: 'suppliers' },
        { label: 'Services', value: 'services' },
    ];
    const vendorTypes = [
        { label: 'Subcontractor', value: 'subContractor' },

    ];

    const subCategoryOptions = {
        suppliers: [
            { label: 'Tools', value: 'tools' },
            { label: 'Materials', value: 'materials' },
        ],
        services: [
            { label: 'Garbage Collector', value: 'garbageCollector' },
            { label: 'Trash Removal', value: 'trashRemoval' },
            { label: 'Portable Bathroom', value: 'portableBathroom' },
            { label: 'Resource', value: 'resource' },
            { label: 'Scaffold', value: 'scaffold' },
        ],
    };

    // Handle changes in the vendor type checkboxes
    const handleVendorOptionChange = (event: SelectChangeEvent<typeof selectedVendorTypes>) => {
        const {
            target: { value },
        } = event;

        // Determine the new vendor types based on the selection
        const newSelectedVendorTypes = typeof value === 'string' ? value.split(',') : value;

        // Filter out subcategories based on the removed vendor type
        let newSubCategory = subCategory;
        if (!newSelectedVendorTypes.includes('services')) {
            // If "services" is deselected, remove all service-related subcategories
            newSubCategory = newSubCategory.filter(sub => !subCategoryOptions.services.some(opt => opt.value === sub));
        }
        if (!newSelectedVendorTypes.includes('suppliers')) {
            // If "suppliers" is deselected, remove all supplier-related subcategories
            newSubCategory = newSubCategory.filter(sub => !subCategoryOptions.suppliers.some(opt => opt.value === sub));
        }

        // Update selected vendor types
        setSelectedVendorTypes(newSelectedVendorTypes);

        // Update the subcategories
        setSubCategory(newSubCategory);

        // Update the vendor data with the selected services and suppliers
        setVendorData({
            ...vendorData,
            services: newSelectedVendorTypes.includes('services')
                ? subCategoryOptions.services.filter(opt => newSubCategory.includes(opt.value)).map(opt => opt.label)
                : [],
            suppliers: newSelectedVendorTypes.includes('suppliers')
                ? subCategoryOptions.suppliers.filter(opt => newSubCategory.includes(opt.value)).map(opt => opt.label)
                : [],
        });
    };
    // Handle changes in the subcategory select box
    const handleSubCategoryChange = (event: SelectChangeEvent<string[]>) => {
        setSubCategory(event.target.value as string[]);
        setVendorData({
            ...vendorData,
            services: selectedVendorTypes.includes('services')
                ? subCategoryOptions.services.filter(opt => subCategory.includes(opt.value)).map(opt => opt.label)
                : [],
            suppliers: selectedVendorTypes.includes('suppliers')
                ? subCategoryOptions.suppliers.filter(opt => subCategory.includes(opt.value)).map(opt => opt.label)
                : []
        });

    };

    const handleVendorTypeChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const { checked } = event.target;
        const updatedVendorType = checked
            ? [...vendorType, value]
            : vendorType.filter((type) => type !== value);

        setVendorType(updatedVendorType);
        setVendorData({
            ...vendorData,
            vendorTypes: updatedVendorType
        });
    };

    // const handleSubCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const { value, checked } = event.target;
    //     setSubCategory(prev =>
    //         checked ? [...prev, value] : prev.filter(v => v !== value)
    //     );
    // };

    const setCancel = () => {
        setOpenDialogs(false);
    }
    const handleMaterialChange = (event: SelectChangeEvent) => {
        if (event.target.value !== undefined) {
            setMaterial(event.target.value as string);
            addItem(event.target.value);
        }
        else {
            setMaterial("");
            addItem("");
        }
    };

    const handleClose = () => {
        onClose();
        setVendorData({
            companyName: "",
            displayName: "",
            status: "Active",
            primaryMobileNumber: '',
            email: "",
            website: "",
            accountNo: "",
            // services: "Service",
            description: "",
            approvedBy: "",
            createdBy: '',
            checkName: '',
            zipCode: '',
            address: '',
            location: '',
            city: '',
            country: '',
            state: '',
            leadTime: '',
            createdAt: new Date(),
            updatedAt: '',
            routerNo: '',
            payTerms: '',
            services: [],
            suppliers: [],
            vendorTypes: []
            // tools: [],
            // materials: []
        })
        setCount(0);
    };

    const handleToolChange = (e: any) => {
        if (e.target.value !== undefined) {
            setTool(e.target.value as string);
            addToolItem(e.target.value);
        }
        else {
            setTool("");
            addToolItem("");
        }
    };

    const getMaterials = async () => {
        let result = await getMaterialNames(accessToken);
        setMaterialName(result.data);
    }
    const getTools = async () => {
        let result = await getToolsName(accessToken);
        setToolName(result.data);
    }
    const handleChange = (event: SelectChangeEvent<typeof service>) => {
        const {
            target: { value },
        } = event;
        setService(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

        if (value.includes("Service")) {
            getTools();
        }
        if (value.includes("Material")) {
            getMaterials();
        }
    };
    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(componentData.current);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const handleUnitChange = (event: SelectChangeEvent) => {
        setUnit(event.target.value as string);
    };

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])


    const getLocation = () => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                componentData.current = { lat: position.coords.latitude, lng: position.coords.longitude };
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }

    const onClick = (e: google.maps.MapMouseEvent) => {
        componentData.current = { lat: e.latLng?.lat(), lng: e.latLng?.lng() }
        let locationObject: any = {}
        const geocoder = new google.maps.Geocoder();
        const fulladdress: any[] = [];
        geocoder
            .geocode({ location: componentData.current })
            .then((response) => {
                if (response.results[0]) {
                    // eslint-disable-next-line array-callback-return
                    response.results[0].address_components.map((address: any) => {
                        if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise") || address.types.includes("plus_code")) {
                            fulladdress.push(address.long_name);
                            locationObject['address'] = fulladdress.join(",");
                        }
                        else if (address.types.includes("locality")) {
                            locationObject['city'] = address.long_name;
                        }
                        else if (address.types.includes("administrative_area_level_1")) {
                            locationObject['state'] = address.long_name
                        }
                        else if (address.types.includes("country")) {
                            locationObject['country'] = address.long_name
                        }
                        else if (address.types.includes("postal_code")) {
                            locationObject['zipCode'] = address.long_name
                        }
                    })
                }
                setVendorData({
                    ...vendorData, zipCode: locationObject.zipCode, country: locationObject.country,
                    address: locationObject.address, city: locationObject.city, state: locationObject.state,
                    location: Object.values(componentData.current).join(",")
                });
            })
    }
    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        const fulladdress: any[] = [];
        let locationObject: any = {}
        if (place) {
            place.address_components.map((address: any) => {
                if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise")) {
                    fulladdress.push(address.long_name);
                    locationObject['address'] = fulladdress.join(",");
                }
                else if (address.types.includes("locality")) {
                    locationObject['city'] = address.long_name;
                }
                else if (address.types.includes("administrative_area_level_1")) {
                    locationObject['state'] = address.long_name;
                }
                else if (address.types.includes("country")) {
                    locationObject['country'] = address.long_name;
                }
                else if (address.types.includes("postal_code")) {
                    locationObject['zipCode'] = address.long_name;
                }
                else {
                    locationObject['zipCode'] = '';
                    locationObject['address'] = fulladdress.join(",");

                }
            })
            componentData.current = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            setVendorData({
                ...vendorData, zipCode: locationObject.zipCode, country: locationObject.country,
                address: locationObject.address, city: locationObject.city, state: locationObject.state,
                location: Object.values(componentData.current).join(",")
            });
        }
    }
    const inputchange = (e: any) => {
        setVendorData({
            ...vendorData,
            [e.target.name]: e.target.value
        });
        console.log(vendorData);
    };

    const validation = () => {
        let validated: any = true;
        if (vendorData.companyName === '') {
            message.companyName = 'Enter Company Name';
            validated = false;

        }
        if (vendorData.state === '') {
            message.state = 'Enter Your State';
            validated = false;

        }
        if (vendorData.city === '') {
            message.city = 'Enter Your City';
            validated = false;

        }
        if (vendorData.country === '') {
            message.country = 'Enter Your Country';
            validated = false;

        }
        if (!numberRegex.test(vendorData.primaryMobileNumber)) {
            message.primaryMobileNumber = 'Enter Your Mobile Number with exactly 10 digits';
            validated = false;
        }

        if (vendorData.zipCode === '') {
            message.zipCode = 'Enter Your ZipCode';
            validated = false;

        }
        if (vendorData.address === '') {
            message.address = 'Enter Your address';
            validated = false;

        }
        if (vendorData.email === '' && regex.test(vendorData.email) === false) {
            message.email = 'Enter Your Email';
            validated = false;

        }
        if (vendorData.leadTime === '') {
            message.leadTime = 'Enter Delivery Lead Time';
            validated = false;
        }
        return validated;
    }
    // const handleUpdateVendorData = () => {
    //     const updatedVendorData = Object.assign({}, vendorData, {
    //         venderType:vendorType,
    //         services: selectedVendorTypes.includes('services') 
    //           ? subCategoryOptions.services.filter(opt => subCategory.includes(opt.value)).map(opt => opt.label)
    //           : [],
    //         suppliers: selectedVendorTypes.includes('suppliers') 
    //           ? subCategoryOptions.suppliers.filter(opt => subCategory.includes(opt.value)).map(opt => opt.label)
    //           : []
    //       });

    //       setVendorData(updatedVendorData);
    //       console.log(vendorData)
    //   };

    const handleSubmit = async (e: any) => {
        setCount(count + 1);

        console.log(vendorData)

        let validated = validation();
        if (validated === true) {
            setOpenDialogs(true);
            let result = await saveVendor(vendorData, accessToken)
            console.log(result)

            console.log(vendorData)
            setOpenDialogs(true);
            if (result.status === 201 && result.ok === true) {
                setCreateSuccess(true);
                setTimeout(() => {
                    handleCancel();
                    handleClose();
                }, 2000);

            }
            else {
                setCreateError(true);
            }
        }
        else {
            setCreateError(true);
        }
    }

    const handleCancel = () => {
        const previousPageDetails = getPreviousPageDetails();
        navigate(previousPageDetails.path,
            { state: { selectedTab: previousPageDetails.tabPosition } }
        );
    }
    const convertLocation = (locationString: any) => {
        let locationObject: any = {}
        let string = locationString;
        let st = string.split(",");
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        return locationObject;
    }
    const updateHandleSubmit = async () => {
        setCount(count + 1);
        let validated = validation();
        if (vendorDataObject.companyName === vendorData.companyName && vendorDataObject.displayName === vendorData.displayName
            && vendorDataObject.email === vendorData.email
            && vendorDataObject.primaryMobileNumber === vendorData.primaryMobileNumber &&
            vendorDataObject.website === vendorData.website && vendorDataObject.checkName === vendorData.checkName
            && vendorDataObject.services === vendorData.services && vendorDataObject.address === vendorData.address &&
            vendorDataObject.state === vendorData.state && vendorDataObject.city === vendorData.city &&
            vendorDataObject.country === vendorData.country && vendorDataObject.zipCode === vendorData.zipCode &&
            vendorDataObject.description === vendorData.description && vendorDataObject.accountNo === vendorData.accountNo &&
            vendorDataObject.routeNo === vendorData.routeNo) {
            setOpenToast(true);
            // setToastMessage({ ...toastMessage, message: "No value Changed", security: "error" });
            setSuccess(true);
        }
        else {
            if (validated === true) {
                setOpenDialog(true);
                let result = await editVendor(vendorData, accessToken);
                if (result.status === 200 || result.status === 201) {
                    setUpdateSuccess(true);
                    setTimeout(() => {
                        handleCancel();
                    }, 2000);
                    handleClose();
                }
                else {
                    setUpdateError(true)
                }
            }
        }
    };

    const addItem = (materialName: any) => {
        const material = {
            name: materialName,
            itemCode: "",
            grade: "",
            versionStartdate: "",
            manufacturer: "",
            tags: "",
            unit: "",
            cost: "",
        };

        const updateUsers = [
            // copy the current users state
            ...vendorData.materials,
            // now you can add a new object to add to the array
            material,
        ];
        // update the state to the updatedUsers
        setVendorData({ ...vendorData, materials: updateUsers });
    };
    const addToolItem = (toolName: any) => {
        const tool = {
            name: toolName,
            itemCode: "",
            tags: "",
            unit: "",
            versionStartdate: "",
            cost: "",
        };

        const updateTools = [
            ...vendorData.tools,
            tool,
        ];
        setVendorData({ ...vendorData, tools: updateTools });
    };

    const getVendorsByVendorId = async (vendorId: any) => {
        let result = await getVendorsById(vendorId, accessToken);
        console.log(result.data);
        let vendorList = result.data;
        setVendorData(vendorList);
        setVendorDataObject(vendorList);
        componentData.current = convertLocation((result.data).location);
    }

    // program to convert first letter of a string to uppercase
    const capitalizeFirstLetter = (str: any) => {
        // converting first letter to uppercase
        const capitalized = str.replace(/^./, str[0].toUpperCase());
        return capitalized;
    }
    React.useEffect(() => {
        getLocation();
        const id = location.state?.vendorId;
        if (id !== undefined) {
            getVendorsByVendorId(id);
            setShow(true);
        }
    }, [vendorData]);

    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSuccess(false);

    };
    return (
        <>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle sx={{ fontSize: "16px" }}>
                    Vendor Registration
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container justifyContent="center" alignItems="center" rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
                        <Grid item xs={12} md={12} sm={12}>
                            <CardContent sx={{
                                display: 'block',
                                alignItems: 'center',
                                '& > :not(style)': { m: 2 },
                            }}>
                                <Card className='vendorcard'>
                                    <CardHeader
                                        title={
                                            <div className='VendorTitle'><BadgeIcon />&nbsp;Name and Contact
                                            </div>
                                        }
                                    />
                                    <Grid container spacing={2} pb={3} pr={3} pl={3} pt={0}>
                                        <Grid item xs={6}>
                                            <TextField

                                                id="demo-helper-text-aligned"
                                                label="Company name"
                                                size="small"
                                                name="companyName"
                                                fullWidth
                                                value={vendorData?.companyName}
                                                error={(vendorData.companyName === '' && count !== 0)}
                                                helperText={(vendorData.companyName === '' && count !== 0) ? message.companyName : null}
                                                onChange={(e) => inputchange(e)}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Vendor display name"
                                                size="small"
                                                fullWidth
                                                name="displayName"
                                                value={vendorData?.displayName}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(count !== 0 && (regex.test(vendorData.email) === false))}
                                                id="demo-helper-text-aligned"
                                                label="Primary Email"
                                                size="small"
                                                fullWidth
                                                name="email"
                                                helperText={(vendorData.email === '' && count !== 0) ? message.email : null}
                                                value={vendorData?.email}
                                                onChange={(e) => inputchange(e)}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={count !== 0 && !numberRegex.test(vendorData.primaryMobileNumber)}
                                                id="demo-helper-text-aligned"
                                                label="Primary Mobile Number"
                                                size="small"
                                                fullWidth
                                                name="primaryMobileNumber"
                                                value={vendorData?.primaryMobileNumber || ''}
                                                helperText={(count !== 0 && !numberRegex.test(vendorData.primaryMobileNumber)) ? message.primaryMobileNumber : null}
                                                onChange={inputchange}
                                                required
                                                InputProps={{
                                                    inputProps: {
                                                        type: 'number',
                                                        max: "10",
                                                    },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Website"
                                                size="small"
                                                fullWidth
                                                name="website"
                                                value={vendorData?.website}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Name to print on checks"
                                                size="small"
                                                name="checkName"
                                                onChange={(e) => inputchange(e)}
                                                value={vendorData?.checkName}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl fullWidth size='small'>
                                                <InputLabel id="vendor-select-label">Type of Vendor</InputLabel>
                                                <Select
                                                    labelId="vendor-select-label"
                                                    id="vendor-select"
                                                    multiple
                                                    label='Type of Vendor'
                                                    value={selectedVendorTypes} // selectedVendorTypes as an array of selected values
                                                    onChange={handleVendorOptionChange} // Update logic to handle Select change
                                                    renderValue={(selected) => selected.join(', ')} // Display the selected values
                                                >
                                                    {vendorOptions.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            <Checkbox checked={selectedVendorTypes.indexOf(option.value) > -1} />
                                                            <ListItemText primary={option.label} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>


                                        {/* Subcategory Select Box */}
                                        <Grid item xs={6}>
                                            {selectedVendorTypes.length > 0 && (
                                                <FormControl fullWidth size='small'>
                                                    <InputLabel id="sub-category-label">Category</InputLabel>
                                                    <Select
                                                        labelId="sub-category-label"
                                                        id="sub-category"
                                                        multiple
                                                        value={subCategory}
                                                        onChange={handleSubCategoryChange}
                                                        label="Category"
                                                        renderValue={(selected) => selected.join(', ')}
                                                    >
                                                        {selectedVendorTypes.flatMap(type =>
                                                            subCategoryOptions[type]?.map(option => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    <Checkbox checked={subCategory.indexOf(option.value) > -1} />
                                                                    <ListItemText primary={option.label} />
                                                                </MenuItem>
                                                            ))
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={6}>
                                            <FormControl component="fieldset" fullWidth>
                                                <FormGroup style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}> {/* Use flexbox for inline display and align items to the right */}
                                                    {/* <FormLabel style={{ marginRight: '16px', flexShrink: 0 }}>
                                                        Is a Subcontractor?
                                                    </FormLabel> */}
                                                    {vendorTypes.map((option) => (
                                                        <FormControlLabel
                                                            key={option.value}
                                                            control={
                                                                <Checkbox
                                                                    checked={vendorType.indexOf(option.value) > -1}
                                                                    onChange={(e) => handleVendorTypeChange(e, option.value)} // Update vendorType array
                                                                    value={option.value}
                                                                />
                                                            }
                                                            label=' Is a Subcontractor?'
                                                            style={{ marginRight: '8px' }} // Add margin between checkboxes
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Card className='vendorcard'>
                                    <CardHeader className='VendorTitle'
                                        title={
                                            <div className='VendorTitle'><LocationOnIcon />&nbsp;Address
                                            </div>
                                        }
                                    />
                                    <Grid container spacing={2} pb={3} pr={3} pl={3} pt={0}>
                                        <Grid item xs={6}>
                                            {isLoaded
                                                &&
                                                <StandaloneSearchBox
                                                    onLoad={ref => inputRef.current = ref}
                                                    onPlacesChanged={handlePlaceChanged}
                                                >
                                                    <TextField
                                                        className='textfield'
                                                        error={(vendorData.address === '' && count !== 0)}
                                                        id="demo-helper-text-aligned"
                                                        label="Street Address"
                                                        size="small"
                                                        name="address"
                                                        value={vendorData?.address}
                                                        helperText={(vendorData.address === '' && count !== 0) ? message.address : null}
                                                        onChange={(e) => inputchange(e)}
                                                        fullWidth
                                                        required
                                                    />
                                                </StandaloneSearchBox>}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(vendorData.city === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="City"
                                                size="small"
                                                fullWidth
                                                name="city"
                                                value={vendorData?.city}
                                                helperText={(vendorData.city === '' && count !== 0) ? message.city : null}
                                                onChange={(e) => inputchange(e)}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(vendorData.state === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="State"
                                                type="text" // Set the input type to password
                                                size="small"
                                                name="state"
                                                value={vendorData?.state}
                                                helperText={(vendorData?.state === '' && count !== 0) ? message?.state : null}
                                                onChange={(e) => inputchange(e)}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(vendorData?.country === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Country"
                                                size="small"
                                                name="country"
                                                value={vendorData?.country}
                                                helperText={(vendorData?.country === '' && count !== 0) ? message?.country : null}
                                                onChange={(e) => inputchange(e)}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(vendorData?.zipCode === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="Zip Code"
                                                size="small"
                                                name="zipCode"
                                                value={vendorData?.zipCode}
                                                helperText={(vendorData?.zipCode === '' && count !== 0) ? message?.zipCode : null}
                                                onChange={(e) => inputchange(e)}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={13} pt={1}>
                                                {isLoaded ? (
                                                    <GoogleMap
                                                        mapContainerStyle={containerStyle}
                                                        center={componentData?.current}
                                                        zoom={10}
                                                        onLoad={onLoad}
                                                        onUnmount={onUnmount}
                                                        onClick={onClick}
                                                    >
                                                        <MarkerF
                                                            position={componentData.current}
                                                        >
                                                        </MarkerF>

                                                        <></>

                                                    </GoogleMap>
                                                ) : <></>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Card className='vendorcard'>
                                    <CardHeader className='VendorTitle'
                                        title={
                                            <div className='VendorTitle'><NoteAltIcon />&nbsp;Descriptions and Attachments
                                            </div>
                                        }
                                    />
                                    <Grid item xs={12} pb={3} pr={3} pl={3} pt={0}>
                                        <TextField
                                            className='textfield'
                                            id="demo-helper-text-aligned"
                                            label="Descriptions"
                                            multiline
                                            minRows={3}
                                            name="description"
                                            value={vendorData?.description}
                                            onChange={(e) => inputchange(e)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Card>
                                <Card className='vendorcard'>
                                    <CardHeader className='VendorTitle'
                                        title={
                                            <div className='VendorTitle'><AccountBalanceIcon />&nbsp;ACH payments
                                            </div>
                                        }
                                    />
                                    <Grid container spacing={1} pb={3} pr={3} pl={3} pt={0} >
                                        <Grid item xs={6}>
                                            <TextField
                                                className='textfield'
                                                helperText=""
                                                label="Account No"
                                                id="demo-helper-text-aligned"
                                                error={count !== 0 && !accountNoRegex.test(vendorData?.accountNo)}
                                                size="small"
                                                fullWidth
                                                name="accountNo"
                                                required
                                                value={vendorData?.accountNo}
                                                inputProps={{
                                                    minLength: 5,
                                                    maxLength: 17
                                                }}
                                                onChange={(e) => inputchange(e)}
                                            />
                                            <label className=' textfield'>Account no. is usually 5-17 digits</label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                className='textfield'
                                                id="demo-helper-text-aligned"
                                                size="small"
                                                helperText=""
                                                name='routeNo'
                                                fullWidth
                                                type="number"
                                                error={count !== 0 && !routeNoRegex.test(vendorData?.routeNo)}
                                                value={vendorData?.routeNo}
                                                onChange={(e) => inputchange(e)}
                                                label="Routing No"
                                                inputProps={{
                                                    maxLength: 9
                                                }}
                                            />
                                            <label className=' textfield'>Routing no. is 9 digits</label>
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Card className='vendorcard'>
                                    <CardHeader className='VendorTitle'
                                        title={
                                            <div className='VendorTitle'><FeedIcon />&nbsp;Additional Info
                                            </div>
                                        }
                                    />
                                    <Grid container spacing={1} pb={3} pr={3} pl={3} pt={0} >
                                        <Grid item xs={12}>
                                            <label className='subtitle'>Payments</label>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Payment Terms</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={vendorData?.payTerms}
                                                    error={(vendorData.payTerms === '' && count !== 0)}
                                                    label="Payment Terms"
                                                    name="payTerms"
                                                    onChange={(e) => inputchange(e)}
                                                >
                                                    <MenuItem value={30}>Net 30 Days</MenuItem>
                                                    <MenuItem value={60}>Net 60 Days</MenuItem>
                                                    <MenuItem value={90}>Net 90 Days</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="demo-simple-select-label">Delivery Lead Time</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={vendorData?.leadTime}
                                                    label="Delivery Lead Time"
                                                    name="leadTime"
                                                    onChange={(e) => inputchange(e)}
                                                    error={(vendorData.leadTime === '' && count !== 0)}
                                                >
                                                    {days.map((day, index) => (
                                                        <MenuItem value={index + 1}>{day}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                </Card>
                            </CardContent>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                        <Stack spacing={2} direction="row">
                            {id ? (
                                <Button variant="contained" className='sucessButton' size="medium" onClick={updateHandleSubmit}>
                                    Update
                                </Button>
                            ) : (
                                <Button className='sucessButton' variant="contained" size="medium" onClick={handleSubmit}>
                                    Save
                                </Button>
                            )}
                            <Button className='primaryTonalButton' variant="contained" size="medium" onClick={handleClose}>Cancel</Button>
                        </Stack>
                    </Box>
                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openDialogs}
                onClose={() => setCancel()}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Created Successfully" : " Error Couldnot Created "} />
            <CustomDialog
                open={openDialog}
                onClose={() => setCancel()}
                success={updateSuccess}
                error={updateError}
                Content={updateSuccess ? "Updated Successfully" : "Not Updated Successfully  "}
            />
            <Dialog open={success} >
                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent >
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="NoChangeButton" onClick={handleAlertClose}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>

        </>
    )
};

export default VendorRegister;