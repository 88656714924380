/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 05-Mar-2024
* @author      :Vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Vendor Service Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import { executeGetData, executePostData, executePutData } from './ApiBase';
import { ConfigUrl } from '../auth/OidcConfig';

export const saveVendor = async (vendor: any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/vendors`;
    return await executePostData(url, vendor, accessToken);
};

export const getVendorcount = async (status:any, searchParms:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/vendors/getVendorCount/status/${status}/searchParams/${searchParms}`;
  return await executeGetData(url, accessToken);
};

export const getVendors = async (status:any, searchParms:any, page:any, size:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/vendors/search/status/${status}/searchParams/${searchParms}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};

export const deleteVendor = async(vendorId:any, status:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/vendors/changeStatus/id/${vendorId}/status/${status}`;
  return await executePutData(url,null, accessToken);
}

export const editVendor = async(payload:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/vendors/updateVendor`;
  return await executePutData(url, payload, accessToken);
}

export const getVendorsById = async (id:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/vendors/getVendorsById/id/${id}`;
  return await executeGetData(url, accessToken);
};

export const getAllVendors = async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/vendors`;
  return await executeGetData(url, accessToken);
};

export const getMaterialsByVendorId = async (vendorId:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/vendors/getVendorMaterials/vendorId/${vendorId}`;
  return await executeGetData(url, accessToken);
};

export const getToolsByVendorId = async (vendorId:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/vendors/getVendorTools/vendorId/${vendorId}`;
  return await executeGetData(url, accessToken);
};