import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Grid, Tab, Tabs, Typography } from '@mui/material';
import QuestionRendererCard from './components/QuestionRendererCard';
import { CodeList, EventData, ItemDef, FormData, ItemGroupDef, SDM, ProjectData, ItemGroupData, ItemData, FormDef, EventDef, TemplateVersion, BasicDefinitions } from '../../interface/SdmInterfaces';
import { useLocation } from "react-router-dom";
import { getselectedTemplatesByVersion, getTemplatesByTradeName } from '../../services/TradeTemplateService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import sampleRenderData from '../../data/sampleRenderData.json';
import { forwardRef, useImperativeHandle } from 'react';
import { getProjectById } from '../../services/ProjectService';
import { getAssetsByAssetUid, getTradeDetails } from '../../services/BidService';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface QuestionRendererProps {
    data?: any
    onSave: (assetData?: any, tradeData?: any, tradeType?: any, tradeVersion?: any, assetVersion?: any) => void;
    projectId?: any;
    //validate: () => void;
    validate: any;
}

const QuestionRenderer = forwardRef<{}, QuestionRendererProps>(({ onSave, projectId, data, validate }, ref) => {
    const [tabValue, setTabValue] = useState(0);
    const [template, setTemplate] = useState<TemplateVersion[]>();
    const [formDef, setFormDef] = useState<FormDef[]>();
    const [eventDef, setEventDef] = useState<EventDef[]>();
    const [itemGroup, setItemGroup] = useState<ItemGroupDef[]>([]);
    const [itemDef, setItemDef] = useState<ItemDef[]>([]);
    const [codeList, setCodeList] = useState<CodeList[]>([]);
    const [response, setResponse] = useState<SDM | any>();
    const [projectOID, setProjectOID] = useState<string>("");
    const [measureUnit, setMeasureUnit] = useState<BasicDefinitions | any>();

    const [assetresponse, setAssetResponse] = useState<SDM | any>();
    const [assetformDef, setAssetFormDef] = useState<FormDef[]>();
    const [asseteventDef, setAssetEventDef] = useState<EventDef[]>();
    const [assetitemGroup, setAssetItemGroup] = useState<ItemGroupDef[]>([]);
    const [assetitemDef, setAssetItemDef] = useState<ItemDef[]>([]);
    const [assetcodeList, setAssetCodeList] = useState<CodeList[]>([]);
    const [assetconditionDef, setAssetConditionDef] = useState<any>();
    const [assetOID, setAssetOID] = useState<any>("");
    const [assetData, setAssetData] = useState<ProjectData>();

    const [formData, setFormData] = useState<FormData[]>([]);
    const [eventData, setEventData] = useState<EventData[]>([]);
    const [projectData, setProjectData] = useState<ProjectData>();
    const [conditionDef, setConditionDef] = useState<any>();
    const [itemGroupData, setItemGroupData] = useState<ItemGroupData[]>([]);
    const [itemAssetGroupData, setItemAssetGroupData] = useState<ItemGroupData[]>([]);
    const [tradeName, setTradeName] = useState<any>();
    const trade = useRef<any>();
    const [assetDetails, setAssetDetails] = useState<any>();
    const [tradeDetails, setTradeDetails] = useState<any>();
    const [tradeType, setTradeType] = useState<any>();
    const [tradeVersion, setTradeVersion] = useState<any>();
    const [assetVersion, setAssetVersion] = useState<any>();
    const [templateVersion, setTemplateVersion] = useState<any>();
    const [aasettemplateVersion, setAssetTemplateVersion] = useState<any>();

    const [hide, setHide] = useState(false);
    const [show, setShow] = useState(false);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const location = useLocation();

    // console.log(data);

    useEffect(() => {
        (async () => {

            if (location.pathname === '/asset') {
                setHide(true);
                getTemplatesByTradeName("asset", 'asset', accessToken).then((res => {
                    if (res?.data !== (null || undefined || '')) {
                        let response = JSON.parse(res?.data?.questionTemplateData);
                        if (response !== (null || undefined)) {
                            setAssetResponse(response);
                            setAssetFormDef(response?.SDM?.Project?.TemplateVersion[0]?.FormDef);
                            setAssetEventDef(response?.SDM?.Project?.TemplateVersion[0]?.EventDef);
                            setTemplate(response.SDM.Project.TemplateVersion);
                            setMeasureUnit(response?.SDM?.Project?.BasicDefinition);
                            setAssetTemplateVersion(response?.SDM?.Project?.TemplateVersion[0].OID);
                            setAssetOID(response?.SDM?.Project?.OID);
                            setAssetVersion(res?.data?.version);
                            handleChange(null, response, 0);
                        }
                    }
                }));
                if (data !== (null || undefined)) {
                    handleSampleData(null, JSON.parse(data?.assetTemplateData), 'A.1', 'E.1', 'F.1');
                    setAssetData(JSON.parse(data?.assetTemplateData));
                }
                setShow(true);
            } else if (location.pathname === '/soldproduction/projectinformation') {
                //await getProjectByProjectId();

                const res = await getProjectById(accessToken, projectId);
                console.log(res);
                if (res.status === 200) {
                    let tradename = res.data.trade;

                    // Set state and other values
                    setTradeName(tradename);
                    trade.current = tradename;
                    handleSampleData(null, JSON.parse(res?.data?.assetTemplateData), 'A.1', 'E.1', 'F.1');
                    setAssetData(JSON.parse(res?.data?.assetTemplateData));
                    setProjectData(JSON.parse(res?.data?.projectTemplateData));

                    let trades = trade.current;
                    getTradeDetails(trades, accessToken).then(resType => {
                        setTradeType(resType.data.type);
                        getTemplatesByTradeName("asset", 'asset', accessToken).then((res => {
                            if (res?.data !== (null || undefined || '')) {
                                let response = JSON.parse(res.data.questionTemplateData);
                                if (response !== (null || undefined)) {
                                    setAssetResponse(response);
                                    setAssetFormDef(response?.SDM?.Project?.TemplateVersion?.FormDef);
                                    setAssetEventDef(response?.SDM?.Project?.TemplateVersion?.EventDef);
                                    setTemplate(response.SDM.Project.TemplateVersion);
                                    setMeasureUnit(response.SDM.Project.BasicDefinition);
                                    setAssetOID(response?.SDM?.Project?.OID);
                                    setAssetVersion(res?.data?.version);
                                    handleChange(null, response, 0);
                                }
                            }
                        }));

                        // if (tabValue === 0 && assetDetails !== undefined) {
                        //handleSampleData(null, assetDetails, 'A.1', 'E.1', 'F.1');
                        // }
                        // else if (tradeDetails !== undefined) {
                        //     handleSampleData(null, tradeDetails, 'P.1', 'E.1', 'F.1');
                        // }
                        // setProjectData(sampleRenderData)

                        getselectedTemplatesByVersion(trades, resType.data.type, res?.data?.tradeQuestionTemplateVersion, accessToken).then((res => {
                            //console.log(res)
                            if (res?.data !== (null || undefined || '')) {
                                let response = JSON.parse(res.data.questionTemplateData);
                                if (response !== (null || undefined)) {
                                    setResponse(response);
                                    setFormDef(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1]?.FormDef);
                                    setEventDef(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1]?.EventDef);
                                    setTemplate(response?.SDM?.Project?.TemplateVersion);
                                    setTemplateVersion(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1].OID);
                                    setMeasureUnit(response.SDM.Project.BasicDefinition);
                                    setProjectOID(response?.SDM?.Project?.OID);
                                    setTradeVersion(res?.data?.version);
                                }
                            }
                        }));
                    });
                }
                setShow(true);
            } else {
                // const res = await getQuestionList();
                // console.log(res?.SDM);
                // if (res !== (null || undefined)) {
                //     setResponse(res);
                //     setFormDef(res?.SDM?.Project?.TemplateVersion[1]?.FormDef);
                //     setEventDef(res?.SDM?.Project?.TemplateVersion[1]?.EventDef);
                //     setTemplate(res?.SDM?.Project?.TemplateVersion);
                //     setTemplateVersion(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1].OID)
                //     setProjectOID(res?.SDM?.Project?.OID);
                //     setMeasureUnit(response.SDM.Project.BasicDefinition);
                //     //handleChange(null, res, 1);
                // }

                if (data?.trade !== (null || undefined)) {
                    getTradeDetails(data?.trade, accessToken).then(resType => {
                        setTradeType(resType.data.type);
                        getTemplatesByTradeName(data?.trade, resType.data.type, accessToken).then((res => {
                            //console.log(res)
                            if (res.data.questionTemplateData) {
                                let response = JSON.parse(res.data.questionTemplateData);
                                //console.log(response)
                                if (response !== (null || undefined)) {
                                    setResponse(response);
                                    setFormDef(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1]?.FormDef);
                                    setEventDef(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1]?.EventDef);
                                    setTemplate(response?.SDM?.Project?.TemplateVersion);
                                    setTemplateVersion(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1].OID);
                                    setMeasureUnit(response.SDM.Project.BasicDefinition);
                                    setProjectOID(response?.SDM?.Project?.OID);
                                    setTradeVersion(res?.data?.version);
                                }
                            }
                        }));
                    })
                }

                if (data !== (null || undefined) && data?.id !== (null || undefined)) {
                    console.log(data)
                    getselectedTemplatesByVersion(data.trade, data.tradeType, data?.tradeQuestionTemplateVersion, accessToken).then((res => {
                        //console.log(res)
                        let response = JSON.parse(res.data.questionTemplateData);
                        //console.log(response)
                        if (response !== (null || undefined)) {
                            setResponse(response);
                            setFormDef(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1]?.FormDef);
                            setEventDef(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1]?.EventDef);
                            setTemplate(response?.SDM?.Project?.TemplateVersion);
                            setTemplateVersion(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1].OID);
                            setMeasureUnit(response.SDM.Project.BasicDefinition);
                            setProjectOID(response?.SDM?.Project?.OID);
                            setTradeVersion(res?.data?.version);
                        }
                    }));

                    setProjectData(JSON.parse(data?.projectTemplateData));
                    handleSampleData(null, JSON.parse(data?.projectTemplateData), 'P.1', 'E.1', 'F.1');
                }

                // const assetResponse = await getAssetQuestionList();
                // if (assetResponse !== (null || undefined)) {
                //     setAssetResponse(assetResponse);
                //     setAssetFormDef(assetResponse?.SDM?.Project?.TemplateVersion?.FormDef);
                //     setAssetEventDef(assetResponse?.SDM?.Project?.TemplateVersion?.EventDef);
                //     setTemplate(assetResponse?.SDM?.Project?.TemplateVersion);
                //     setMeasureUnit(assetResponse?.SDM?.Project?.BasicDefinition);
                //     setAssetOID(assetResponse?.SDM?.Project?.OID);
                //     setAssetVersion(assetresponse?.version);
                //     handleChange(null, assetResponse, 0);
                // }

                getTemplatesByTradeName("asset", 'asset', accessToken).then((res => {
                    let response = JSON.parse(res.data.questionTemplateData);
                    //console.log(response);
                    if (response !== (null || undefined)) {
                        setAssetResponse(response);
                        setAssetFormDef(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1]?.FormDef);
                        setAssetEventDef(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1]?.EventDef);
                        setTemplate(response.SDM.Project.TemplateVersion);
                        setMeasureUnit(response?.SDM?.Project?.BasicDefinition);
                        setAssetTemplateVersion(response?.SDM?.Project?.TemplateVersion[(response?.SDM?.Project?.TemplateVersion.length) - 1].OID);
                        setAssetOID(response?.SDM?.Project?.OID);
                        setAssetVersion(res?.data?.version);
                        handleChange(null, response, 0);
                    }
                }));

                await getAssetsByAssetUid(accessToken, data?.assetUid).then((res: any) => {
                    //console.log(res.data);
                    if (res.status === 200) {
                        let tradename = res.data.trade;

                        // Set state and other values
                        setTradeName(tradename);
                        trade.current = tradename;
                        if (res?.data?.assetTemplateData !== (null || undefined)) {
                            handleSampleData(null, JSON.parse(res?.data?.assetTemplateData), 'A.1', 'E.1', 'F.1');
                            setAssetData(JSON.parse(res?.data?.assetTemplateData));
                            // setProjectData(JSON.parse(res?.data?.projectTemplateData));
                        }
                    }
                });
                setHide(false);
            }
        })();
    }, []);

    const getProjectByProjectId = async () => {
        try {
            // Await the result of the getProjectById function
            const res = await getProjectById(accessToken, projectId);
            //console.log(res);
            if (res.status === 200) {
                let tradename = res.data.trade;
                let assetTemplateData = res?.data?.assetTemplateData;
                let tradeTemplateData = res?.data?.projectTemplateData;

                // Set state and other values
                setTradeName(tradename);
                trade.current = tradename;
                handleSampleData(null, JSON.parse(assetTemplateData), 'A.1', 'E.1', 'F.1');
                setAssetData(JSON.parse(assetTemplateData));
                setProjectData(JSON.parse(tradeTemplateData));
            }
        } catch (error) {
            console.error('Error fetching project data:', error);
        }
    };

    // useEffect(() => {
    // }, [itemDef]);

    // useEffect(() => {
    //     console.log(validate.assetTemplateData);
    //     if (validate.assetTemplateData !== (undefined || null) || validate.projectTemplateData !== (undefined || null)) {
    //         const data = JSON.parse(validate.assetTemplateData || validate.projectTemplateData);
    //         if (data !== (undefined || null)) {
    //             data?.EventData?.map((ed: any) => {
    //                 ed?.FormData?.map((fd: any) => {
    //                     fd?.ItemGroupData?.map((igd: any) => {
    //                         igd?.ItemData?.map((id: any) => {
    //                             itemGroup?.map((ig) => {
    //                                 ig?.ItemRef?.map((ir) => {
    //                                     if(ir.Mandatory === 'Yes'){
    //                                         if(ir.ItemOID === id.ItemOID){
    //                                             if(id.Value === '') {
    //                                                 //setErrors(true);
    //                                             }
    //                                         }
    //                                     }
    //                                 })
    //                             })
    //                         })
    //                     })
    //                 })
    //             })
    //         }
    //     }
    // }, []);

    let itemGroupDefList: ItemGroupDef[] = [];
    let itemDefList: ItemDef[] = [];
    let codeDefList: CodeList[] = [];
    // Tab change handler for key Question filter by form OID and ItemGroup OID
    // To collect itemGrpDef, itemDefs and codeListDefs with respect to form OID
    const handleChange = (event: any, res: any, newValue: number) => {
        //console.log(res)
        setTabValue(newValue);
        let x: any;
        if (res !== null) { x = res }
        else if (assetresponse !== (null || undefined)) {
            x = assetresponse;
        }
        if (newValue === 0 && x !== (null || undefined) && x?.SDM?.Project.OID === 'A.1') {
            let index = x?.SDM?.Project?.TemplateVersion.length;
            // if (index - 1 !== -1) {
            //     let eventDef = x?.SDM?.Project?.TemplateVersion[index - 1]?.EventDef?.filter((event: any) => event.OID === "E.1");
            //     if (eventDef !== (null || undefined) && eventDef.length > 0) {
            //         let formDef = x?.SDM?.Project?.TemplateVersion[index - 1]?.FormDef?.filter((form: any) => form.OID === "F.1");
            //         if (formDef !== (null || undefined) && formDef.length > 0) {
            //             if (formDef[0] != (null || undefined)) {
            //                 if (formDef[0]?.ItemGroupRef !== (null || undefined) && formDef[0]?.ItemGroupRef?.length > 0) {
            //                     formDef[0]?.ItemGroupRef?.map((i: any) => {
            //                         let ItemGroupDef = x?.SDM?.Project?.TemplateVersion[index - 1]?.ItemGroupDef?.find((ig: any) => ig.OID === i.ItemGroupOID);
            //                         itemGroupDefList.push(ItemGroupDef);
            //                         if (ItemGroupDef !== (null || undefined)) {
            //                             ItemGroupDef?.ItemRef?.map((it: any) => {
            //                                 let ItemDef = x?.SDM?.Project?.TemplateVersion[index - 1]?.ItemDef?.find((id: any) => id.OID === it.ItemOID);
            //                                 if (ItemDef !== (null || undefined)) {
            //                                     if (it.CollectionExceptionConditionOID) {
            //                                         let xy = itemGroupData?.find((igd: any) => igd.ItemGroupOID === ItemGroupDef?.OID);
            //                                         let gf = xy?.ItemData?.find((id: any) => id.ItemOID === ItemDef?.OID);
            //                                         if (gf !== (null || undefined) && gf.ItemOID === ItemDef?.OID) {
            //                                             //console.log(ItemDef);
            //                                             itemDefList.push(ItemDef);
            //                                         }
            //                                     } else {
            //                                         itemDefList.push(ItemDef);
            //                                     }
            //                                 }
            //                                 else {
            //                                     if (index - 2 !== -1) {
            //                                         ItemDef = x?.SDM?.Project?.TemplateVersion[index - 2]?.ItemDef?.find((id: any) => id.OID === it.ItemOID);
            //                                         if (it.CollectionExceptionConditionOID) {
            //                                             let xy = itemGroupData?.find((igd: any) => igd.ItemGroupOID === ItemGroupDef?.OID);
            //                                             let gf = xy?.ItemData?.find((id: any) => id.ItemOID === ItemDef?.OID);
            //                                             if (gf !== (null || undefined) && gf.ItemOID === ItemDef?.OID) {
            //                                                 // console.log(ItemDef);
            //                                                 itemDefList.push(ItemDef);
            //                                             }
            //                                         } else {
            //                                             itemDefList.push(ItemDef);
            //                                         }
            //                                     }
            //                                 }
            //                                 if (ItemDef?.CodeListRef !== (null || undefined)) {
            //                                     ItemDef.CodeListOID = ItemDef.CodeListRef.CodeListOID;
            //                                     let codeList = x?.SDM?.Project?.TemplateVersion[index - 1]?.CodeList?.find((cl: any) => cl.OID === ItemDef?.CodeListOID);
            //                                     if (codeList !== (null || undefined)) {
            //                                         codeDefList.push(codeList);
            //                                     }
            //                                     else {
            //                                         let codeList = x?.SDM?.Project?.TemplateVersion[index - 2]?.CodeList?.find((cl: any) => cl.OID === ItemDef?.CodeListOID);
            //                                         codeDefList.push(codeList);
            //                                     }
            //                                 }
            //                             })
            //                         }
            //                         setAssetItemGroup(itemGroupDefList);
            //                         setAssetItemDef(itemDefList);
            //                         setAssetCodeList(codeDefList);
            //                     })
            //                 } else {
            //                     // handled ItemGroupRef is empty
            //                     setAssetItemGroup(itemGroupDefList);
            //                     setAssetItemDef(itemDefList);
            //                     setAssetCodeList(codeDefList);
            //                 }
            //             }
            //             setAssetConditionDef(x?.SDM?.Project?.TemplateVersion[index - 1]?.ConditionDef);
            //         }
            //     }
            // }

            x?.SDM?.Project?.TemplateVersion.map((tv: any, i: any) => {
                let eventDef = tv?.EventDef?.filter((event: any) => event.OID === "E.1");
                if (eventDef !== (null || undefined) && eventDef.length > 0) {
                    let formDef = tv?.FormDef?.filter((form: any) => form.OID === "F.1");
                    if (formDef !== (null || undefined) && formDef.length > 0) {
                        if (formDef[0] != (null || undefined)) {
                            if (formDef[0]?.ItemGroupRef !== (null || undefined) && formDef[0]?.ItemGroupRef?.length > 0) {
                                formDef[0]?.ItemGroupRef?.map((i: any) => {
                                    let ItemGroupDef = tv?.ItemGroupDef?.filter((ig: any) => ig.OID === i.ItemGroupOID);
                                    // console.log(ItemGroupDef, i, itemGroupDefList);
                                    if (itemGroupDefList !== (null || undefined) && itemGroupDefList.length !== 0) {
                                        ItemGroupDef[0]?.ItemRef?.map((it: any) => {
                                            let ItemDef = tv?.ItemDef?.filter((id: any) => id.OID === it.ItemOID);
                                            ItemDef?.map((id: any) => {
                                                const index = ItemGroupDef?.findIndex((ig: any) => ig.OID === i.ItemGroupOID);
                                                const updatedGroupDef = [...ItemGroupDef];
                                                if (index !== -1) {
                                                    const itd = [...updatedGroupDef[index].ItemRef];
                                                    const ind = itd.findIndex((obj: any) => obj.ItemOID === id.ItemOID);
                                                    if (ind !== -1) {
                                                        itd[ind] = id;
                                                    } else {
                                                        itd.push(id)
                                                    }
                                                    updatedGroupDef[index] = { Description: updatedGroupDef[index]?.Description, ItemRef: itd, OID: updatedGroupDef[index].OID, Name: updatedGroupDef[index].Name, Repeating: updatedGroupDef[index].Repeating };
                                                }
                                                else {
                                                    updatedGroupDef.push({ Description: ItemGroupDef?.Description, ItemRef: [id], OID: ItemGroupDef?.OID, Name: ItemGroupDef?.Name, Repeating: ItemGroupDef?.Repeating });
                                                }
                                                setAssetItemGroup(updatedGroupDef);
                                            })
                                        })
                                    }
                                    else {
                                        itemGroupDefList.push(ItemGroupDef);
                                        setAssetItemGroup(itemGroupDefList);
                                    }

                                    if (ItemGroupDef !== (null || undefined)) {
                                        ItemGroupDef[0]?.ItemRef?.map((it: any) => {
                                            let ItemDef = tv?.ItemDef?.find((id: any) => id.OID === it.ItemOID);
                                            if (ItemDef !== (null || undefined)) {
                                                if (it.CollectionExceptionConditionOID) {
                                                    let xy = itemAssetGroupData?.find((igd: any) => igd.ItemGroupOID === ItemGroupDef[0]?.OID);
                                                    let gf = xy?.ItemData?.find((id: any) => id.ItemOID === ItemDef?.OID);
                                                    // console.log(ItemDef?.OID, xy?.ItemData, gf);
                                                    if (gf !== (null || undefined)) {
                                                        // console.log(ItemDef);
                                                        itemDefList.push(ItemDef);
                                                    }
                                                }
                                                // else if (itemDefList.length !== 0) {
                                                //     const index = ItemDef?.findIndex((ig: any) => ig.OID === it.ItemOID);
                                                //     const updatedItemDef = [...ItemDef];
                                                //     if (index !== -1) {
                                                //         updatedItemDef[index] = it;
                                                //     } else {
                                                //         updatedItemDef.push(it);
                                                //     }
                                                //     updatedItemDef[index] = ({ Question: updatedItemDef[index]?.Description, OID: updatedItemDef[index]?.OID,  Name: updatedItemDef[index]?.Name, DataType: updatedItemDef[index]?.DataType, CodeListRef: updatedItemDef[index]?.CodeListRef, MeasurementOid: updatedItemDef[index]?.MeasurementOid });
                                                //     itemDefList.push(updatedItemDef[index]);
                                                // }
                                                else {
                                                    itemDefList.push(ItemDef);
                                                }
                                            }

                                            if (ItemDef?.CodeListRef !== (null || undefined)) {
                                                let codeList = tv?.CodeList?.filter((cl: any) => cl.OID === ItemDef?.CodeListRef?.CodeListOID);

                                                if (codeList !== (null || undefined) && codeList.length !== 0) {
                                                    const index = codeList?.findIndex((ig: any) => ig.OID === ItemDef?.CodeListRef?.CodeListOID);
                                                    const updatedCodeDef = [...codeList];
                                                    if (index !== -1) {
                                                        updatedCodeDef[index] = { OID: codeList[index]?.OID, Name: codeList[index]?.Name, DataType: codeList[index]?.DataType, CodeListItem: codeList[index]?.CodeListItem };
                                                    } else {
                                                        updatedCodeDef.push({ OID: codeList?.OID, Name: codeList?.Name, DataType: codeList?.DataType, CodeListItem: codeList?.CodeListItem });
                                                    }
                                                    //console.log(updatedCodeDef);
                                                    setAssetCodeList(updatedCodeDef);
                                                }
                                                else {
                                                    codeDefList.push(codeList);
                                                    setAssetCodeList(codeDefList);
                                                }
                                            }
                                        })
                                    }
                                    // setItemGroup(itemGroupDefList);
                                    setAssetItemDef(itemDefList);
                                })
                            }
                            else {
                                // handled ItemGroupRef is empty
                                setAssetItemGroup(itemGroupDefList);
                                setAssetItemDef(itemDefList);
                                setAssetCodeList(codeDefList);
                            }
                        }
                        setAssetConditionDef(tv?.ConditionDef);
                        // itemGroup?.map((igd) => {
                        //     igd?.ItemRef?.map((ir) => {
                        //         setConditionDef(response?.SDM?.Project?.TemplateVersion?.ConditionDef?.filter((cd) => cd.OID === ir.CollectionExceptionConditionOID));
                        //         //console.log(conditionDef);
                        //     })
                        // })
                    }
                }
            })
        }
        else if (newValue === 1 && response !== (null || undefined) && response?.SDM?.Project.OID === 'P.1') {
            response?.SDM?.Project?.TemplateVersion.reverse().map((tv: any, i: any) => {
                let eventDef = tv?.EventDef?.filter((event: any) => event.OID === "E.1");
                if (eventDef !== (null || undefined) && eventDef.length > 0) {
                    let formDef = tv?.FormDef?.filter((form: any) => form.OID === "F.1");
                    if (formDef !== (null || undefined) && formDef.length > 0) {
                        if (formDef[0] != (null || undefined)) {
                            if (formDef[0]?.ItemGroupRef !== (null || undefined) && formDef[0]?.ItemGroupRef?.length > 0) {
                                formDef[0]?.ItemGroupRef?.map((i: any) => {
                                    let ItemGroupDef = tv?.ItemGroupDef?.filter((ig: any) => ig.OID === i.ItemGroupOID);
                                    ItemGroupDef[0]?.ItemRef?.map((it: any) => {
                                        let ItemDef = tv?.ItemDef?.filter((id: any) => id.OID === it.ItemOID);
                                        ItemDef?.map((id: any) => {
                                            if (itemGroupDefList !== (null || undefined) && itemGroupDefList.length !== 0) {
                                                const index = ItemGroupDef?.findIndex((ig: any) => ig.OID === i.ItemGroupOID);
                                                const updatedGroupDef = [...ItemGroupDef];
                                                if (index !== -1) {
                                                    const itd = [...updatedGroupDef[index].ItemRef];
                                                    const ind = itd.findIndex((obj: any) => obj.ItemOID === id.ItemOID);
                                                    if (ind !== -1) {
                                                        itd[ind] = it;
                                                    } else {
                                                        itd.push(it)
                                                    }
                                                    updatedGroupDef[index] = { Description: updatedGroupDef[index]?.Description, ItemRef: itd, OID: updatedGroupDef[index].OID, Name: updatedGroupDef[index].Name, Repeating: updatedGroupDef[index].Repeating };
                                                }
                                                else {
                                                    updatedGroupDef.push({ Description: ItemGroupDef?.Description, ItemRef: [id], OID: ItemGroupDef?.OID, Name: ItemGroupDef?.Name, Repeating: ItemGroupDef?.Repeating });
                                                }
                                                setItemGroup(updatedGroupDef);
                                            }
                                            else {
                                                itemGroupDefList.push(it);
                                                setItemGroup(itemGroupDefList)
                                            }
                                        })
                                    })

                                    if (ItemGroupDef !== (null || undefined)) {
                                        ItemGroupDef[0]?.ItemRef?.map((it: any) => {
                                            let ItemDef = tv?.ItemDef?.find((id: any) => id.OID === it.ItemOID);
                                            if (ItemDef !== (null || undefined)) {
                                                if (it.CollectionExceptionConditionOID) {
                                                    let xy = itemGroupData?.find((igd: any) => igd.ItemGroupOID === ItemGroupDef[0]?.OID);
                                                    let gf = xy?.ItemData?.find((id: any) => id.ItemOID === ItemDef?.OID);
                                                    if (gf !== (null || undefined)) {
                                                        // console.log(ItemDef);
                                                        itemDefList.push(ItemDef);
                                                    }
                                                }
                                                // else if (itemDefList.length !== 0) {
                                                //     const index = ItemDef?.findIndex((ig: any) => ig.OID === it.ItemOID);
                                                //     const updatedItemDef = [...ItemDef];
                                                //     if (index !== -1) {
                                                //         updatedItemDef[index] = it;
                                                //     } else {
                                                //         updatedItemDef.push(it);
                                                //     }
                                                //     updatedItemDef[index] = ({ Question: updatedItemDef[index]?.Description, OID: updatedItemDef[index]?.OID,  Name: updatedItemDef[index]?.Name, DataType: updatedItemDef[index]?.DataType, CodeListRef: updatedItemDef[index]?.CodeListRef, MeasurementOid: updatedItemDef[index]?.MeasurementOid });
                                                //     itemDefList.push(updatedItemDef[index]);
                                                // }
                                                else {
                                                    itemDefList.push(ItemDef);
                                                }
                                            }

                                            if (ItemDef?.CodeListRef !== (null || undefined)) {
                                                ItemDef.CodeListOID = ItemDef.CodeListRef.CodeListOID;
                                                let codeList = tv?.CodeList?.find((cl: any) => cl.OID === ItemDef?.CodeListOID);
                                                if (codeList !== (null || undefined)) {
                                                    codeDefList.push(codeList);
                                                }
                                                // else {
                                                //     let codeList = tv?.CodeList?.find((cl: any) => cl.OID === ItemDef?.CodeListOID);
                                                //     codeDefList.push(codeList);
                                                // }
                                            }
                                        })
                                    }
                                    // setItemGroup(itemGroupDefList);
                                    setItemDef(itemDefList);
                                    setCodeList(codeDefList);
                                    //     if (ItemGroupDef !== (null || undefined)) {
                                    //         ItemGroupDef?.ItemRef?.map((it: any) => {
                                    //             let ItemDef = tv?.ItemDef?.filter((id: any) => id.OID === it.ItemOID);
                                    //             ItemDef.map((id: any) => {
                                    //                 if (ItemDef !== (null || undefined)) {
                                    //                     if (it.CollectionExceptionConditionOID) {
                                    //                         let xy = itemGroupData?.find((igd: any) => igd.ItemGroupOID === ItemGroupDef?.OID);
                                    //                         let gf = xy?.ItemData?.find((id: any) => id.ItemOID === ItemDef?.OID);
                                    //                         if (gf !== (null || undefined) && gf.ItemOID === ItemDef?.OID) {
                                    //                             //console.log(ItemDef);
                                    //                             itemDefList.push(ItemDef);
                                    //                         }
                                    //                     } else {
                                    //                         itemDefList.push(ItemDef);
                                    //                     }
                                    //                     console.log(itemDefList)
                                    //                     setItemDef(itemDefList);
                                    //                 }

                                    //                 if (ItemDef?.CodeListRef !== (null || undefined)) {
                                    //                     ItemDef.CodeListOID = ItemDef.CodeListRef.CodeListOID;
                                    //                     let codeList = tv?.CodeList?.find((cl: any) => cl.OID === ItemDef?.CodeListOID);
                                    //                     if (codeList !== (null || undefined)) {
                                    //                         codeDefList.push(codeList);
                                    //                     }
                                    //                     console.log(codeDefList)
                                    //                     setCodeList(codeDefList);
                                    //                 }

                                    //                 if (itemGroup.length !== 0) {
                                    //                     console.log(itemGroup);
                                    //                     const index = ItemGroupDef?.findIndex((ig: any) => ig.OID === i.ItemGroupOID);
                                    //                     const updatedGroupDef = [...ItemGroupDef];
                                    //                     if (index !== -1) {
                                    //                         const itd = [...updatedGroupDef[index].ItemRef];
                                    //                         const ind = itd.findIndex((obj: any) => obj.ItemOID === id.ItemOID);
                                    //                         console.log(ind);
                                    //                         if (ind !== -1) {
                                    //                             itd[ind] = id;
                                    //                         } else {
                                    //                             itd.push(id)
                                    //                         }
                                    //                         updatedGroupDef[index] = { Description: updatedGroupDef[index]?.Description, ItemRef: itd, OID: updatedGroupDef[index].OID, Name: updatedGroupDef[index].Name, Repeating: updatedGroupDef[index].Repeating };
                                    //                     }
                                    //                     else {
                                    //                         updatedGroupDef.push({ Description: ItemGroupDef?.Description, ItemRef: [id], OID: ItemGroupDef?.OID, Name: ItemGroupDef?.Name, Repeating: ItemGroupDef?.Repeating });
                                    //                     }
                                    //                     console.log(updatedGroupDef);
                                    //                     setItemGroup(updatedGroupDef);
                                    //                 }
                                    //                 else {
                                    //                     itemGroupDefList.push({ Description: ItemGroupDef?.Description, ItemRef: [id], OID: ItemGroupDef?.OID, Name: ItemGroupDef?.Name, Repeating: ItemGroupDef?.Repeating });
                                    //                     console.log(itemGroupDefList);
                                    //                     setItemGroup(itemGroupDefList);
                                    //                 }
                                    //             })
                                    //         })
                                    //     }
                                    //     // console.log(itemGroupDefList);
                                    //     // setItemGroup(itemGroupDefList);
                                })
                            }
                            else {
                                // handled ItemGroupRef is empty
                                setItemGroup(itemGroupDefList);
                                setItemDef(itemDefList);
                                setCodeList(codeDefList);
                            }
                        }
                        setConditionDef(tv?.ConditionDef);
                        // itemGroup?.map((igd) => {
                        //     igd?.ItemRef?.map((ir) => {
                        //         setConditionDef(response?.SDM?.Project?.TemplateVersion?.ConditionDef?.filter((cd) => cd.OID === ir.CollectionExceptionConditionOID));
                        //         //console.log(conditionDef);
                        //     })
                        // })
                    }
                }
            })
        }
        // else if (newValue === 2 && response !== (null || undefined) && response?.SDM?.Project.OID === 'P.1') {
        //     let eventDef = response?.SDM?.Project?.TemplateVersion?.EventDef?.filter((event: any) => event.OID === "E.1");
        //     if (eventDef !== (null || undefined) && eventDef.length > 0) {
        //         let formDef = response?.SDM?.Project?.TemplateVersion?.FormDef?.filter((form: any) => form.OID === "F.2");
        //         if (formDef !== (null || undefined) && formDef.length > 0) {
        //             if (formDef[0] !== (null || undefined)) {
        //                 console.log(formDef[0]?.ItemGroupRef);
        //                 if (formDef[0]?.ItemGroupRef !== (null || undefined) && formDef[0]?.ItemGroupRef?.length > 0) {
        //                     formDef[0]?.ItemGroupRef?.map((i: any) => {
        //                         let ItemGroupDef = response?.SDM?.Project?.TemplateVersion?.ItemGroupDef?.find((ig: any) => ig.OID === i.ItemGroupOID);
        //                         itemGroupDefList.push(ItemGroupDef);
        //                         if (ItemGroupDef !== null || undefined) {
        //                             ItemGroupDef?.ItemRef?.map((it: any) => {
        //                                 let ItemDef = response?.SDM?.Project?.TemplateVersion?.ItemDef?.find((id: any) => id.OID === it.ItemOID);
        //                                 if (it.CollectionExceptionConditionOID) {
        //                                     let xy = itemGroupData?.find((igd: any) => igd.ItemGroupOID === ItemGroupDef?.OID);
        //                                     let gf = xy?.ItemData?.find((id: any) => id.ItemOID === ItemDef?.OID);
        //                                     if (gf !== (null || undefined) && gf.ItemOID === ItemDef?.OID) {
        //                                         console.log(ItemDef);
        //                                         itemDefList.push(ItemDef);
        //                                     }
        //                                 } else {
        //                                     itemDefList.push(ItemDef);
        //                                 }
        //                                 if (ItemDef?.CodeListRef !== (null || undefined)) {
        //                                     ItemDef.CodeListOID = ItemDef.CodeListRef.CodeListOID;
        //                                     let codeList = response?.SDM?.Project?.TemplateVersion?.CodeList?.find((cl: any) => cl.OID === ItemDef?.CodeListOID);
        //                                     codeDefList.push(codeList);
        //                                 }
        //                             })
        //                         }
        //                         // console.log(itemGroupList, itemDataList);
        //                         setItemGroup(itemGroupDefList);
        //                         setItemDef(itemDefList);
        //                         setCodeList(codeDefList);
        //                     })
        //                 } else {
        //                     // handled ItemGroupRef is empty
        //                     setItemGroup(itemGroupDefList);
        //                     setItemDef(itemDefList);
        //                     setCodeList(codeDefList);
        //                 }
        //             }
        //             setConditionDef(response?.SDM?.Project?.TemplateVersion?.ConditionDef);
        //             // itemGroup?.map((igd) => {
        //             //     igd?.ItemRef?.map((ir) => {
        //             //         setConditionDef(response?.SDM?.Project?.TemplateVersion?.ConditionDef?.filter((cd) => cd.OID === ir.CollectionExceptionConditionOID));
        //             //         //console.log(conditionDef);
        //             //     })
        //             // })
        //         }
        //     }
        // }
    }


    //To prepare FormData
    function handleState(itemGroupData: any) {
        //console.log(formDef);
        let formDataArray: FormData[] = [];
        if (itemGroupData?.ProjectOID === 'P.1') {
            if (itemGroupData?.ItemGroup?.length !== 0) {
                formDef?.map((form: any) => {
                    form?.ItemGroupRef?.map((ir) => {
                        itemGroupData?.ItemGroup?.map((igd: any) => {
                            if (ir.ItemGroupOID === igd.ItemGroupOID) {
                                if (formData.length !== 0) {
                                    const index = formData.findIndex((obj) => obj.FormOID === form.OID);
                                    const updateFormData = [...formData];
                                    if (index !== -1) {
                                        const frdt = [...updateFormData[index].ItemGroupData];
                                        const ind = frdt.findIndex((obj) => obj.ItemGroupOID === igd.ItemGroupOID);
                                        if (ind !== -1) {
                                            frdt[ind] = igd;
                                        } else {
                                            frdt.push(igd)
                                        }
                                        updateFormData[index] = { ItemGroupData: frdt, FormOID: updateFormData[index].FormOID, TransactionType: "Insert" };
                                    } else {
                                        updateFormData.push({ ItemGroupData: [igd], FormOID: form.OID, TransactionType: "Insert" });
                                    }
                                    setFormData(updateFormData);
                                    handleEventData(updateFormData, itemGroupData.ProjectOID);
                                } else {
                                    formDataArray.push({ ItemGroupData: [igd], FormOID: form.OID, TransactionType: "Insert" });
                                    setFormData(formDataArray);
                                    handleEventData(formDataArray, itemGroupData.ProjectOID);
                                }
                            }
                        })
                    })
                })
            }
        } else {
            if (itemGroupData?.ItemGroup?.length !== 0) {
                assetformDef?.map((form: any) => {
                    form?.ItemGroupRef?.map((ir) => {
                        itemGroupData?.ItemGroup?.map((igd: any) => {
                            if (ir.ItemGroupOID === igd.ItemGroupOID) {
                                if (formData.length !== 0) {
                                    const index = formData.findIndex((obj) => obj.FormOID === form.OID);
                                    const updateFormData = [...formData];
                                    if (index !== -1) {
                                        const frdt = [...updateFormData[index].ItemGroupData];
                                        const ind = frdt.findIndex((obj) => obj.ItemGroupOID === igd.ItemGroupOID);
                                        if (ind !== -1) {
                                            frdt[ind] = igd;
                                        } else {
                                            frdt.push(igd)
                                        }
                                        updateFormData[index] = { ItemGroupData: frdt, FormOID: updateFormData[index].FormOID, TransactionType: "Insert" };
                                    } else {
                                        updateFormData.push({ ItemGroupData: [igd], FormOID: form.OID, TransactionType: "Insert" });
                                    }
                                    setFormData(updateFormData);
                                    handleEventData(updateFormData, itemGroupData.ProjectOID);
                                } else {
                                    formDataArray.push({ ItemGroupData: [igd], FormOID: form.OID, TransactionType: "Insert" });
                                    setFormData(formDataArray);
                                    handleEventData(formDataArray, itemGroupData.ProjectOID);
                                }
                            }
                        })
                    })
                })
            }
        }
    }

    //To prepare EventData
    function handleEventData(formData: any, projectOID: string) {
        //console.log(formData);
        let eventDataArray: EventData[] = [];
        if (projectOID === 'P.1') {
            eventDef?.map((event: any) => {
                event?.FormRef.map((fr: any) => {
                    formData.map((fd: any) => {
                        if (fr.FormOID === fd.FormOID) {
                            if (eventData.length !== 0) {
                                const index = eventData.findIndex((obj) => obj.ProjectEventOID === event.OID);
                                const updateEventData = [...eventData];
                                if (index !== -1) {
                                    const etdt = [...updateEventData[index].FormData];
                                    const ind = etdt.findIndex((obj) => obj.FormOID === fd.FormOID);
                                    if (ind !== -1) {
                                        etdt[ind] = fd;
                                    } else {
                                        etdt.push(fd);
                                    }
                                    updateEventData[index] = { FormData: etdt, ProjectEventOID: updateEventData[index].ProjectEventOID }
                                } else {
                                    updateEventData.push({ FormData: [fd], ProjectEventOID: event.OID });
                                }
                                setEventData(updateEventData);
                                handleProjectData(updateEventData, projectOID);
                            } else {
                                eventDataArray.push({ FormData: [fd], ProjectEventOID: event.OID });
                                setEventData(eventDataArray);
                                handleProjectData(eventDataArray, projectOID);
                            }
                        }
                    })
                })
            })
        } else {
            asseteventDef?.map((event: any) => {
                event?.FormRef.map((fr: any) => {
                    formData.map((fd: any) => {
                        if (fr.FormOID === fd.FormOID) {
                            if (eventData.length !== 0) {
                                const index = eventData.findIndex((obj) => obj.ProjectEventOID === event.OID);
                                const updateEventData = [...eventData];
                                if (index !== -1) {
                                    const etdt = [...updateEventData[index].FormData];
                                    const ind = etdt.findIndex((obj) => obj.FormOID === fd.FormOID);
                                    if (ind !== -1) {
                                        etdt[ind] = fd;
                                    } else {
                                        etdt.push(fd);
                                    }
                                    updateEventData[index] = { FormData: etdt, ProjectEventOID: updateEventData[index].ProjectEventOID }
                                } else {
                                    updateEventData.push({ FormData: [fd], ProjectEventOID: event.OID });
                                }
                                setEventData(updateEventData);
                                handleProjectData(updateEventData, projectOID);
                            } else {
                                eventDataArray.push({ FormData: [fd], ProjectEventOID: event.OID });
                                setEventData(eventDataArray);
                                handleProjectData(eventDataArray, projectOID);
                            }
                        }
                    })
                })
            })
        }
    }

    //To prepare ProjectData
    function handleProjectData(eventData: any, projectOID: string) {
        //console.log(eventData);
        // let prjData: ProjectData;
        // template?.EventDef?.map((ed: any) => {
        //     ed?.FormRef.map((fr: any) => {
        //         eventData.map((edt: any) => {
        //             if (fr.FormOID === edt.FormData[0].FormOID) {
        //                 let tempprojectData: ProjectData = { EventData: edt, ProjectOID: "S.1", ProjectKey: "123", MetaDataVersionOID: "MVD.1" };
        //                 // prjData = tempprojectData;
        //                 // setProjectData({...projectData, ...{
        //                 //     EventData: edt, ProjectOID: "S.1", ProjectKey: "123", MetaDataVersionOID: "MVD.1"
        //                 // }});
        //                 setProjectData(tempprojectData);
        //             }
        //         })
        //         // let tempprojectData: ProjectData = { EventData: eventData, ProjectOID: "S.1", ProjectKey: "123", MetaDataVersionOID: "MVD.1" };
        //         // setProjectData(tempprojectData);
        //         // let SDMData: SDM = { Project: { GlobalVariables: { ProjectDescription: "", ProjectName: "", ProtocolName: "" }, TemplateVersion: template as TemplateVersion, OID: "S.1" }, AdminData: { User: { FirstName: "", LastName: "", OID: "" }, ProjectOID: "S.1" }, ProjectData: tempprojectData, xmlns: "", FileType: "", FileOID: "", CreationDateTime: "", ODMVersion: "", SourceSystem: "" };
        //         // setSdmData(SDMData);
        //     })
        // })

        if (projectOID === 'P.1') {
            let tempprojectData: ProjectData = { EventData: eventData, ProjectOID: projectOID, ProjectKey: "123", MetaDataVersionOID: templateVersion };
            setProjectData(tempprojectData);
        } else {
            let tempprojectData: ProjectData = { EventData: eventData, ProjectOID: projectOID, ProjectKey: "123", MetaDataVersionOID: aasettemplateVersion };
            setAssetData(tempprojectData);
        }
    }


    //Download JSON File
    const handleSaveFunctionality = () => {
        const dataString = 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(projectData));
        const download = document.createElement('a');
        download.setAttribute('href', dataString);
        download.setAttribute('download', 'ProjectData' + '.json');
        document.body.appendChild(download);
        download.click();
        download.remove();
        const dataStr = JSON.stringify(assetData);
        ////console.log(dataStr);
        // onSave(assetData)
    }

    useImperativeHandle(ref, () => ({
        handleSaveFunctionality: () => {
            const dataStr = JSON.stringify(assetData);
            const dataString = JSON.stringify(projectData);
            onSave(dataStr, dataString, tradeType, tradeVersion, assetVersion);
        }

        // validate: () => {
        // }
    }), [assetData, projectData, tradeType, tradeVersion, assetVersion, onSave]);

    // Conditional Rendering of Questions
    function showQus(item: any) {
        //console.log(itemDef);
        let ItemDef: any;
        const i = response?.SDM?.Project?.TemplateVersion.length;
        if (item.ProjectOID === 'P.1') {
            if (item.status === true) {
                response?.SDM?.Project?.TemplateVersion.reverse().map((tv: any, i: any) => {
                    ItemDef = tv?.ItemDef?.find((id: any) => id.OID === item.id);
                    //console.log(ItemDef);
                    if (ItemDef !== (null || undefined)) {
                        let index = tv?.ItemDef?.findIndex((id: any) => id.OID === item.id);
                        if (itemDef.length > 0 && itemDef !== (null || undefined) && itemDef.find((i: any) => i.OID === item.id)) {
                            itemDef.splice(index, 0, ItemDef);
                        } else {
                            itemDef.splice(index, 0, ItemDef);
                        }
                        // console.log(itemDef);
                        setItemDef(itemDef);
                    }
                })
                // if (i - 1 !== -1) {
                //     ItemDef = response?.SDM?.Project?.TemplateVersion[i - 1]?.ItemDef?.find((id: any) => id.OID === item.id);
                //     if (ItemDef !== (null || undefined)) {
                //         let index = response?.SDM?.Project?.TemplateVersion[i - 1]?.ItemDef?.findIndex((id: any) => id.OID === item.id);
                //         if (itemDef.length > 0 && itemDef !== (null || undefined) && itemDef.find((i: any) => i.OID === item.id)) {
                //             //itemDef.splice(index, 0, ItemDef);
                //         } else {
                //             itemDef.splice(index, 0, ItemDef);
                //         }
                //         console.log(itemDef);
                //         setItemDef(itemDef);
                //     } 
                //     else {
                //         if (i - 2 !== -1) {
                //             ItemDef = response?.SDM?.Project?.TemplateVersion[i - 2]?.ItemDef?.find((id: any) => id.OID === item.id);
                //             if (ItemDef !== (null || undefined)) {
                //                 let index = response?.SDM?.Project?.TemplateVersion[i - 2]?.ItemDef?.findIndex((id: any) => id.OID === item.id);
                //                 // console.log(itemDef.find((i: any) => i.OID === item.id), itemDef, item.id)
                //                 if (itemDef.length > 0 && itemDef !== (null || undefined) && itemDef.find((i: any) => i.OID === item.id)) {
                //                     itemDef.splice(index, 0, ItemDef);
                //                 } else {
                //                     //console.log(index, itemDef)
                //                     itemDef.splice(index, 0, ItemDef);
                //                 }
                //                 setItemDef(itemDef);
                //             }
                //         }
                //     }
                // }

                // ItemDef = response?.SDM?.Project?.TemplateVersion?.ItemDef?.find((id: any) => id.OID === item.id);
                // if (ItemDef !== (null || undefined)) {
                //     let index = response?.SDM?.Project?.TemplateVersion?.ItemDef?.findIndex((id: any) => id.OID === item.id);
                //     if (itemDef.length > 0 && itemDef !== (null || undefined) && itemDef.find((i: any) => i.OID === item.id)) {
                //         itemDef.splice(index, 0, ItemDef);
                //     } else {
                //         itemDef.splice(index, 0, ItemDef);
                //     }
                //     console.log(itemDef);
                //     setItemDef(itemDef);
                // }
            } else {
                //console.log(itemDef);
                let ItemDef = itemDef?.find((id: any) => id.OID === item.id);
                if (ItemDef !== (null || undefined)) {
                    let index = itemDef?.findIndex((id: any) => id.OID === item.id);
                    itemDef.splice(index, 1);
                    setItemDef(itemDef);
                }
            }
        } else {
            if (item.status === true) {
                let ItemDef = assetresponse?.SDM?.Project?.TemplateVersion?.ItemDef?.find((id: any) => id.OID === item.id);
                if (ItemDef !== (null || undefined)) {
                    let index = assetresponse?.SDM?.Project?.TemplateVersion?.ItemDef?.findIndex((id: any) => id.OID === item.id);
                    if (assetitemDef.length > 0 && assetitemDef !== (null || undefined) && assetitemDef.find((i: any) => i.OID === item.id)) {
                        assetitemDef.splice(index, 0, ItemDef);
                    } else {
                        assetitemDef.splice(index, 0, ItemDef);
                    }
                    setAssetItemDef(assetitemDef);
                }
            } else {
                let ItemDef = assetitemDef?.find((id: any) => id.OID === item.id);
                if (ItemDef !== (null || undefined)) {
                    let index = assetitemDef?.findIndex((id: any) => id.OID === item.id);
                    assetitemDef.splice(index, 1);
                    setAssetItemDef(assetitemDef);
                }
            }
        }
    }

    // Repeat Questions
    function RepeatQus(itemGrp: any) {
        //console.log(itemGrp);
        // if (itemGrp !== (null || undefined)) {
        //     itemGroup.splice(itemGrp.Index, 0, itemGrp.ItemGroup);
        // }
        setItemGroup(itemGrp);
    }

    //For Data Binding
    function handleSampleData(e: any, data: any, projectOID: string, eventOID: string, formOID: string) {
        // console.log(data, projectOID, eventOID, formOID);
        let itemGroupDataList: ItemGroupData[] = [];
        let itemDataList: ItemData[] = [];
        let codeDataList: CodeList[] = [];
        if (data !== (null || undefined)) {
            if (data?.ProjectOID === 'A.1') {
                let eventData = data?.EventData?.filter((ed: any) => ed.ProjectEventOID === eventOID);
                if (eventData !== (null || undefined) && eventData.length > 0) {
                    let formData = eventData[0]?.FormData?.filter((fd: any) => fd.FormOID === formOID);
                    if (formData[0] !== (null || undefined) && formData.length > 0) {
                        formData[0]?.ItemGroupData?.map((i: any) => {
                            let ItemGroupData = formData[0]?.ItemGroupData?.find((ig: any) => ig.ItemGroupOID === i.ItemGroupOID);
                            // console.log(ItemGroupData);
                            itemGroupDataList.push(ItemGroupData);
                            setItemAssetGroupData(itemGroupDataList);
                        })
                    }
                }
            }
            else {
                let eventData = data?.EventData?.filter((ed: any) => ed.ProjectEventOID === eventOID);
                // console.log(eventData);
                if (eventData !== (null || undefined) && eventData.length > 0) {
                    let formData = eventData[0]?.FormData?.filter((fd: any) => fd.FormOID === formOID);
                    if (formData[0] !== (null || undefined) && formData.length > 0) {
                        formData[0]?.ItemGroupData?.map((i: any) => {
                            let ItemGroupData = formData[0]?.ItemGroupData?.find((ig: any) => ig.ItemGroupOID === i.ItemGroupOID);
                            // console.log(ItemGroupData);
                            itemGroupDataList.push(ItemGroupData);
                            setItemGroupData(itemGroupDataList);
                        })
                    }
                }
            }
        }
        else {
            // if (projectOID === 'P.1') {
            //     // handling projectData is empty
            //     let formData = response?.SDM?.Project?.TemplateVersion?.FormDef?.filter((form: any) => form.OID === formOID);
            //     if (formData !== (null || undefined) && formData.length > 0) {
            //         if (formData[0] != (null || undefined)) {
            //             formData[0]?.ItemGroupData?.map((i: any) => {
            //                 let ItemGroupData = response?.SDM?.Project?.TemplateVersion?.ItemGroupDef?.find((ig: any) => ig.ItemGroupOID === i.ItemGroupOID);
            //                 itemGroupDataList.push(ItemGroupData);
            //                 setItemGroupData(itemGroupDataList);
            //             })
            //         }
            //     } else {
            //         // Need to handle formData is empty
            //     }
            // } else {
            //     let formData = assetresponse?.SDM?.Project?.TemplateVersion?.FormDef?.filter((form: any) => form.OID === formOID);
            //     if (formData !== (null || undefined) && formData.length > 0) {
            //         if (formData[0] != (null || undefined)) {
            //             formData[0]?.ItemGroupData?.map((i: any) => {
            //                 let ItemGroupData = assetresponse?.SDM?.Project?.TemplateVersion?.ItemGroupDef?.find((ig: any) => ig.ItemGroupOID === i.ItemGroupOID);
            //                 itemGroupDataList.push(ItemGroupData);
            //                 setItemAssetGroupData(itemGroupDataList);
            //             })
            //         }
            //     } else {
            //         // Need to handle formData is empty
            //     }
            // }
        }
    }

    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={10}>
                    <Box>
                        <Card className='POCard' variant="outlined" >
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabValue} onChange={(event, newValue) => handleChange(event, null, newValue)} aria-label="basic tabs example">
                                    {/* {!show ? <Tab sx={{ textTransform: "none" }} label="Asset Info" {...a11yProps(0)} onClick={(e) => handleSampleData(e, assetData, "A.1", "E.1", "F.1")} /> : null} */}
                                    <Tab sx={{ textTransform: "none" }} label="Asset Info" {...a11yProps(0)} onClick={(e) => handleSampleData(e, assetData, "A.1", "E.1", "F.1")} />
                                    {!hide ? <Tab sx={{ textTransform: "none" }} label="Key Questions" {...a11yProps(1)} onClick={(e) => handleSampleData(e, projectData, "P.1", "E.1", "F.1")} /> : null}
                                    {/* {!hide ? <Tab sx={{ textTransform: "none" }} label="Optional Questions" {...a11yProps(2)} onClick={(e) => handleSampleData(e, projectData, "P.1", "E.1", "F.2")} /> : null} */}

                                </Tabs>
                            </Box>
                            <CustomTabPanel value={tabValue} index={0}>
                                <QuestionRendererCard OID={assetOID} itemGroupDefs={assetitemGroup} itemDefs={assetitemDef} codeListDefs={assetcodeList} conditionDefs={assetconditionDef} measureDef={measureUnit} OnItemGrpDataChange={handleState} show={showQus} showRepeat={RepeatQus} itemGroupDatas={itemAssetGroupData} />
                                {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button variant='contained' onClick={handleSaveFunctionality}>Save</Button>
                                </Box> */}
                            </CustomTabPanel>
                            <CustomTabPanel value={tabValue} index={1}>
                                <QuestionRendererCard OID={projectOID} itemGroupDefs={itemGroup} itemDefs={itemDef} codeListDefs={codeList} conditionDefs={conditionDef} measureDef={measureUnit} OnItemGrpDataChange={handleState} show={showQus} showRepeat={RepeatQus} itemGroupDatas={itemGroupData} />
                                {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button variant='contained' onClick={handleSaveFunctionality}>Save</Button>
                                </Box> */}
                            </CustomTabPanel>
                            {/* <CustomTabPanel value={tabValue} index={2}>
                                <QuestionRendererCard OID={projectOID} itemGroupDefs={itemGroup} itemDefs={itemDef} codeListDefs={codeList} conditionDefs={conditionDef} measureDef={measureUnit} OnItemGrpDataChange={handleState} show={showQus} showRepeat={RepeatQus} itemGroupDatas={itemGroupData} />
                                {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                                    <Button variant='contained' onClick={handleSaveFunctionality}>Save</Button>
                                </Box> 
                            </CustomTabPanel> */}
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
})

export default QuestionRenderer;