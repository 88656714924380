import { Box, Button, Card, Checkbox, Fab, FormControl, Grid, IconButton, InputLabel, ListItemText, Menu, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TextField, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getMaterialNamesByTrade } from '../../services/materialService';
import { useNavigate } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import AddMaterialForm from '../materialManagement/AddMaterialForm';
import { getAllVendors, getMaterialsByVendorId } from '../../services/VendorService';
import VendorRegister from '../vendor/VendorRegister';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddMaterial from './AddMaterial';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const MaterialForm: React.FC<any> = (props) => {
    const { accessToken } = useOidcAccessToken();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuIndex, setMenuIndex] = React.useState<any>();
    const [openMaterial, setOpenMaterial] = React.useState<boolean>(false);
    const openMenu = Boolean(anchorEl);
    const [editData, setEditData] = React.useState<any>();
    const [editIndexvalue, setEditIndexValue] = React.useState<any>();

    const handleChange = (data: any) => {
        props.changeTaskData(data);
    };

    const handleClick = (event: any, index: any) => {
        setMenuIndex(index);
        setAnchorEl(event.currentTarget);
    };

    const addNewMaterial = () => {
        setAnchorEl(null);
        setOpenMaterial(true);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };


    const editMaterialData = (data:any) =>
    {
        props.edit(editIndexvalue, data);

    }

    const handleCloseMaterial = () => {
        setOpenMaterial(false);
    }


    const removeRow = (index: any) => {
        props.remove(index);
    }

    const editRow = (index:any, row:any) => {

        setEditIndexValue(index);
        setEditData(row);
        setAnchorEl(null);
        setOpenMaterial(true);
        console.log(editData);
    }

    const changeEditMaterialData = () =>
    {
        setEditData(undefined);
    }

    React.useEffect(() => {
    }, []);

    return (
        <>
            <Grid container className='alignCenter'>
                <Grid item xs={12}>
                <Card variant='outlined' className='taskcard' sx={{ padding: 1, marginBottom: 1, height:'31vh', overflow:'auto'}}>
                    {props?.task?.boms[0].companyName !=='' &&  props?.task?.boms[0].name !=='' && 
                    props?.task?.boms[0].quantity !=='' && props?.task?.boms[0].unit !=='' &&
                    props?.task?.boms[0].totalCost !=='' && props?.task?.boms[0].unitCost !=='' 
                    && props?.task?.boms[0].vendorMaterialMapId !=='' ?
                    <>
                    {props?.task?.boms?.map((material: any, index: any) =>
                        <Card variant='outlined' className='taskcard' sx={{ padding: 1, marginBottom: 1, backgroundColor: '#F1F3FF' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="demo-helper-text-aligned"
                                            label="Material Name"
                                            name="name"
                                            size="small"
                                            fullWidth
                                            disabled
                                            value={material?.name}                                     
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="demo-helper-text-aligned"
                                            label="Vendor Name"
                                            name="companyName"
                                            size="small"
                                            fullWidth
                                            disabled
                                            value={material?.companyName}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="demo-helper-text-aligned"
                                            label="Quanitity"
                                            name="quantity"
                                            size="small"
                                            fullWidth
                                            disabled
                                            value={material?.quantity}
                                            type="number" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="demo-helper-text-aligned"
                                            label="Unit"
                                            name="unit"
                                            size="small"
                                            fullWidth
                                            disabled
                                            value={material?.unit}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="demo-helper-text-aligned"
                                            label="Sub Total"
                                            name="subTotal"
                                            size="small"
                                            fullWidth
                                            disabled
                                            value={material?.totalCost}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        id="demo-positioned-button"
                                        aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openMenu ? 'true' : undefined}
                                        onClick={(e) => handleClick(e, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="demo-positioned-menu"
                                        aria-labelledby="demo-positioned-button"
                                        anchorEl={anchorEl}
                                        open={openMenu === true && menuIndex === index ? true : false}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <MenuItem onClick={() => editRow(index, material)}>
                                            <Stack direction={'row'} spacing={1}>
                                                <EditIcon fontSize='small'
                                                />
                                                <Typography>Edit</Typography>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem onClick={() => removeRow(index)}>
                                            <Stack direction={'row'} spacing={1}>
                                                <DeleteIcon fontSize='small' />
                                                <Typography>Delete</Typography>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem onClick={() => addNewMaterial()}>
                                            <Stack direction={'row'} spacing={1}>
                                                <AddIcon fontSize='small' />
                                                <Typography>Add</Typography>
                                            </Stack>
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Card>
                    )}
                    </>
                    :
                    <Box className = "alignCenter">
                    <Button onClick={() => addNewMaterial()} size="large" startIcon={ <AddIcon fontSize='small' />}>Add Bom</Button>
                    </Box>
                }
                    </Card>
                </Grid>
                </Grid>
                <Grid xs={12}>
                    <Grid container spacing={2} sx={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2} className='alignEnd'>
                            <Typography>Total:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{props?.total}</Typography>
                        </Grid>
                        <Grid item xs={1}>

                        </Grid>
                    </Grid>
                </Grid>

            <AddMaterial open={openMaterial} onClose={handleCloseMaterial} trade={props?.projectData?.trade}
                changeMaterial={handleChange} editMaterialData = {editData} changeEditMaterial = {changeEditMaterialData}
                editAddedMaterial = {editMaterialData}/>
        </>
    );
}

export default MaterialForm;