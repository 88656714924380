/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Material
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE     |    AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2044           |07/Jun/2024 | vijaykumar   |  Integrate Add material api 
* 2174           |  12/06/24  | Vanitha      |  Pagination implementation for Material Management
* 2344           | 15-07-2024 | shiyam kumar |  Made changes for Dynamic price change.
* 2437           | 31-07-2024 | shiyam kumar |  Material cost update issue fixed
*2399            | 06-08-2024 | shiyam kumar | made changes to fix pagination issue
*/
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import {
    Alert, Button, Divider, FormControl, FormControlLabel, Grid, InputAdornment, InputBase, InputLabel, Menu, MenuItem, OutlinedInput, Select, SelectChangeEvent, Snackbar, Switch, Tab, Tabs, TextField, colors, useMediaQuery,
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography, Paper, Checkbox, IconButton,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,

    DialogActions,
    DialogContentText,

} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SearchIcon from "@mui/icons-material/Search";
import './MaterialList.scss'
import { useNavigate } from 'react-router-dom';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { editMaterial, getMaterialsCost, getMaterialsCount, getMaterialsTags, searchMaterials, updateMaterialCost } from '../../services/MaterialMangementService';
import CustomDialog from '../../pages/Dialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { getAllVendors } from '../../services/VendorService';
import { addMaterial } from '../../services/materialService';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { importCodes } from '../../services/ImportService';

interface Data {
    id: number;
    VendorId: number;
    VendorName: string;
    Trade: string;
    Manufacturer: string;
    MaterialName: string;
    ItemCode: string;
    VersionHistory: string;
    VersionStartDate: string;
    Grade: string,
    Unit: string,
    UnitCost: number;
    Action: string;
}

function createData(
    id: number,
    VendorId: number,
    VendorName: string,
    Trade: string,
    Manufacturer: string,
    MaterialName: string,
    ItemCode: string,
    VersionHistory: string,
    VersionStartDate: string,
    Grade: string,
    Unit: string,
    UnitCost: number,
    Action: string,
): Data {
    return {
        id,
        VendorId,
        VendorName,
        Trade,
        Manufacturer,
        MaterialName,
        ItemCode,
        VersionHistory,
        VersionStartDate,
        Grade,
        Unit,
        UnitCost,
        Action

    };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    // {
    //     id: 'VendorId',
    //     numeric: false,
    //     disablePadding: true,
    //     label: 'VendorId',
    // },
    {
        id: 'VendorName',
        numeric: false,
        disablePadding: true,
        label: 'VendorName',
    },
    {
        id: 'Trade',
        numeric: false,
        disablePadding: true,
        label: 'Trade',
    },
    {
        id: 'Manufacturer',
        numeric: true,
        disablePadding: false,
        label: 'Manufacturer',
    },
    {
        id: 'MaterialName',
        numeric: true,
        disablePadding: false,
        label: 'MaterialName',
    },
    {
        id: 'ItemCode',
        numeric: true,
        disablePadding: false,
        label: 'ItemCode',
    },
    {
        id: 'VersionHistory',
        numeric: true,
        disablePadding: false,
        label: 'VersionHistory',
    },
    {
        id: 'VersionStartDate',
        numeric: true,
        disablePadding: false,
        label: 'VersionStartDate',
    },
    {
        id: 'Grade',
        numeric: true,
        disablePadding: false,
        label: 'Grade',
    },
    {
        id: 'Unit',
        numeric: true,
        disablePadding: false,
        label: 'Unit',
    },
    {
        id: 'UnitCost',
        numeric: true,
        disablePadding: false,
        label: 'UnitCost',
    },
    {
        id: 'Action',
        numeric: true,
        disablePadding: false,
        label: 'Action',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead className='materialTableHead'>
            <TableRow className='tableCustomerRows tableHead'>
                <TableCell padding="checkbox">
                    {/* <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    /> */}
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell className='tableCustomerRows '
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


const MaterialList: React.FC = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('Trade');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [materialCount, setMaterialCount] = React.useState(Number);
    const [count, setCount] = React.useState(0);


    const rows = [
        createData(1, 1, "", 'Roofing', 'generic', "", 'Oc', "c", "", "c", 'sq', 4.3, ''),
    ];

    const navigate = useNavigate();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const customerForm = () => {
        navigate("/customerregistration"); // Navigate to the "/task" route
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [trade, setTrade] = React.useState<any>(null);
    const [grade, setGrade] = React.useState<any>(null);
    const [materialName, setmaterialName] = React.useState<any>(null);
    const [materialData, setMaterialData] = React.useState<any>([] as any);
    const [updatedMaterialData, setUpdatedMaterialData] = React.useState<any>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [trades, setTrades] = React.useState<any>([]);
    const [grades, setGrades] = React.useState<any>([]);
    const materials = React.useRef([]);
    const [searchParam, setSearchParam] = React.useState<any>([]);
    const [newMaterialData, setNewMaterialData] = React.useState<any>();
    const [ids, setIds] = React.useState<any>([]);  
    const [number, setNumber] = React.useState(0);
    const [vendorList, setVendorList] = React.useState<any>([]);
    const [vendor, setVendor] = React.useState<any>();
    const units = [
        'SQ', 'Bundle-100 LF', 'Bundle-33 LF', 'RL', 'EA', '1.85inches - 10', 'BD', '5LB', 'QTY', 'BX', 'TB', 'LF', 'Hour'
    ];

    const gradeList = [
        'a', 'b', 'c'
    ]
    const manufacturers = [
        'Owens Corning', 'GAF', 'Certain Teed', 'Norandex', 'James Hardie', 'Smart Side', 'ACM', 'Berger', ' US Aluminum Inc'
    ]
    const tradeList = [
        'Roofing',
        'Siding',
        'Gutter',
    ];
    const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [saveError, setSaveError] = React.useState(false);
    const [updatedRows, setUpdatedRows] = React.useState<any>({});
    const [updatedId, setUpdateId] = React.useState<any>([]);
    const [tradeName, setTradeName] = React.useState<string[]>([]);
    const [noChange, setNoChange] = React.useState(false);
    const filter = createFilterOptions<any>();

    const handleChangeTrade = (event: SelectChangeEvent) => {
        let data = {
            key: "tags",
            value: event.target.value,
            operation: "cn",
            logicalOp: "and"
        };
        let params = searchParam;
        if (params.length !== 0) {
            let index = params.findIndex(x => x.key === "tags");
            if (index !== -1) {
                params[index] = data;
            }
            else {
                params.push(data);
            }
        } else {
            params.push(data);
        }
        setSearchParam(params);
        setTrade(event.target.value);
        // var s = event.target.value;
        getMaterial(event.target.value, grade, materialName);

    };
    const handleChangeGrade = (event: SelectChangeEvent) => {
        let data = {
            key: "grade",
            value: event.target.value,
            operation: "cn",
            logicalOp: "and"
        };
        let params: any = [];
        params = searchParam;
        if (params.length !== 0) {
            let index = params.findIndex(x => x.key === "grade");
            if (index !== -1) {
                params[index] = data;
            }
            else {
                params.push(data);
            }
        } else {
            params.push(data);
        }
        setSearchParam(params);
        setGrade(event.target.value);
        getMaterial(trade, event.target.value, materialName);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const onHandleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const [values, setValues] = React.useState<any>();
    const [updateCount, setUpdateCount] = React.useState(0);

    const getMaterialCostBySelectedDateAndMapId = async (selectedDate: any, vendorMaterialMapId: any, index: any) => {
        let result = await getMaterialsCost(selectedDate, vendorMaterialMapId, accessToken);
        let materialCostData = result.data;

        if (materialCostData !== '') {
            let newArr = [...materialData];
            newArr[index]["cost"] = materialCostData.cost;
            newArr[index]['versionStartdate'] = materialCostData.versionStartDate;
            setMaterialData(newArr);
        }
        else {
            setNoChange(true);
        }
    }
    const onChangeVersionHistory = (e: any, row: any, index: any) => {
        getMaterialCostBySelectedDateAndMapId(dayjs(e.$d).format('YYYY-MM-DD') + "T00:00:00.000Z", row.vendorMaterialMapId, index);
    }
    const addMaterialItem = () => {
        // ids.push(0 + number);
        // setNumber(number + 1);
        ids.push(0)
        getVendors();
        const material = {
            vendorId: '',
            companyName: '',
            name: "",
            itemCode: "",
            tags: '',
            manufacturer: '',
            unit: "",
            grade: '',
            versionStartdate: "",
            cost: "",
        };
        const updatedMaterial = [
            material,
            ...materialData
        ];
        setMaterialData(updatedMaterial);
    };
    const getVendors = async () => {
        let result = await getAllVendors(accessToken);
        setVendorList(result.data);
    }
    const materialInputChange = (e: any, index: any) => {
        if (e.target.name === "companyName") {
            let newArray = [...materialData];
            newArray[index][e.target.name] = (e.target.value)?.companyName;
            newArray[index]['vendorId'] = (e.target.value)?.id;
            setVendor(e.target.value);
            setMaterialData(newArray);
        }
        else if (e.target.name === "cost") {
            let newArray = [...materialData];
            newArray[index][e.target.name] = e.target.value;
            setMaterialData(newArray);
        }
        else {
            let newArray = [...materialData];
            newArray[index][e.target.name] = e.target.value;
            setMaterialData(newArray);
        }

    };
    const setVendorNameAndId = (e: any, value: any, index: any) => {
        let newArray = [...materialData];
        newArray[index]["companyName"] = value?.companyName;
        newArray[index]['vendorId'] = value?.id;
        // setVendor(e.target.value);
        setMaterialData(newArray);
    };

    const handleTradeNameChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setTradeName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    React.useEffect(() => {
        (async () => {
            await getMaterial(trade, grade, materialName);
            materials.current.map((value: any) => {
                if (!grades.includes(value.grade)) {
                    grades.push(value.grade)
                }
                // console.log(value.tags);
                // (value.tags).split(";").map(trade => {
                //     if (!trades.includes(trade)) {
                //         trades.push(trade)
                //     }
                // })
            })
        })() 
        setIds([]);
        getMaterialsTags(accessToken)
        .then((res) => {
            // console.log("tags", res.data);
            setTrades(res.data);

        })
        .catch((error) => {
            console.error("Error fetching tags:", error);
        });
    
    }, [page, rowsPerPage]);

    const handleClose = () => {
        setAnchorEl(null);
    };


    const inputchange = (e: any) => {
        let data = {
            key: "name",
            value: e.target.value,
            operation: "cn",
            logicalOp: "and"
        };
        let params = searchParam;
        if (params.length !== 0) {
            let index = params.findIndex(x => x.key === "name");
            if (index !== -1) {
                params[index] = data;
            }
            else {
                params.push(data);
            }
        } else {
            params.push(data);
        }
        setSearchParam(params);
        setmaterialName(e.target.value);
        getMaterial(trade, grade, e.target.value);
    };

    const dateFormatter = (value: any) => {
        const date = new Date(value);
        const month = date.getMonth().toString();
        return date.getFullYear() + "-" + (month.length === 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + ((date.getDate()).toString().length === 1 ? "0" + (date.getDate()).toString() : date.getDate()) + "T00:00:00.000Z";
    }
    const onChangeDate = (e: any, row: any, index: any) => {
        
        let updatedRowIndex = Object.entries(updatedRows).findIndex(updatedrow => updatedrow[0] === index.toString());

        let oldDate = dayjs(row.versionStartdate).format('YYYY-MM-DD')+ "T00:00:00.000Z";
        let newDate = dayjs(e.$d).format('YYYY-MM-DD')+ "T00:00:00.000Z";

        if(updatedRowIndex >= 0){
            if("date" in updatedRows[index]){
                oldDate = updatedRows[index]['date'];
                if(newDate === oldDate){
                    updatedId.pop(index);
                }else{
                    updatedRows[index]['date'] = oldDate;
                    if("cost" in updatedRows[index]){
                        if(!updatedId.includes(index)){
                            updatedId.push(index);
                        }
                    }
                }
            }else{
                updatedRows[index]['date'] = oldDate;
                if("cost" in updatedRows[index]){
                    if(!updatedId.includes(index)){
                        updatedId.push(index);
                    }
                }
            } 
        }else{
            Object.assign(updatedRows, {[index]:{date: newDate}});
        }
        console.log(updatedId, updatedRows);

        row.versionStartdate = dayjs(e.$d).format('YYYY-MM-DD') + "T00:00:00.000Z";
        let newArr = [...materialData];
        let indexNumber = newArr.findIndex(obj => obj.materialCostId === row.materialCostId);
        newArr[indexNumber] = row;
        setMaterialData(newArr);
        let newData = row;
        setNewMaterialData(newData);
        let updatedIndex = updatedMaterialData.findIndex(obj => obj.materialCostId === row.materialCostId);
        if (updatedIndex === -1) {
            updatedMaterialData.push(row);
        }
        else {
            updatedMaterialData[updatedIndex] = row;
        }
    }
    const onEdit = (e: any, row: any, index: any) => {
        
        let updatedRowIndex = Object.entries(updatedRows).findIndex(updatedrow => updatedrow[0] === index.toString());

        let oldCost = row.cost.toString();
        let newCost = e.target.value.toString();

        console.log(updatedRowIndex);
        if(updatedRowIndex >= 0){
            if("cost" in updatedRows[index]){
                console.log(newCost, oldCost);
                oldCost = updatedRows[index]['cost'];
                console.log(newCost, oldCost);

                if(newCost === oldCost){
                    updatedId.pop(index);
                }else{
                    if("date" in updatedRows[index]){
                        if(!updatedId.includes(index)){
                            updatedId.push(index);
                        }
                    }
                }
            }else{
                updatedRows[index]['cost'] = oldCost;
                if("date" in updatedRows[index]){
                    if(!updatedId.includes(index)){
                        updatedId.push(index);
                    }
                }
            }  
        }else{
            Object.assign(updatedRows, {[index]:{cost:oldCost}});
        }
        console.log(updatedId, updatedRows);

        row.cost = e.target.value;
        let newArr = [...materialData];
        let indexNumber = newArr.findIndex(obj => obj.materialCostId === row.materialCostId);
        newArr[indexNumber] = row;
        setMaterialData(newArr);
        let newData = row;
        setNewMaterialData(newData);
        let updatedIndex = updatedMaterialData.findIndex(obj => obj.materialCostId === row.materialCostId);
        if (updatedIndex === -1) {
            updatedMaterialData.push(row);
        }
        else {
            updatedMaterialData[updatedIndex] = row;
        }

    }

    const getMaterial = async (trade: any, grade: any, materialName: any) => {
        let searchCriteriaList = searchParam
        const materialData = (searchParam.length !== 0 ? { searchCriteriaList } : {})
        const res = await getMaterialsCount(accessToken, materialName, trade, grade);
        console.log(res)
        setMaterialCount(res.data)
        let result = await searchMaterials(accessToken, materialData, materialName, trade, grade, page, rowsPerPage);
        materials.current = result.data;
        console.log(result.data)
        setMaterialData(result.data);

    }
    // const material = async (trade, grade, materialName) => {
    //     let result = await searchMaterials(accessToken, materialData, materialName, trade, grade, page, rowsPerPage);
    //     materials.current = result.data
    //     setMaterialData(result.data);
    // }

    const updateMaterial = async (row:any) => {
        let indexNumber = materialData.findIndex(obj => obj.materialCostId === row.materialCostId);
        let selectedrow = updatedMaterialData.findIndex(obj => obj.materialCostId === row.materialCostId);
        let selectedData = updatedMaterialData[selectedrow];
        setUpdateCount(count + 1);
        let validate = validation(selectedData);
        if (validate === true) {
            setOpenDialog(true);
            if (selectedData?.length !== 0) {
                let result = await updateMaterialCost(accessToken, selectedData);
                if (result.status === 200 || result.status === 201) {
                    setUpdateSuccess(true);
                    setIds([]);
                    updatedId.pop(indexNumber);
                    delete updatedRows[indexNumber];
                    getMaterial(trade, grade, materialName);
                    updatedMaterialData.pop(selectedData);
                }
                else {
                    setUpdateError(true);
                }
            }
            else {
                setUpdateError(true);
            }
        }

    }
    const onChangeVersionDate = (e: any, index: any) => {
        let newArr = [...materialData];
        newArr[index]["versionStartdate"] = dayjs(e.$d).format('YYYY-MM-DD') + "T00:00:00.000Z";
        setMaterialData(newArr);
    }


    const validation = (newMaterial: any) => {
        let validated: any = true;
        if (newMaterial?.companyName === '' || newMaterial?.tags === '' || newMaterial?.name === '' || newMaterial?.itemCode === ''
            || newMaterial?.cost === '' || newMaterial?.vendorId === '' || newMaterial?.unit === '' || newMaterial?.versionStartdate === ''
            || newMaterial?.manufacturer === '' || newMaterial?.grade === '' || newMaterial?.unit === ''
        ) {
            validated = false;
        }
        return validated;
    }
    const addMaterials = async (index) => {
        setCount(count + 1);
        let newMaterial = materialData[index];
        newMaterial['tags'] = tradeName.join(';');
        let validate = validation(newMaterial);
        if (validate === true) {
            let result = await addMaterial(accessToken, [newMaterial]);
            if (result.status === 201) {
                setOpenSaveDialog(true);
                setSaveSuccess(true);
                setIds([]);
                setTradeName([]);
                setCount(0);
                getMaterial(trade, grade, materialName);
            }
            else {
                setOpenSaveDialog(true);
                setSaveError(true);
            }
        }
    }
    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setNoChange(false)
    }


    const onVendorRegPage = () => {
        navigate("/admin/vendorregistration") // Navigate to the "/task" route
    };


    const [openImportDialog, setOpenImportDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [file, setFile] = React.useState<File | null>(null);
    const [dataa, setFormData] = React.useState<any>();
    const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);

    const handleDialogOpen = () => {
        setOpenImportDialog(true);
    };

    const handleDialogClose = () => {
        setOpenImportDialog(false);
    };

    const handleChanged = React.useCallback(
        (event) => {
            const files = event.target.files;
            if (files && files[0]) {
                handleFileChange(files[0]);
                // setFile(files[0]);
            }
        },
        []
    );

    let formData = new FormData();

    const handleFileChange = (file: File) => {
        setLoading(true);
        setFile(file);
        formData.append('file', file);
        console.log(file)
        setFormData(formData);
    };

    const importCode = async () => {
        console.log(dataa);
        const res = await importCodes(dataa, accessToken);
        console.log(res);
        setOpenImportDialog(false);
        setOpenSuccess(true);
        if (res.ok === true) {
            setSuccess(true);

        } else {
            setError(true);
        }
    }

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        if (success) {
            handleDialogClose();
        }
    }

    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={10}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='POCard' sx={{ width: '100%', mb: 2 }}>
                            <div className='px-3 py-3'>
                                <Grid
                                    container
                                    direction="row"
                                    // justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    <Grid item xs={12}>
                                        <Typography className='filterHeader'>Search Filters</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-select-small-label">Trade</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={trade}
                                                label="Trade"
                                                onChange={handleChangeTrade}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {trades?.map((trade: any) => {
                                                    return (
                                                        <MenuItem value={trade}>{trade}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-select-small-label">Grade</InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={grade}
                                                label="Grade"
                                                onChange={handleChangeGrade}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {grades?.map((grade: any) => {
                                                    return (
                                                        <MenuItem value={grade}>{grade}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} className='alignEnd'>
                                        <Button variant="contained" size="large" onClick={handleDialogOpen}>Import Costcode</Button>
                                    </Grid>
                                    <Grid item xs={4} className='alignEnd'>
                                        {/* <Button variant="contained" size="medium" startIcon={<FileUploadOutlinedIcon />} onClick={updateMaterial}>
                                            Update
                                        </Button> */}
                                        <CustomDialog
                                            open={openDialog}
                                            onClose={() => setOpenDialog(false)}
                                            success={updateSuccess}
                                            error={updateError}
                                            Content={updateSuccess ? "Updated Successfully" : "Not Updated Successfully"}
                                        />
                                        <CustomDialog
                                            open={openSaveDialog}
                                            onClose={() => setOpenSaveDialog(false)}
                                            success={saveSuccess}
                                            error={saveError}
                                            Content={saveSuccess ? "Saved" : "Not Saved"}
                                        />
                                    </Grid>

                                </Grid>
                            </div>
                            <Grid item xs={12}><Divider></Divider></Grid>
                            <div className='px-3 py-3'>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    <Grid item xs={6}>
                                        {/* <Button variant="contained" size="medium" startIcon={<FileUploadOutlinedIcon />}>
                                            Export
                                        </Button> */}
                                    </Grid>
                                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <TextField
                                            placeholder="Search by material name"
                                            size='small'
                                            name="materialName"
                                            value={materialName}
                                            onChange={(e) => inputchange(e)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => getMaterial(trade, grade, materialName)}>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Typography pl={2}>
                                            <Button variant="contained" size="medium" onClick={addMaterialItem}
                                                startIcon={<AddOutlinedIcon fontSize='small' />} sx={{ padding: 1 }}
                                                disabled={ids.includes(0) ? true : false}>
                                                Add New Material
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    {materialData?.length === 0 ?
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={10}>
                                                    <Typography align="center">No Data</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        :
                                        <TableBody>
                                            {materialData?.map((row, index) => {
                                                const isItemSelected = isSelected(row?.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                    // hover
                                                    // onClick={(event) => handleClick(event, row.id)}
                                                    // role="checkbox"
                                                    // aria-checked={isItemSelected}
                                                    // tabIndex={-1}
                                                    // key={row.id}
                                                    // selected={isItemSelected}
                                                    // sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell padding="checkbox" size='small'>
                                                            {/* <CustomCheckbox
                                                            className='check'
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        /> */}
                                                        </TableCell>
                                                        {/* <TableCell size='small'>
                                                        {row?.vendorId === '' || ids.includes(index) ?
                                                            <FormControl sx={{ minWidth: 80 }}>
                                                                <TextField
                                                                    name="vendorId"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    disabled
                                                                    value={row?.vendorId}
                                                                    error={(row?.vendorId === '' && count !== 0)}
                                                                    onChange={(e) => materialInputChange(e, index)}
                                                                />
                                                            </FormControl> :
                                                            <FormControl>{row?.vendorId} </FormControl>
                                                        }
                                                    </TableCell> */}
                                                        <TableCell size='small'>
                                                            {row?.companyName === '' || ids.includes(index) ?
                                                                // <FormControl size='small' sx={{ minWidth: 120 }}>
                                                                //     <InputLabel id="demo-simple-select-label">Vendor Name</InputLabel>
                                                                //     <Select
                                                                //         labelId="demo-simple-select-label"
                                                                //         id="demo-simple-select"
                                                                //         value={vendor}
                                                                //         label="Vendor Name"
                                                                //         name="companyName"
                                                                //         error={(row?.companyName === '' && count !== 0)}
                                                                //         onChange={(e) => materialInputChange(e, index)}
                                                                //         required
                                                                //     // disabled={show}
                                                                //     >
                                                                //         {vendorList?.map((vendor) => (
                                                                //             <MenuItem value={vendor}>{vendor?.companyName}</MenuItem>
                                                                //         ))}
                                                                //     </Select>
                                                                //     </FormControl>
                                                                <FormControl fullWidth size="small" sx={{ minWidth: 150 }}>
                                                                    <Autocomplete
                                                                        value={row?.companyName}
                                                                        onChange={(e, newValue) => {
                                                                            if (typeof newValue === 'string') {
                                                                                setVendorNameAndId(e, newValue, index);
                                                                            } else if (newValue && newValue.inputValue) {


                                                                                onVendorRegPage();


                                                                            } else {
                                                                                setVendorNameAndId(e, newValue, index);
                                                                            }
                                                                        }}
                                                                        // onChange={(e, newValue) => setVendorNameAndId(e, newValue,index)}
                                                                        filterOptions={(options, params) => {
                                                                            const filtered = filter(options, params);

                                                                            const { inputValue } = params;
                                                                            // Suggest the creation of a new value
                                                                            const isExisting = options.some((option) => inputValue === option.companyName);
                                                                            if (inputValue !== '' && !isExisting) {
                                                                                filtered.push({
                                                                                    inputValue,
                                                                                    companyName: `Add Vendor`,
                                                                                });
                                                                            }

                                                                            return filtered;
                                                                        }}
                                                                        selectOnFocus
                                                                        clearOnBlur
                                                                        handleHomeEndKeys
                                                                        id="free-solo-with-text-demo"
                                                                        options={vendorList}
                                                                        getOptionLabel={(option) => {
                                                                            // Value selected with enter, right from the input
                                                                            if (typeof option === 'string') {
                                                                                return option;
                                                                            }
                                                                            // Add "xxx" option created dynamically
                                                                            if (option.inputValue) {
                                                                                return option.inputValue;
                                                                            }
                                                                            // Regular option
                                                                            return option.companyName;
                                                                        }}
                                                                        renderOption={(props, option) => <li {...props}>{option.companyName}</li>}

                                                                        freeSolo
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Vendor Name" size='small'
                                                                                error={(row?.companyName === '' && count !== 0)} />
                                                                        )}
                                                                    />
                                                                </FormControl>
                                                                :
                                                                <FormControl>{row?.companyName}</FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small' sx={{ minWidth: 120 }}>
                                                            {row?.companyName === '' || ids.includes(index) ?
                                                                // <FormControl fullWidth size="small" sx={{ m: 1, minWidth: 90 }}>
                                                                //     <InputLabel id="demo-simple-select-label">Trade</InputLabel>
                                                                //     <Select
                                                                //         labelId="demo-simple-select-label"
                                                                //         id="demo-simple-select"
                                                                //         error={row?.tags === '' && count !== 0}
                                                                //         value={row?.tags}
                                                                //         label="trade"
                                                                //         name="tags"
                                                                //         onChange={(e) => materialInputChange(e, index)}
                                                                //         required
                                                                //     // disabled={show}
                                                                //     >
                                                                //         {tradeList?.map((trade) => (
                                                                //             <MenuItem value={trade}>{trade}</MenuItem>
                                                                //         ))}
                                                                //     </Select>
                                                                // </FormControl> 
                                                                <FormControl size='small' sx={{ minWidth: 90 }}>
                                                                    <InputLabel id="demo-multiple-checkbox-label">Trade</InputLabel>
                                                                    <Select
                                                                        labelId="demo-multiple-checkbox-label"
                                                                        id="demo-multiple-checkbox"
                                                                        multiple
                                                                        error={tradeName.length === 0 && count !== 0}
                                                                        value={tradeName}
                                                                        onChange={handleTradeNameChange}
                                                                        input={<OutlinedInput label="Trade" />}
                                                                        renderValue={(selected) => selected.join(', ')}
                                                                    //   MenuProps={MenuProps}
                                                                    >
                                                                        {tradeList.map((name) => (
                                                                            <MenuItem key={name} value={name}>
                                                                                <Checkbox checked={tradeName.indexOf(name) > -1} />
                                                                                <ListItemText primary={name} />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                <FormControl>
                                                                    {row?.tags}
                                                                </FormControl>
                                                            }</TableCell>
                                                        <TableCell size='small'>
                                                            {row?.manufacturer === '' || ids.includes(index) ?
                                                                <FormControl fullWidth size="small" sx={{ minWidth: 150 }}>
                                                                    <InputLabel id="demo-simple-select-label">Manufacturer</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={row?.manufacturer}
                                                                        error={row?.manufacturer === '' && count !== 0}
                                                                        label="manufacturer"
                                                                        name="manufacturer"
                                                                        onChange={(e) => materialInputChange(e, index)}
                                                                        required
                                                                    >
                                                                        {manufacturers.map((m) => (
                                                                            <MenuItem value={m}>{m}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                <FormControl>
                                                                    {row?.manufacturer}
                                                                </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.name === '' || ids.includes(index) ?

                                                                <FormControl>
                                                                    <TextField
                                                                        name="name"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        value={row?.name}
                                                                        error={(row?.name === '' && count !== 0)}
                                                                        onChange={(e) => materialInputChange(e, index)}
                                                                    />

                                                                </FormControl> :
                                                                <FormControl>{row?.name} </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.itemCode === '' || ids.includes(index) ?

                                                                <FormControl>
                                                                    <TextField
                                                                        name="itemCode"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        value={row?.itemCode}
                                                                        error={(row?.itemCode === '' && count !== 0)}
                                                                        onChange={(e) => materialInputChange(e, index)}
                                                                    />
                                                                </FormControl>
                                                                :
                                                                <FormControl>{row?.itemCode} </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>

                                                            <FormControl sx={{ minWidth: 160 }}>

                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        // format='DD MMM YYYY'
                                                                        label="Version History"
                                                                        slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                                                        // disablePast
                                                                        name="versionStartdate"
                                                                        disabled={ids.includes(index) ? true : false}
                                                                        // value={dayjs(row?.versionStartdate)}
                                                                        onChange={(e) => onChangeVersionHistory(e, row, index)}
                                                                    // disableHighlightToday   
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>

                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.versionStartdate === '' || ids.includes(index) ?
                                                                <FormControl sx={{ minWidth: 160 }}>

                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DatePicker
                                                                            // format='DD MMM YYYY'
                                                                            label="Version Start Date"
                                                                            slotProps={{ textField: { fullWidth: true, size: "small", error: row?.versionStartdate === '' && count !== 0 } }}
                                                                            // disablePast
                                                                            name="versionStartdate"
                                                                            value={row?.versionStartdate === '' ? null : dayjs(row?.versionStartdate)}
                                                                            onChange={(e) => onChangeVersionDate(e, index)}

                                                                        // disableHighlightToday   
                                                                        />
                                                                    </LocalizationProvider>
                                                                </FormControl> :
                                                                <FormControl>

                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DatePicker
                                                                            // format='DD MMM YYYY'
                                                                            label="Version Start Date"
                                                                            slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                                                            // disablePast
                                                                            name="versionStartdate"
                                                                            value={dayjs(row?.versionStartdate)}
                                                                            onChange={(e) => onChangeDate(e, row, index)}
                                                                        // disableHighlightToday   
                                                                        />
                                                                    </LocalizationProvider>
                                                                </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.grade === '' || ids.includes(index) ?
                                                                <FormControl fullWidth size="small" sx={{ minWidth: 80 }}>
                                                                    <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={row?.grade}
                                                                        error={row?.grade === '' && count !== 0}
                                                                        label="Grade"
                                                                        name="grade"
                                                                        onChange={(e) => materialInputChange(e, index)}
                                                                        required
                                                                    >
                                                                        {gradeList.map((grade) => (
                                                                            <MenuItem value={grade}>{grade}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                :
                                                                <FormControl>
                                                                    {row?.grade}
                                                                </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.unit === '' || ids.includes(index) ?
                                                                <FormControl fullWidth size="small" sx={{ minWidth: 80 }}>
                                                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={row?.unit}
                                                                        error={row?.unit === '' && count !== 0}
                                                                        label="Unit"
                                                                        name="unit"
                                                                        // disabled={show}
                                                                        onChange={(e) => materialInputChange(e, index)}
                                                                        required
                                                                    >
                                                                        {units.map((unit) => (
                                                                            <MenuItem value={unit}>{unit}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl> :
                                                                <FormControl>{row?.unit}</FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {row?.cost === '' || ids.includes(index) ?
                                                                <FormControl sx={{ minWidth: 100 }}>
                                                                    <TextField
                                                                        name="cost"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        type="number"
                                                                        value={row?.cost}
                                                                        error={row?.cost === '' && count !== 0}
                                                                        onChange={(e) => materialInputChange(e, index)}
                                                                    />
                                                                </FormControl> :
                                                                <FormControl sx={{ minWidth: 100 }}>
                                                                    <TextField
                                                                        name="cost"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        type="number"
                                                                        value={row?.cost}
                                                                        error={row?.cost === '' && updateCount !== 0}
                                                                        onChange={(e) => onEdit(e, row, index)}
                                                                    />
                                                                </FormControl>
                                                            }
                                                        </TableCell>
                                                        <TableCell size='small'>
                                                            {!ids.includes(index) ?
                                                                <Button variant="contained" size="small"
                                                                    startIcon={<FileUploadOutlinedIcon />}
                                                                    onClick={() => updateMaterial(row)}
                                                                    disabled={updatedId.includes(index) ? false : true}>
                                                                    Update
                                                                </Button>
                                                                :
                                                                <Button variant="contained" size="small" startIcon={<FileUploadOutlinedIcon />}
                                                                    onClick={() => addMaterials(index)}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            }
                                                        </TableCell>
                                                        {/* <TableCell size='small'>
                                                        <IconButton
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-controls={open ? 'long-menu' : undefined}
                                                            aria-expanded={open ? 'true' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={onHandleClick}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id="long-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                        // PaperProps={{
                                                        //     style: {
                                                        //         maxHeight: ITEM_HEIGHT * 4.5,
                                                        //         width: '10ch',
                                                        //     },
                                                        // }}
                                                        >
                                                            <MenuItem>
                                                                <VisibilityOutlinedIcon fontSize='small' />
                                                                View
                                                            </MenuItem>
                                                            <MenuItem>
                                                                <ModeEditOutlinedIcon fontSize='small' />Edit
                                                            </MenuItem>
                                                            <MenuItem>
                                                                <DeleteOutlineOutlinedIcon fontSize='small' />
                                                                Delete
                                                            </MenuItem>

                                                        </Menu>
                                                    </TableCell> */}
                                                    </TableRow>
                                                );
                                            })}
                                            {/* {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )} */}
                                        </TableBody>
                                    }
                                </Table>
                            </TableContainer>
                            <Dialog open={noChange} >
                                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                                    <Box paddingLeft={8} paddingRight={8}>
                                        <Typography textAlign={'center'}>
                                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                        {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                                    </Box>
                                </DialogTitle>
                                <Box paddingLeft={8} paddingRight={8}>
                                    <DialogContent >
                                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Data Found</DialogContentText>
                                    </DialogContent>
                                    <DialogActions style={{ justifyContent: "center" }}>
                                        <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                                    </DialogActions>
                                </Box>
                            </Dialog>
                            <Grid container spacing={2}>
                                <Grid item xs={6} >
                                    {/* <FormControlLabel sx={{ padding: theme.spacing(1) }}
                                        control={<Switch checked={dense} onChange={handleChangeDense} />}
                                        label="Dense padding"
                                    /> */}
                                </Grid>
                                <Grid item xs={6} className='px-2'>
                                    <TablePagination
                                        sx={{ border: 'none' }}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={materialCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    ></TablePagination>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
            </Grid >
            <Dialog
                open={openImportDialog}
                onClose={handleDialogClose}
            >
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Upload
                        </Typography>
                        <Typography textAlign={'center'}>
                            <form>
                                <input type="file" onChange={handleChanged} />
                            </form>
                        </Typography>
                    </DialogContent>
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleDialogClose} className="dangerButton">Cancel</Button>
                    <Button
                        onClick={importCode}
                        autoFocus className="primaryTonalButton">
                        Upload
                    </Button>
                </DialogActions>

            </Dialog>
            <CustomDialog
                open={openSuccess}
                onClose={closeDialog}
                success={success}
                error={error}
                Content={success ? "Imported  Successfully" : "Failed To Import"}
            />
        </>

    );
}

export default MaterialList;