import { Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { ItemData, ItemDef } from "../../../interface/SdmInterfaces";

interface Props {
    itemDef: ItemDef;
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
    mandatory: string;
}

const FreeText: React.FC<any> = ({ itemDef, measureDef, onItemchange, itemDatas, mandatory }) => {

    // console.log(mandatory);

    const [itemData, setItemData] = useState<ItemData>();

    const inputchange = (e: any) => {
        let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: e.target.value, CodeList: [], MetaKey: itemDef.MetaKey };
        setItemData(tempItemData);
        onItemchange(tempItemData);
    }

    return (
        <>
            <Stack direction="row" spacing={2}>
                <TextField
                    // error={}
                    id={itemDef?.OID}
                    label=""
                    name="Value"
                    size="small"
                    sx={{ m: 1 }}
                    fullWidth
                    value={(itemDef?.OID === itemDatas[0]?.ItemOID) ? itemDatas[0]?.Value : itemData?.Value}
                    required
                    //key={itemDef?.OID}
                    // helperText={ }
                    onChange={inputchange}
                />
                <Typography sx={{ textAlign: 'center' }}>{measureDef?.Symbol?.TranslatedText?.text}</Typography>
            </Stack>
        </>
    )
}

export default FreeText;