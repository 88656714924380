import { Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { ItemData, ItemDef } from "../../../interface/SdmInterfaces";

interface Props {
    itemDef: ItemDef;
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
}

const FloatText: React.FC<any> = ({ itemDef, measureDef, onItemchange, itemDatas }) => {

    ////console.log(itemDef);

    const [itemData, setItemData] = useState<ItemData>();

    const inputchange = (e: any) => {
        let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: e.target.value, CodeList: [], MetaKey: itemDef.MetaKey };
        setItemData(tempItemData);
        onItemchange(tempItemData);
    }

    return (
        <>
            <Stack direction="row" spacing={2}>
                <TextField
                    // error={}
                    id="demo-helper-text-aligned"
                    label=""
                    name="name"
                    size="small"
                    sx={{ m: 1 }}
                    type="number"
                    fullWidth
                    value={(itemDef?.OID === itemDatas[0]?.ItemOID) ? itemDatas[0]?.Value : itemData?.Value}
                    inputProps={{ step: "0.1" }}
                    required
                    // helperText={ }
                    onChange={inputchange}
                />
                <Typography sx={{ textAlign: 'center' }}>{measureDef?.Symbol?.TranslatedText?.text}</Typography>
            </Stack>
        </>
    )
}

export default FloatText;