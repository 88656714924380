/*
 * Copyright (C) GMS Inc - All Rights Reserved
 * This file is part of GMSCloud. - Proprietary and confidential
 * Unauthorized copying of this file, via any medium is strictly prohibited
 *
 * Created on   : 08-Mar-2024
 * @author      :Vijay kumar
 * @since       : 0.0.1
 * Requirement# :
 * Purpose      : Tool Service Layer
 * -----------------------------------------------------------------------------
 * Revision History
 * -----------------------------------------------------------------------------
 * Requirement/
 * Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
 * -----------------------------------------------------------------------------
 *
 *
 *
 */
import { executeGetData, executePostData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";

export const getToolsName = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tools/getToolNames`;
  return await executeGetData(url, accessToken);
};

export const getToolCount = async (accessToken: any, toolName: any, trade:any) => {
  const url = `${ConfigUrl.baseURL}/tools/getToolCount/toolName/${toolName}/trade/${trade}`;
  return await executeGetData(url, accessToken);
}

export const searchTools = async (accessToken: any, toolName: any, trade:any, page:any, size:any) => {
  const url = `${ConfigUrl.baseURL}/tools/getTools/toolName/${toolName}/trade/${trade}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
}

export const updateToolCost = async (accessToken: any, tools: any) => {
  const url = `${ConfigUrl.baseURL}/toolcost/updateToolCost`;
  return await executePostData(url, tools, accessToken);
};

export const addTool = async (accessToken: any, tools: any) => {
  const url = `${ConfigUrl.baseURL}/tools/addTools`;
  return await executePostData(url, tools, accessToken);
};

export const getToolsCost = async (
  selectedDate: any,
  vendorMaterialMapId: any,
  accessToken: any
) => {
  const url = `${ConfigUrl.baseURL}/toolcost/getToolCostByDateAndMapId/selectedDate/${selectedDate}/vendorToolMapId/${vendorMaterialMapId}`;
  return await executeGetData(url, accessToken);
};

export const getToolNamesByTrade = async (trade: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tools/getToolName/tradeName/${trade}`;
  return await executeGetData(url, accessToken);
};

export const getVendorByToolName = async (name: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tools/getTools/toolName/${name}`;
  return await executeGetData(url, accessToken);
};
