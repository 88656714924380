import { TextField } from "@mui/material";
import React, { useState } from "react";
import { ItemData, ItemDef } from "../../../interface/SdmInterfaces";

interface Props {
    itemDef: ItemDef;
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
}

const MultilineText: React.FC<any> = ({ itemDef, onItemchange, itemDatas }) => {

    //console.log(itemDef);

    const [itemData, setItemData] = useState<ItemData>();

    const inputchange = (e: any) => {
        //console.log(e.target.value);
        let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: e.target.value, CodeList: [], MetaKey: itemDef.MetaKey };
        setItemData(tempItemData);
        onItemchange(tempItemData);
    }

    return (
        <>
            <TextField
                // error={}
                id="demo-helper-text-aligned"
                label=""
                name="name"
                size="small"
                sx={{ m: 1 }}
                fullWidth
                value={(itemDef?.OID === itemDatas[0]?.ItemOID) ? itemDatas[0]?.Value : itemData?.Value}
                multiline
                rows={4}
                required
                // helperText={ }
                onChange={inputchange}
            />
        </>
    )
}

export default MultilineText;