import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { CodeList, CodeListItem, ConditionDef, ItemData, ItemDef } from "../../../interface/SdmInterfaces";


interface MultiSelectProps {
    itemDef: ItemDef;
    conditionDef: ConditionDef[];
    codeList: CodeList[];
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
    showStatus: React.Dispatch<React.SetStateAction<any>>;
}

const MultiChoice: React.FC<any> = ({ itemDef, conditionDef, codeList, onItemchange, showStatus, itemDatas }) => {

    //console.log(itemDatas);

    //Need to change to itemData
    const [itemData, setItemData] = useState<ItemData>();
    const [codeData, setcodeData] = useState<CodeList[]>(codeList);
    const [state, setState] = useState<any>([] as any[]);
    const [checkedState, setCheckedState] = useState(
        new Array(state.length).fill(false)
    );

    useEffect(() => {
        if (itemDatas?.length > 0) {
            itemDatas?.map((i: any) => {
                if (i?.ItemOID === itemDef?.OID) {
                    i.CodeList.map((cd: any) => {
                        cd.CodeListItem.map((c: any) => {
                            codeList.map((cl: any) => {
                                cl.CodeListItem.map((cli: any) => {
                                    if (cli.CodedValue === c.CodedValue) {
                                        cli.IsEnabled = c.IsEnabled;
                                        if (c.IsEnabled) {
                                            conditionDef.map((cnd: any) => {
                                                showStatus(cnd.FormalExpression.text.includes(i.ItemOID), cnd.OID);
                                            })
                                        }
                                    }
                                }
                                )
                            })
                        })
                    })
                }
            })
        }
    }, [itemDatas]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, indexes: any) => {
        const checked = event.target.checked;
        if (checked) {
            setState([...state, event.target.value]);
            conditionDef.map((cd: any) => {
                showStatus(cd.FormalExpression.text.includes(event.target.value), cd.OID);

            });
            //setCheckedState(new Array(state.length).fill(true));
        } else {
            setState([...state, event.target.value]);
            conditionDef.map((cd: any) => {
                showStatus(cd.FormalExpression.text.includes(event.target.value), cd.OID);

            });
            //setCheckedState(new Array(state.length).fill(false));
        }

        let codeListItems: CodeListItem[] = codeList[0].CodeListItem;
        const indexToUpdate = codeListItems.findIndex((x) => x.CodedValue === event.target.value);
        const updatedTodos = [...codeList]; // creates a copy of the array
        updatedTodos[0].CodeListItem[indexToUpdate].IsEnabled = event.target.checked; // modifies the copy
        setcodeData(updatedTodos);
        if (itemDef.CodeListOID = codeList[0].OID) {
            let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: "", CodeList: updatedTodos, MetaKey: itemDef.MetaKey };
            setItemData(tempItemData);
            onItemchange(tempItemData);
        }
    };

    const error = [state].filter((v) => v).length > 1;

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <FormControl
                    required
                    error={error}
                    component="fieldset"
                    // sx={{ m: 1 }}
                    sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1 }}
                    variant="standard"
                >
                    {/* <FormLabel component="legend">Pick two</FormLabel> */}
                    {codeList?.map((item: any) => {
                        let cdlt = itemDatas.find((id: any) => id?.CodeList[0]?.OID === item.OID)
                        return (
                            item.CodeListItem.map((i: any, index: any) => {
                                return (
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                // checked={checkedState[index]}
                                                <Checkbox value={i?.CodedValue} checked={i.IsEnabled} onChange={e => handleChange(e, index)} />
                                            }
                                            label={i?.Decode?.TranslatedText?.text}
                                        />

                                        {/* <FormControlLabel control={<Checkbox checked={cdlt?.CodeList[0].CodeListItem[index].IsEnabled} />} label={i.Decode.TranslatedText.text} /> */}
                                    </FormGroup>
                                )
                            })
                        )
                    })
                    }
                    {/* <FormHelperText>Select more than one</FormHelperText> */}
                </FormControl>
            </Box>
        </>
    )
}

export default MultiChoice;